import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren } from 'react';

export function StatusCardContainer ({
  children
}: PropsWithChildren): JSX.Element {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: token('space.200'),
        marginBottom: token('space.400'),
        alignItems: 'stretch',
        justifyContent: 'stretch',
        width: '100%'
      }}
    >
      {children}
    </Box>
  );
}
