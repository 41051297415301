import { type ReactElement } from 'react';
import AWSSecretsManagerIcon from 'assets/icons/vaults/AWSSecretsManagerIcon';
import { type IconWidthProps } from 'assets/icons/IconWidthProps';

const VAULT_MAPPER: Record<
string,
{
  Icon: (props: IconWidthProps) => ReactElement
  name: string
}
> = {
  AWS_SECRETS_MANAGER: {
    Icon: AWSSecretsManagerIcon,
    name: 'AWS Secret Manager'
  }
};

export default VAULT_MAPPER;
