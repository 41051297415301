import { ButtonGroup } from '@atlaskit/button';
import NavigationButton from './NavigationButton';
import { useNavigate, useParams } from 'react-router';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export default function NavigationButtons (): JSX.Element {
  const navigate = useNavigate();
  const { currentPage } = useParams();

  const onNavigationButtonClick = (pageKey: string): void => {
    navigate(`/app/${pageKey}`);
  };

  return (
    <ButtonGroup label="top navigation">
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: token('space.150')
        }}
      >
        <NavigationButton
          pageKey="dashboard"
          currentPage={currentPage}
          onClick={onNavigationButtonClick}
        >
          Dashboard
        </NavigationButton>
        <NavigationButton
          pageKey="secrets-inventory"
          currentPage={currentPage}
          onClick={onNavigationButtonClick}
        >
          Application Secrets
        </NavigationButton>
        <NavigationButton
          pageKey="database-users"
          currentPage={currentPage}
          isDisabled
        >
          Database users
        </NavigationButton>
        <NavigationButton
          pageKey="iam-roles"
          currentPage={currentPage}
          isDisabled
        >
          IAM Roles
        </NavigationButton>
      </Box>
    </ButtonGroup>
  );
}
