import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { useRef, useState, type ReactElement } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import IdentifierRow from './IdentifierRow';

export default function IdentifierSelect ({
  apiKeys,
  currentApiKey,
  onSelect
}: {
  apiKeys: ApiApiKey[]
  currentApiKey: ApiApiKey
  onSelect?: (apiKey: ApiApiKey) => void
}): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const filteredApiKeys = apiKeys.filter(
    (apiKey) => apiKey.applicationDomain === currentApiKey.applicationDomain
  );
  const containerRef = useRef<HTMLElement | null>(null);

  useOutsideClick(containerRef, () => {
    setIsExpanded(false);
  });

  const onRowSelect = (selectedApiKey: ApiApiKey): void => {
    onSelect?.(selectedApiKey);
    setIsExpanded(false);
  };
  return (
    <Box
      ref={containerRef}
      style={{
        flex: 1,
        position: 'relative'
      }}
    >
      <Box
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
        style={{
          backgroundColor: token('elevation.surface'),
          border: `1px solid ${token('color.border')}`,
          borderRadius: token('space.150')
        }}
      >
        <IdentifierRow withExpand apiKey={currentApiKey} />
      </Box>
      {isExpanded && (
        <Box
          style={{
            position: 'absolute',
            border: `1px solid ${token('color.border')}`,
            // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
            top: `calc(100% + ${token('space.100')})`,
            backgroundColor: token('elevation.surface'),
            width: '100%',
            borderRadius: `${token('space.150')} 0 0 ${token('space.150')}`,
            zIndex: 1,
            maxHeight: '306px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {filteredApiKeys.map((apiKey, index) => (
            <IdentifierRow
              key={index}
              apiKey={apiKey}
              onClick={() => {
                onRowSelect(apiKey);
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
