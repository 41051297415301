import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { faGauge, faGaugeMax } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BadgeGroup } from 'components/BadgeGroup';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { ViolationSeverity_ViolationSeverityEnum as ViolationSeverity } from 'gen/torch/v1/violation_engine';
import { getViolationDisplayName, sortViolations } from 'utils/ViolationUtils';

export default function ViolationsBadges ({ apiKey }: { apiKey?: ApiApiKey }): JSX.Element {
  if (apiKey?.violations == null) return <Box />;

  sortViolations(apiKey.violations);

  return (
    <Box
      key={apiKey.id}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: token('space.100'),
        height: '100%'
      }}
    >
      <BadgeGroup badges={apiKey.violations.map((violation) => ({
        value: getViolationDisplayName(violation.type),
        icon: <FontAwesomeIcon icon={violation.severity < ViolationSeverity.HIGH ? faGauge : faGaugeMax} size='xl' />,
        tooltip: `${getViolationDisplayName(violation.type)} since the threshold of ${violation.daysUntilTriggered} days has been surpassed`,
        backgroundColor: violation.severity < ViolationSeverity.HIGH ? token('color.background.accent.yellow.subtlest.hovered') : token('color.background.accent.red.subtler'),
        color: violation.severity < ViolationSeverity.HIGH ? token('color.text.accent.yellow.bolder') : token('color.text.accent.red')
      }))} badgePadding={token('space.100')} widerBadges={true} />
    </Box>
  );
}
