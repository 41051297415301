import styled from 'styled-components';

interface FlexProps {
  height?: string
  width?: string
  gap?: string
  direction: 'row' | 'column'

  // Default react container behavior
  children?: React.ReactNode
  className?: string
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};

  ${({ height }) => height != null && `height: ${height};`}
  ${({ width }) => width != null && `width: ${width};`}
  ${({ gap }) => gap != null && `gap: ${gap};`}
`;
type DirectionalFlexProps = Omit<FlexProps, 'direction'>;

const FlexRowComponent = (props: DirectionalFlexProps): JSX.Element => (
  <Flex {...props} direction="row" />
);

export const FlexRow = styled(FlexRowComponent)<DirectionalFlexProps>``;

export const FlexRowCenter = styled(FlexRow)`
  align-items: center;
`;

export const FlexRowCenterSpaceBetween = styled(FlexRowCenter)`
  justify-content: space-between;
`;

const FlexColumnComponent = (props: DirectionalFlexProps): JSX.Element => (
  <Flex {...props} direction="column" />
);

export const FlexColumn = styled(FlexColumnComponent)<DirectionalFlexProps>``;
