import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ComponentProps, type ReactElement } from 'react';

export type ToggleOptionType = Array<{
  value: unknown
  label: string
  isDisabled?: boolean
}>;

const Option = ({
  isSelected,
  option,
  ...rest
}: {
  isSelected?: boolean
  option: ToggleOptionType[number]
} & ComponentProps<typeof Box>): ReactElement => {
  return (
    <Box
      {...rest}
      style={{
        font: token('font.body'),
        cursor: (option.isDisabled ?? false) ? 'not-allowed' : 'pointer',
        textWrap: 'nowrap',
        padding: token('space.100'),
        borderRadius: token('space.050'),
        backgroundColor:
          (isSelected ?? false)
            ? token('elevation.surface.hovered')
            : token('elevation.surface'),
        fontWeight: token('font.weight.semibold'),
        color:
          (isSelected ?? false)
            ? token('color.text')
            : token('color.text.subtlest')
      }}
    >
      {option.label}
    </Box>
  );
};

export default function Toggle ({
  options,
  selectedOption,
  onChange
}: {
  options: ToggleOptionType
  selectedOption: ToggleOptionType[number]['value']
  onChange: (value: ToggleOptionType[number]['value']) => void
}): ReactElement {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: token('space.050'),
        padding: token('space.050'),
        border: `1px solid ${token('elevation.surface.hovered')}`,
        borderRadius: token('space.050'),
        backgroundColor: token('elevation.surface'),
        width: 'min-content'
      }}
    >
      {options.map((option) => (
        <Option
          key={String(option.value)}
          option={option}
          isSelected={option.value === selectedOption}
          onClick={
            (option.isDisabled ?? false)
              ? undefined
              : () => {
                  onChange(option.value);
                }
          }
        />
      ))}
    </Box>
  );
}
