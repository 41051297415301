import { type ReactElement } from 'react';
import {
  SlackIcon,
  ChatGPTIcon,
  AporiaIcon,
  GoogleGenAIIcon,
  OpenAIIcon,
  AmazonIcon,
  CircleCIIcon,
  CoverallsIcon,
  DatadogIcon,
  DockerIcon,
  GCPIcon,
  GoogleIcon,
  JenkinsIcon,
  LoadmillIcon,
  PrometheusIcon,
  SentryIcon,
  SnowflakeIcon,
  AgyleTimeIcon,
  AshbyIcon,
  FiberyIcon,
  GreenhouseIcon,
  GuruIcon,
  HarvestIcon,
  SpokeIcon,
  WorkdayIcon,
  BestowIcon,
  LesFuretsIcon,
  AgentSyncIcon,
  AllianzIcon,
  BuildFaxIcon,
  CardifIcon,
  CopartIcon,
  GuyCarpenterIcon,
  HomesiteIcon,
  HorizonIcon,
  IvansIcon,
  KelleyBlueBookIcon,
  LexisNexisIcon,
  MitchellIcon,
  MunicIcon,
  OctoIcon,
  SafeliteIcon,
  ThomsonReutersIcon,
  TransUnionIcon,
  VeriskIcon,
  XactAnalysisIcon,
  AddressCloudIcon,
  OpenWeatherMapIcon,
  RealPageIcon,
  AppStoreConnectIcon,
  AppFollowIcon,
  ApplePushNotificationServiceIcon,
  AppsFlyerIcon,
  FacebookIcon,
  FirebaseCloudMessagingIcon,
  GmailIcon,
  GoogleAdsIcon,
  GoogleMapsIcon,
  GooglePlacesIcon,
  GoogleSheetsIcon,
  IntercomIcon,
  LobIcon,
  PoplarIcon,
  SalesforceIcon,
  SegmentIcon,
  ShortIOIcon,
  StellaConnectIcon,
  TwilioIcon,
  YouCanBookMeIcon,
  ZendeskIcon,
  BoltIcon,
  AdyenIcon,
  ApplePayIcon,
  HyperwalletIcon,
  RevolutIcon,
  SpreedlyIcon,
  StripeIcon,
  VindiciaIcon,
  NickelIcon,
  OktaIcon,
  ResistantAIIcon,
  SatoriIcon,
  TraceableIcon,
  FiveTranIcon,
  GithubIcon,
  PubnubIcon,
  GroundcoverIcon,
  AxaIcon,
  EquiFaxIcon,
  RapidSosIcon,
  CustomerIoIcon,
  EmptyStateLogo,
  JotformIcon,
  SendgridIcon,
  SugarCrmIcon,
  TalkDeskIcon,
  LangAiIcon,
  AdaIcon,
  CheckoutComIcon,
  OfacIcon,
  TorqIcon,
  GPTalkAPIIcon,
  AerialIcon,
  HonkIcon,
  SuncoIcon
} from '../assets/icons/applications';
import XIcon from 'assets/icons/applications/XIcon';

const APPLICATION_MAPPER: Record<
string,
{ Icon: () => ReactElement, category: string, description?: string }
> = {
  'https://slack.com': {
    Icon: SlackIcon,
    category: 'Productivity'
  },
  'https://chatgpt.com': {
    Icon: ChatGPTIcon,
    category: 'Productivity'
  },
  'https://www.snowflake.com': {
    Icon: SnowflakeIcon,
    category: 'Cloud',
    description:
      'The firm offers a cloud-based data storage and analytics service, generally termed "data-as-a-service"'
  },
  'https://stripe.com': {
    Icon: StripeIcon,
    category: 'Billing'
  },
  'https://gptalk.io': {
    Icon: GPTalkAPIIcon,
    category: 'AI & ML',
    description: 'ChatGPT is a conversational AI model developed by OpenAI.'
  },
  'https://cloud.google.com/ai/generative-ai': {
    Icon: GoogleGenAIIcon,
    category: 'AI & ML',
    description:
      'Google GenAI provides advanced AI and ML capabilities through Google Cloud.'
  },
  'https://www.aporia.com': {
    Icon: AporiaIcon,
    category: 'AI & ML',
    description:
      'Aporia is a platform for monitoring and evaluating machine learning models.'
  },
  'https://cloud.google.com': {
    Icon: GCPIcon,
    category: 'DevOps',
    description:
      'Google Cloud Platform (GCP) provides a suite of cloud computing services that runs on the same infrastructure that Google uses internally for its end-user products.'
  },
  'https://www.openai.com': {
    Icon: OpenAIIcon,
    category: 'AI & ML',
    description:
      'OpenAI develops artificial intelligence technology and research, including GPT models.'
  },
  'https://aws.amazon.com': {
    Icon: AmazonIcon,
    category: 'DevOps',
    description:
      'Amazon Web Services offers a suite of cloud computing services.'
  },
  'https://circleci.com': {
    Icon: CircleCIIcon,
    category: 'DevOps',
    description:
      'CircleCI provides continuous integration and continuous delivery (CI/CD) services.'
  },
  'https://coveralls.io': {
    Icon: CoverallsIcon,
    category: 'DevOps',
    description:
      'Coveralls provides test coverage history and statistics for engineering teams.'
  },
  'https://www.datadoghq.com': {
    Icon: DatadogIcon,
    category: 'DevOps',
    description:
      'Datadog is a monitoring and security platform for cloud applications.'
  },
  'https://www.docker.com': {
    Icon: DockerIcon,
    category: 'DevOps',
    description:
      'Docker provides a platform to develop, ship, and run applications in containers.'
  },
  'https://www.fivetran.com': {
    Icon: FiveTranIcon,
    category: 'DevOps',
    description:
      'Fivetran provides automated data integration, delivering ready-to-use connectors that adapt as schemas and APIs change.'
  },
  'cloud.google.com': {
    Icon: GCPIcon,
    category: 'DevOps',
    description:
      'Google Cloud Platform (GCP) provides a suite of cloud computing services that runs on the same infrastructure that Google uses internally for its end-user products.'
  },
  'https://github.com': {
    Icon: GithubIcon,
    category: 'DevOps',
    description:
      'GitHub is a code hosting platform for version control and collaboration. It lets you and others work together on projects from anywhere.'
  },
  'https://www.google.com': {
    Icon: GoogleIcon,
    category: 'DevOps',
    description:
      'Google is a multinational corporation that specializes in Internet-related services and products, including online advertising technologies, a search engine, cloud computing, software, and hardware.'
  },
  'https://www.jenkins.io': {
    Icon: JenkinsIcon,
    category: 'DevOps',
    description:
      'Jenkins is an open-source automation server used to automate the non-human part of the software development process, with continuous integration and facilitating technical aspects of continuous delivery.'
  },
  'https://www.loadmill.com': {
    Icon: LoadmillIcon,
    category: 'DevOps',
    description:
      'Loadmill is an autonomous testing platform for modern development teams, providing load testing and performance insights.'
  },
  'https://prometheus.io': {
    Icon: PrometheusIcon,
    category: 'DevOps',
    description:
      'Prometheus is an open-source systems monitoring and alerting toolkit originally built at SoundCloud.'
  },
  'https://www.pubnub.com': {
    Icon: PubnubIcon,
    category: 'DevOps',
    description:
      'PubNub provides real-time communication APIs to enable developers to build secure, reliable, and scalable real-time applications.'
  },
  'https://sentry.io': {
    Icon: SentryIcon,
    category: 'DevOps',
    description:
      'Sentry provides error tracking that helps developers monitor and fix crashes in real-time.'
  },
  'https://www.groundcover.com': {
    Icon: GroundcoverIcon,
    category: 'DevOps',
    description:
      'Groundcover provides comprehensive Kubernetes observability with a focus on simplicity and scalability.'
  },
  'https://www.playvox.com/': {
    Icon: AgyleTimeIcon,
    category: 'Operations',
    description:
      'AgyleTime offers workforce management and scheduling solutions for various industries.'
  },
  'aerial.io': {
    Icon: AerialIcon,
    category: 'Insurance Data Source'
  },
  'https://www.ashbyhq.com': {
    Icon: AshbyIcon,
    category: 'Operations',
    description:
      'Ashby provides recruiting software to help companies scale their hiring processes.'
  },
  'https://fibery.io': {
    Icon: FiberyIcon,
    category: 'Operations',
    description:
      'Fibery is a work management platform that combines project management, notes, and databases.'
  },
  'https://www.greenhouse.io': {
    Icon: GreenhouseIcon,
    category: 'Operations',
    description:
      'Greenhouse is a recruiting software company that helps businesses improve their hiring processes.'
  },
  'https://www.getguru.com': {
    Icon: GuruIcon,
    category: 'Operations',
    description:
      'Guru is a knowledge management solution that enables teams to capture and share information effectively.'
  },
  'https://www.getharvest.com': {
    Icon: HarvestIcon,
    category: 'Operations',
    description:
      'Harvest provides time tracking, reporting, and invoicing software for businesses.'
  },
  'https://www.askspoke.com': {
    Icon: SpokeIcon,
    category: 'Operations',
    description:
      'Spoke offers an AI-powered ticketing and request management system for workplace operations.'
  },
  'https://www.workday.com': {
    Icon: WorkdayIcon,
    category: 'Operations',
    description:
      'Workday provides enterprise cloud applicationsfor finance and human resources.'
  },
  'https://www.bestow.com': {
    Icon: BestowIcon,
    category: 'Insurance Data Source',
    description:
      'Bestow is an online life insurance provider that offers quick and easy coverage.'
  },
  'https://www.lesfurets.com': {
    Icon: LesFuretsIcon,
    category: 'Insurance Data Source',
    description:
      'Les Furets is a comparison website for insurance, credit, and other financial products in France.'
  },
  'https://www.agentsync.io': {
    Icon: AgentSyncIcon,
    category: 'Insurance Data Source',
    description:
      'AgentSync provides compliance management solutions for the insurance industry.'
  },
  'https://www.allianz.com': {
    Icon: AllianzIcon,
    category: 'Insurance Data Source',
    description:
      'Allianz is a global financial services provider specializing in insurance and asset management.'
  },
  'https://www.axa.com': {
    Icon: AxaIcon,
    category: 'Insurance Data Source',
    description:
      'AXA is a multinational insurance firm offering life, health, and other insurance products.'
  },
  'https://www.buildfax.com': {
    Icon: BuildFaxIcon,
    category: 'Insurance Data Source',
    description:
      'BuildFax provides property condition and history data for insurance and real estate industries.'
  },
  'https://www.cardif.com': {
    Icon: CardifIcon,
    category: 'Insurance Data Source',
    description:
      'Cardif is a global insurance company offering various life and health insurance products.'
  },
  'https://www.copart.com': {
    Icon: CopartIcon,
    category: 'Insurance Data Source',
    description:
      'Copart provides online vehicle auction and remarketing services.'
  },
  'https://www.equifax.com': {
    Icon: EquiFaxIcon,
    category: 'Insurance Data Source',
    description:
      'Equifax is a global data, analytics, and technology company providing credit reports and other financial services.'
  },
  'https://www.guycarp.com': {
    Icon: GuyCarpenterIcon,
    category: 'Insurance Data Source',
    description: 'Guy Carpenter provides risk and reinsurance solutions.'
  },
  'https://www.homesite.com': {
    Icon: HomesiteIcon,
    category: 'Insurance Data Source',
    description:
      'Homesite offers home, renters, and other property insurance products.'
  },
  'https://www.horizonblue.com': {
    Icon: HorizonIcon,
    category: 'Insurance Data Source',
    description: 'Horizon offers health insurance products and services.'
  },
  'https://www.ivansinsurance.com': {
    Icon: IvansIcon,
    category: 'Insurance Data Source',
    description:
      'Ivans provides connectivity and automation solutions for the insurance industry.'
  },
  'https://www.kbb.com': {
    Icon: KelleyBlueBookIcon,
    category: 'Insurance Data Source',
    description:
      'Kelley Blue Book provides vehicle valuation and automotive research.'
  },
  'https://risk.lexisnexis.com': {
    Icon: LexisNexisIcon,
    category: 'Insurance Data Source',
    description:
      'LexisNexis offers risk management solutions and data analytics for the insurance industry.'
  },
  'https://www.mitchell.com': {
    Icon: MitchellIcon,
    category: 'Insurance Data Source',
    description:
      'Mitchell provides technology solutions and services to the property & casualty insurance industry.'
  },
  'https://www.munic.io': {
    Icon: MunicIcon,
    category: 'Insurance Data Source',
    description:
      'Munic provides connected car data solutions for insurance and fleet management.'
  },
  'https://www.octotelematics.com': {
    Icon: OctoIcon,
    category: 'Insurance Data Source',
    description:
      'Octo offers telematics solutions for insurance and fleet management.'
  },
  'https://www.rapidsos.com': {
    Icon: RapidSosIcon,
    category: 'Insurance Data Source',
    description:
      'Rapid SOS provides emergency response data integration solutions.'
  },
  'https://www.safelite.com': {
    Icon: SafeliteIcon,
    category: 'Insurance Data Source',
    description: 'Safelite offers auto glass repair and replacement services.'
  },
  'https://www.thomsonreuters.com': {
    Icon: ThomsonReutersIcon,
    category: 'Insurance Data Source',
    description:
      'Thomson Reuters offers data and information services for various industries, including insurance.'
  },
  'https://www.transunion.com': {
    Icon: TransUnionIcon,
    category: 'Insurance Data Source',
    description:
      'TransUnion provides credit reporting and data analytics services.'
  },
  'https://www.verisk.com': {
    Icon: VeriskIcon,
    category: 'Insurance Data Source',
    description:
      'Verisk offers data analytics and risk assessment solutions for insurance.'
  },
  'https://www.xactware.com': {
    Icon: XactAnalysisIcon,
    category: 'Insurance Data Source',
    description: 'Xact Analysis provides property claims estimation solutions.'
  },
  'https://www.addresscloud.com': {
    Icon: AddressCloudIcon,
    category: 'Insurance Data Source',
    description:
      'Address Cloud provides geocoding and address data solutions for the insurance industry.'
  },
  'https://www.openweathermap.org': {
    Icon: OpenWeatherMapIcon,
    category: 'Insurance Data Source',
    description:
      'OpenWeatherMap provides weather data services for various industries, including insurance.'
  },
  'https://www.realpage.com': {
    Icon: RealPageIcon,
    category: 'Insurance Data Source',
    description:
      'RealPage offers data analytics and property management solutions for the real estate industry.'
  },
  'https://developer.apple.com/app-store-connect/': {
    Icon: AppStoreConnectIcon,
    category: 'Marketing',
    description:
      'App Store Connect is a suite of tools for managing apps on the App Store.'
  },
  'https://appfollow.io': {
    Icon: AppFollowIcon,
    category: 'Marketing',
    description:
      'AppFollow provides app review management, ASO, and competitive analytics.'
  },
  'https://developer.apple.com/documentation/usernotifications': {
    Icon: ApplePushNotificationServiceIcon,
    category: 'Marketing',
    description:
      'Apple Push Notification Service (APNs) enables sending notifications to iOS devices.'
  },
  'https://www.appsflyer.com': {
    Icon: AppsFlyerIcon,
    category: 'Marketing',
    description:
      'AppsFlyer provides mobile attribution and marketing analytics.'
  },
  'ctm.com': {
    Icon: EmptyStateLogo,
    category: 'Marketing',
    description:
      'Call Tracking Metrics (CTM) offers call tracking and analytics solutions.'
  },
  'carco.com': {
    Icon: EmptyStateLogo,
    category: 'Insurance Data Source'
  },
  'cooperapi.com': {
    Icon: EmptyStateLogo,
    category: 'Insurance Data Source'
  },
  'https://customer.io': {
    Icon: CustomerIoIcon,
    category: 'Marketing',
    description:
      'Customer.io is a marketing automation platform for sending targeted messages.'
  },
  'https://www.honkforhelp.com': {
    Icon: HonkIcon,
    category: 'Insurance Data Source',
    description: 'Honk provides on-demand roadside assistance services.'
  },
  'horizon.com': {
    Icon: HorizonIcon,
    category: 'Insurance Data Source',
    description: 'Horizon offers health insurance products and services.'
  },
  'https://www.facebook.com': {
    Icon: FacebookIcon,
    category: 'Marketing',
    description:
      'Facebook is a social media platform offering advertising and marketing solutions.'
  },
  'https://firebase.google.com/products/cloud-messaging': {
    Icon: FirebaseCloudMessagingIcon,
    category: 'Marketing',
    description:
      'Firebase Cloud Messaging (FCM) allows sending notifications and messages to users.'
  },
  'lexisnexis.com': {
    Icon: LexisNexisIcon,
    category: 'Insurance Data Source',
    description:
      'LexisNexis offers risk management solutions and data analytics for the insurance industry.'
  },
  'https://mail.google.com': {
    Icon: GmailIcon,
    category: 'Marketing',
    description: 'Gmail is an email service developed by Google.'
  },
  'https://ads.google.com': {
    Icon: GoogleAdsIcon,
    category: 'Marketing',
    description: 'Google Ads provides online advertising services.'
  },
  'https://maps.google.com': {
    Icon: GoogleMapsIcon,
    category: 'Marketing',
    description: 'Google Maps offers mapping and location-based services.'
  },
  'https://developers.google.com/places': {
    Icon: GooglePlacesIcon,
    category: 'Marketing',
    description: 'Google Places provides APIs for place search and details.'
  },
  'https://www.google.com/sheets/about/': {
    Icon: GoogleSheetsIcon,
    category: 'Marketing',
    description: 'Google Sheets is an online spreadsheet application.'
  },
  'https://www.intercom.com': {
    Icon: IntercomIcon,
    category: 'Marketing',
    description:
      'Intercom provides customer messaging platforms for sales, marketing, and support.'
  },
  'https://www.jotform.com': {
    Icon: JotformIcon,
    category: 'Marketing',
    description:
      'JotForm is an online form builder for creating surveys and forms.'
  },
  'https://www.lob.com': {
    Icon: LobIcon,
    category: 'Marketing',
    description: 'Lob provides direct mail and address verification services.'
  },
  'https://heypoplar.com/': {
    Icon: PoplarIcon,
    category: 'Marketing',
    description: 'Best-In-Class, Performance Driven Direct Mail.'
  },
  'https://www.salesforce.com': {
    Icon: SalesforceIcon,
    category: 'Marketing',
    description:
      'Salesforce is a customer relationship management (CRM) platform.'
  },
  'https://segment.com': {
    Icon: SegmentIcon,
    category: 'Marketing',
    description: 'Segment provides customer data infrastructure services.'
  },
  'https://sendgrid.com': {
    Icon: SendgridIcon,
    category: 'Marketing',
    description: 'SendGrid offers email delivery and marketing services.'
  },
  'https://short.io': {
    Icon: ShortIOIcon,
    category: 'Marketing',
    description:
      'Short.io provides URL shortening and link management services.'
  },
  'https://stellaservice.zendesk.com/hc/en-us': {
    Icon: StellaConnectIcon,
    category: 'Marketing',
    description:
      'Stella Connect offers customer feedback and quality management solutions.'
  },
  'https://www.sugarcrm.com': {
    Icon: SugarCrmIcon,
    category: 'Marketing',
    description:
      'SugarCRM provides customer relationship management (CRM) software.'
  },
  'https://www.talkdesk.com': {
    Icon: TalkDeskIcon,
    category: 'Marketing',
    description: 'Talkdesk offers cloud-based contact center solutions.'
  },
  'https://www.twilio.com': {
    Icon: TwilioIcon,
    category: 'Marketing',
    description:
      'Twilio provides cloud communications platforms and APIs for messaging and voice.'
  },
  'https://x.com': {
    Icon: XIcon,
    category: 'Marketing',
    description:
      'X (formerly Twitter) is a social media platform offering advertising and marketing services.'
  },
  'https://youcanbook.me': {
    Icon: YouCanBookMeIcon,
    category: 'Marketing',
    description: 'You Can Book Me is an online scheduling tool.'
  },
  'https://www.zendesk.com': {
    Icon: ZendeskIcon,
    category: 'Marketing',
    description: 'Zendesk provides customer service and engagement software.'
  },
  'https://www.lang.ai': {
    Icon: LangAiIcon,
    category: 'Marketing',
    description:
      'Lang.ai offers AI-powered natural language processing tools for customer service and marketing.'
  },
  'https://www.bolt.com': {
    Icon: BoltIcon,
    category: 'Marketing',
    description: 'Bolt provides e-commerce checkout solutions and services.'
  },
  'https://www.ada.cx': {
    Icon: AdaIcon,
    category: 'Marketing',
    description:
      'ADA offers AI-powered chatbot and customer service automation solutions.'
  },
  'draftable.com': {
    Icon: EmptyStateLogo,
    category: 'Misc',
    description:
      'Draftable provides document comparison software to highlight changes between versions.'
  },
  'sheety.co': {
    Icon: EmptyStateLogo,
    category: 'Misc',
    description: 'Sheety allows users to turn Google Sheets into an API.'
  },
  'shipengine.com': {
    Icon: EmptyStateLogo,
    category: 'Misc',
    description:
      'ShipEngine provides shipping APIs to manage logistics and shipping operations.'
  },
  'https://www.adyen.com': {
    Icon: AdyenIcon,
    category: 'Payments',
    description:
      'Adyen provides a payment platform for businesses to accept e-commerce, mobile, and point-of-sale payments.'
  },
  'https://www.apple.com/apple-pay': {
    Icon: ApplePayIcon,
    category: 'Payments',
    description:
      'Apple Pay allows users to make payments using their Apple devices.'
  },
  'https://www.checkout.com': {
    Icon: CheckoutComIcon,
    category: 'Payments',
    description: 'Checkout.com offers global payment processing services.'
  },
  'https://www.hyperwallet.com': {
    Icon: HyperwalletIcon,
    category: 'Payments',
    description: 'Hyperwallet provides global payout solutions for businesses.'
  },
  'lightspeedhq.com': {
    Icon: EmptyStateLogo,
    category: 'Payments',
    description: 'Lightspeed offers point-of-sale and e-commerce solutions.'
  },
  'https://www.revolut.com': {
    Icon: RevolutIcon,
    category: 'Payments',
    description:
      'Revolut provides financial services, including currency exchange, debit cards, and cryptocurrency exchange.'
  },
  'https://www.spreedly.com': {
    Icon: SpreedlyIcon,
    category: 'Payments',
    description:
      'Spreedly offers a payments orchestration platform for managing multiple payment services.'
  },
  'https://www.vindicia.com': {
    Icon: VindiciaIcon,
    category: 'Payments',
    description:
      'Vindicia offers subscription billing and fraud management solutions.'
  },
  'https://nickel.eu': {
    Icon: NickelIcon,
    category: 'Payments',
    description:
      'Nickel provides a banking alternative with accessible and affordable financial services.'
  },
  'https://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx':
    {
      Icon: OfacIcon,
      category: 'Security & Compliance',
      description:
        'OFAC (Office of Foreign Assets Control) administers and enforces economic and trade sanctions.'
    },
  'https://www.okta.com': {
    Icon: OktaIcon,
    category: 'Security & Compliance',
    description: 'Okta provides identity and access management services.'
  },
  'https://www.resistant.ai': {
    Icon: ResistantAIIcon,
    category: 'Security & Compliance',
    description:
      'Resistant AI offers AI-powered solutions for detecting fraud and financial crime.'
  },
  'https://satoricyber.com': {
    Icon: SatoriIcon,
    category: 'Security & Compliance',
    description:
      'Satori provides a data security platform for managing access and security.'
  },
  'torq.io': {
    Icon: TorqIcon,
    category: 'Security & Compliance',
    description: 'Torq offers security automation and orchestration solutions.'
  },
  'https://www.traceable.ai': {
    Icon: TraceableIcon,
    category: 'Security & Compliance',
    description:
      'Traceable provides API security and protection against malicious API traffic.'
  },
  'octo.com': {
    Icon: OctoIcon,
    category: 'Insurance Data Source',
    description:
      'Octo offers telematics solutions for insurance and fleet management.'
  },
  'https://www.torq.io': {
    Icon: TorqIcon,
    category: 'Security & Compliance',
    description: 'Torq offers security automation and orchestration solutions.'
  },
  'https://github.com/': {
    Icon: GithubIcon,
    category: 'DevOps',
    description:
      'GitHub is a code hosting platform for version control and collaboration. It lets you and others work together on projects from anywhere.'
  },
  'https://appfollow.io/': {
    Icon: AppFollowIcon,
    category: 'Marketing',
    description:
      'AppFollow provides app review management, ASO, and competitive analytics.'
  },
  'sunco.com': {
    Icon: SuncoIcon,
    category: 'Insurance Data Source'
  },
  'zurs.com': {
    Icon: EmptyStateLogo,
    category: 'Insurance Data Source'
  },
  'msm.io': {
    Icon: EmptyStateLogo,
    category: 'Insurance Data Source'
  },
  'ar24.com': {
    Icon: EmptyStateLogo,
    category: 'Misc'
  },
  'sftpclient.com': {
    Icon: EmptyStateLogo,
    category: 'Misc'
  },
  'iab.com': {
    Icon: EmptyStateLogo,
    category: 'Misc'
  },
  'imagegenerator.com': {
    Icon: EmptyStateLogo,
    category: 'Misc'
  },
  'paymentgateway.com': {
    Icon: EmptyStateLogo,
    category: 'Payments'
  }
};

export default APPLICATION_MAPPER;
