import LocalStorage from 'LocalStorage';
import posthog from 'posthog-js';
import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren
} from 'react';
import { type User } from '@torchsec/shared';

interface UserContextProps {
  user: User | null
  logout: () => void
  setCurrentUser: (user: User) => void
}
const UserContext = createContext<UserContextProps | null>(null);

export default function UserProvider ({
  children
}: PropsWithChildren<any>): JSX.Element {
  const [user, setUser] = useState<User | null>(null);

  function setCurrentUser (user: User): void {
    LocalStorage.setUser(user);
    setUser(user);
    posthog.identify(user.email, {
      email: user.email
    });
  }

  function logout (): void {
    LocalStorage.removeUser();
    setUser(null);
    posthog.reset();
  }

  return (
    <UserContext.Provider value={{ user, logout, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser (): UserContextProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(UserContext)!;
}
