import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Badge = styled.div`
  background-color: ${token('color.background.accent.blue.subtle')};
  color: ${token('color.text.inverse')};
  border-radius: 12px;
  padding: ${token('space.100')};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150px;
  height: 36px;
  flex-shrink: 0;
  box-sizing: border-box;
`;

export default function NodeCell ({
  content,
  tooltip
}: {
  content: string
  tooltip: string
}): ReactElement {
  return (
    <Tooltip
      content={
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {tooltip}
        </Box>
      }
      position="right"
    >
      <Badge>{content}</Badge>
    </Tooltip>
  );
}
