import { Box } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type ReactElement } from 'react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { ActivityTab } from './ActivityTab';
import { PolicyTab } from './PolicyTab';

function TabContent ({ children }: PropsWithChildren): ReactElement {
  return (
    <TabPanel>
      <Box
        style={{
          marginTop: token('space.300'),
          paddingLeft: token('space.400'),
          paddingRight: token('space.400'),
          paddingBottom: token('space.400'),
          width: '100%'
        }}
      >
        {children}
      </Box>
    </TabPanel>
  );
}

export default function BottomTabs ({
  apiKey
}: {
  apiKey: ApiApiKey
}): ReactElement {
  return (
    <Box
      style={{
        marginTop: token('space.300')
      }}
    >
      <Tabs id="api-keys-bottom-tabs">
        <TabList>
          <Box style={{ marginLeft: token('space.400') }}>
            <Tab>Activity</Tab>
          </Box>
          <Tab>Policy</Tab>
          <Tab>Auto Rotation</Tab>
        </TabList>
        <TabContent>
          <ActivityTab apiKey={apiKey} />
        </TabContent>
        <TabContent>
          <PolicyTab initialApiKey={apiKey} />
        </TabContent>
        <TabContent>Auto Rotation coming soon!</TabContent>
      </Tabs>
    </Box>
  );
}
