import { type ValueGetterParams } from 'ag-grid-community';
import { type ApiApiKey, type ApiViolation } from 'gen/torch/v1/api';
import { getViolationDisplayName } from 'utils/ViolationUtils';
import { sortAndDedup } from './getterUtils';

export const violationsGetter = (
  params: ValueGetterParams<ApiApiKey, ApiViolation[]>
): string[] | null => {
  return sortAndDedup(
    params.data?.violations?.map((violation) =>
      getViolationDisplayName(violation.type)
    ) ?? null
  );
};

export default violationsGetter;
