import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type ComponentProps,
  type PropsWithChildren,
  type ReactElement
} from 'react';

export default function Row ({
  title,
  children,
  icon = null
}: PropsWithChildren<{
  title: string
  icon?: ComponentProps<typeof FontAwesomeIcon>['icon'] | null
}>): ReactElement {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Box
        style={{
          font: token('font.body.small'),
          fontWeight: token('font.weight.semibold'),
          textTransform: 'uppercase',
          width: '120px'
        }}
      >
        {title}
      </Box>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: icon !== null ? '15px 1fr' : '1fr',
          gap: token('space.100'),
          alignItems: 'center',
          font: token('font.body'),
          flex: 1
        }}
      >
        {icon !== null && (
          <FontAwesomeIcon
            icon={icon}
            color={token('color.icon.accent.gray')}
          />
        )}
        {children}
      </Box>
    </Box>
  );
}
