import {
  ViolationType_ViolationTypeEnum as ViolationType,
  type ViolationType_ViolationTypeEnum as ViolationTypeEnum,
  ViolationSeverity_ViolationSeverityEnum as ViolationSeverity,
  type ViolationConfig,
  ViolationConfigState_ViolationConfigStateEnum
} from 'gen/torch/v1/violation_engine';
import { type ApiViolation } from 'gen/torch/v1/api';

const ViolationDisplayNameMap: Record<ViolationTypeEnum, string> = {
  [ViolationType.UNKNOWN]: 'Unknown',
  [ViolationType.KEY_ROTATION_OVERDUE]: 'Rotation Overdue',
  [ViolationType.ORPHANED_IDENTITY]: 'Stale Identity',
  [ViolationType.OVERPRIVILEGED]: 'Overprivileged Identity',
  [ViolationType.SHARED_USE]: 'Shared Use',
  [ViolationType.EXPOSED_TO_OFFBOARDED_EMPLOYEE]:
    'Exposed to Offboarded Employee'
};

export const SEVERITY_MAPPER: Record<ViolationSeverity, string> = {
  [ViolationSeverity.UNKNOWN]: 'unknown',
  [ViolationSeverity.LOW]: 'low',
  [ViolationSeverity.MEDIUM]: 'medium',
  [ViolationSeverity.HIGH]: 'high',
  [ViolationSeverity.NONE]: 'none'
};

export function getViolationDisplayName (
  violationType: ViolationTypeEnum
): string {
  return ViolationDisplayNameMap[violationType];
}

export function getViolationVerb (violationType: ViolationTypeEnum): string {
  switch (violationType) {
    case ViolationType.KEY_ROTATION_OVERDUE:
      return 'rotated';
    case ViolationType.ORPHANED_IDENTITY:
      return 'used';
  }

  return 'taken care of';
}

export function sortViolations (violations: ApiViolation[]): void {
  violations.sort((a, b) => {
    const severityDelta = b.severity - a.severity;
    if (severityDelta !== 0) {
      return severityDelta;
    }
    return b.type - a.type;
  });
}

export function isViolationConfigEnabled (violationConfig: ViolationConfig): boolean {
  return [
    ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOT_NOTIFYING,
    ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOTIFYING,
    ViolationConfigState_ViolationConfigStateEnum.RISK_ACCEPTED
  ].includes(violationConfig.state);
}
