import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const COLOR_MAPPER = {
  success: token('color.icon.accent.green'),
  warning: token('color.background.accent.yellow.subtle'),
  info: token('color.icon.accent.blue'),
  neutral: token('color.text.accent.gray.bolder'),
  error: token('color.icon.accent.red')
};

export type StatusIndicatorStatusType = keyof typeof COLOR_MAPPER;

export default function StatusIndicator ({
  status
}: {
  status: StatusIndicatorStatusType
}): JSX.Element {
  return (
    <Box
      style={{
        borderRadius: '100%',
        backgroundColor: COLOR_MAPPER[status],
        width: token('space.100'),
        height: token('space.100')
      }}
    />
  );
}
