import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import FilterIcon from '@atlaskit/icon/glyph/filter';

const ResetFiltersButton = ({
  resetEnabled,
  reset
}: {
  resetEnabled: boolean
  reset: () => void
}): JSX.Element | null => {
  if (!resetEnabled) return null;

  return (
    <Box
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: token('space.150'),
        backgroundColor: token('color.text'),
        color: token('color.text.inverse'),
        gap: token('space.100'),
        borderRadius: '6px',
        fontSize: '14px',
        fontWeight: 500
      }}
      onClick={reset}
    >
      <FilterIcon
        label=""
        size="small"
        primaryColor={token('color.text.inverse')}
      />
      Reset filters
    </Box>
  );
};

export default ResetFiltersButton;
