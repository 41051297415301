import { Box } from '@atlaskit/primitives';
import { type ReactElement } from 'react';
import APPLICATION_MAPPER from 'mappers/applicationMapper';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { token } from '@atlaskit/tokens';
import { EmptyStateLogo } from 'assets/icons/applications';

export default function Header ({
  apiKey
}: {
  apiKey: ApiApiKey
}): ReactElement {
  const ApplicationDetails =
    APPLICATION_MAPPER[apiKey.applicationDomain] ?? null;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: token('space.400'),
        paddingRight: token('space.400'),
        paddingTop: token('space.400')
      }}
    >
      <Box
        style={{
          font: token('font.heading.large'),
          display: 'flex',
          flexDirection: 'row',
          gap: token('space.100'),
          alignItems: 'center'
        }}
      >
        {ApplicationDetails?.Icon !== undefined ? <ApplicationDetails.Icon /> : <EmptyStateLogo />}
        {apiKey.applicationName}
        {ApplicationDetails?.description !== undefined && (
          <Box
            style={{
              font: token('font.body.small'),
              color: token('color.text.disabled'),
              maxWidth: '335px',
              textAlign: 'left',
              marginLeft: token('space.300')
            }}
          >
            {ApplicationDetails.description}
          </Box>
        )}
      </Box>
    </Box>
  );
}
