// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/platform_instances.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { ObjectIdentifier } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Tenant } from "./tenants";
import { Platform_PlatformEnum } from "../../model/v1/identity";
/**
 * @generated from protobuf message torch.v1.UpsertPlaformInstanceRequest
 */
export interface UpsertPlaformInstanceRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: model.v1.Platform.PlatformEnum platform = 3;
     */
    platform: Platform_PlatformEnum;
    /**
     * @generated from protobuf field: string external_id = 4;
     */
    externalId: string;
    /**
     * @generated from protobuf field: string display_name = 5;
     */
    displayName: string;
}
/**
 * The PlatformInstance message represents an instance of a customer platform.
 * For example a GCP project or AWS account.
 *
 * @generated from protobuf message torch.v1.PlatformInstance
 */
export interface PlatformInstance {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: torch.v1.Tenant tenant = 2;
     */
    tenant?: Tenant;
    /**
     * @generated from protobuf field: model.v1.Platform.PlatformEnum platform = 3;
     */
    platform: Platform_PlatformEnum;
    /**
     * The identifier of the platform instance within the platform itself.
     * For example, project-id for a GCP project.
     * This is used by collectors to perform API calls to the platform.
     *
     * @generated from protobuf field: string external_id = 4;
     */
    externalId: string;
    /**
     * The user-configured display name of the platform instance.
     *
     * @generated from protobuf field: string display_name = 5;
     */
    displayName: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class UpsertPlaformInstanceRequest$Type extends MessageType<UpsertPlaformInstanceRequest> {
    constructor() {
        super("torch.v1.UpsertPlaformInstanceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform", kind: "enum", T: () => ["model.v1.Platform.PlatformEnum", Platform_PlatformEnum] },
            { no: 4, name: "external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.UpsertPlaformInstanceRequest
 */
export const UpsertPlaformInstanceRequest = new UpsertPlaformInstanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformInstance$Type extends MessageType<PlatformInstance> {
    constructor() {
        super("torch.v1.PlatformInstance", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant", kind: "message", T: () => Tenant },
            { no: 3, name: "platform", kind: "enum", T: () => ["model.v1.Platform.PlatformEnum", Platform_PlatformEnum] },
            { no: 4, name: "external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.PlatformInstance
 */
export const PlatformInstance = new PlatformInstance$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.PlatformInstancesService
 */
export const PlatformInstancesService = new ServiceType("torch.v1.PlatformInstancesService", [
    { name: "Upsert", options: {}, I: UpsertPlaformInstanceRequest, O: ObjectIdentifier },
    { name: "Read", options: {}, I: ObjectIdentifier, O: PlatformInstance }
]);
