import { Box } from '@atlaskit/primitives';
import AtlTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { type SyntheticEvent } from 'react';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import styled, { css } from 'styled-components';

interface StyledTextFieldProps {
  onChange: (e: SyntheticEvent) => void
  value: string

  placeholder?: string
  color?: string
  textColor?: string
  borderColor?: string
  backgroundColor?: string
  iconColor?: string
  width?: string
  hasBorder?: boolean
  borderRadius?: string
}

const StyledTextFieldContainer = styled.div<Partial<StyledTextFieldProps>>`
  & > div {
    background: ${({ backgroundColor }) =>
      backgroundColor ?? token('color.background.input')};
    ${({ borderColor, hasBorder, color }) =>
      hasBorder ?? false
        ? css`
            border: ${`2px solid ${borderColor ?? color ?? token('color.border.disabled')}}`};
          `
        : css`
            border: 0;
          `}
    ${({ borderRadius }) =>
      borderRadius !== null &&
      css`
        border-radius: ${borderRadius};
      `}
    color: ${({ textColor, color }) =>
      textColor ?? color ?? token('color.text')};
  }
`;

export const TextField: React.FC<StyledTextFieldProps> = ({
  placeholder,
  onChange,
  value,
  iconColor,
  ...props
}) => {
  return (
    <StyledTextFieldContainer {...props}>
      <AtlTextField
        value={value}
        onChange={onChange}
        elemAfterInput={
          <Box style={{ marginRight: token('space.100') }}>
            <EditorSearchIcon
              label="search"
              size="medium"
              primaryColor={iconColor ?? token('color.icon')}
            />
          </Box>
        }
        style={{
          width: '320px',
          padding: `${token('space.100')} ${token('space.150')}`
        }}
        placeholder={placeholder}
      />
    </StyledTextFieldContainer>
  );
};
