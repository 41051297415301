import { Box } from '@atlaskit/primitives';
import { type ReactElement } from 'react';

export default function FallbackErrorMessage (): ReactElement {
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      Error during loading graph
    </Box>
  );
}
