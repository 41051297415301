import { type RpcOptions, type UnaryCall } from '@protobuf-ts/runtime-rpc';
import {
  TwirpFetchTransport,
  type TwirpOptions
} from '@protobuf-ts/twirp-transport';
import { getEnvSpecificVarClient } from 'Env';
import { ApiServiceClient } from 'gen/torch/v1/api.client';

const baseUrlEnvMap = {
  local: 'http://localhost:8080/twirp',
  dev: 'https://api.dev.torch.security/twirp',
  staging: 'https://api.staging.torch.security/twirp',
  prod: 'https://api.torch.security/twirp',
  demo: 'https://api.demo.torch.security/twirp'
};

function getBaseUrl (): string {
  return getEnvSpecificVarClient(baseUrlEnvMap);
}

export function CreateApiClient (token: string): ApiServiceClient {
  // Configure options with an interceptor to add an Authorization header
  const options: TwirpOptions = {
    baseUrl: getBaseUrl(),
    sendJson: true,
    interceptors: [
      {
        interceptUnary (next, method, input, options: RpcOptions): UnaryCall {
          if (options.meta == null) {
            options.meta = {};
          }
          options.meta.Authorization = token; // Add the authorization token to the request metadata
          return next(method, input, options);
        }
      }
    ]
  };

  // Create a Twirp transport with the configured options
  const transport = new TwirpFetchTransport(options);

  // Instantiate the API client with the transport
  const apiClient = new ApiServiceClient(transport);

  return apiClient;
}
