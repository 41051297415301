// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "model/v1/identity.proto" (package "model.v1", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
// Enumeration for IdentityType

/**
 * Structure for IdentityType
 *
 * @generated from protobuf message model.v1.IdentityType
 */
export interface IdentityType {
    /**
     * @generated from protobuf field: model.v1.IdentityType.IdentityTypeEnum type = 1;
     */
    type: IdentityType_IdentityTypeEnum;
    /**
     * @generated from protobuf field: model.v1.Platform platform = 2;
     */
    platform?: Platform;
}
/**
 * @generated from protobuf enum model.v1.IdentityType.IdentityTypeEnum
 */
export enum IdentityType_IdentityTypeEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: AZURE_MANAGED_IDENTITY = 1;
     */
    AZURE_MANAGED_IDENTITY = 1,
    /**
     * @generated from protobuf enum value: GCP_SERVICE_ACCOUNT = 2;
     */
    GCP_SERVICE_ACCOUNT = 2,
    /**
     * @generated from protobuf enum value: AWS_SECRET_ACCESS_KEY = 3;
     */
    AWS_SECRET_ACCESS_KEY = 3,
    /**
     * @generated from protobuf enum value: AD_SERVICE_ACCOUNT = 4;
     */
    AD_SERVICE_ACCOUNT = 4,
    /**
     * @generated from protobuf enum value: AD_MSA = 5;
     */
    AD_MSA = 5,
    /**
     * @generated from protobuf enum value: AD_GMSA = 6;
     */
    AD_GMSA = 6,
    /**
     * @generated from protobuf enum value: CONFLUENT_API_KEY = 7;
     */
    CONFLUENT_API_KEY = 7,
    /**
     * @generated from protobuf enum value: DATADOG_API_KEY = 8;
     */
    DATADOG_API_KEY = 8,
    /**
     * TODO: Add additional types as needed
     *
     * @generated from protobuf enum value: AWS_ROLE = 9;
     */
    AWS_ROLE = 9
}
/**
 * @generated from protobuf message model.v1.PlatformType
 */
export interface PlatformType {
    /**
     * @generated from protobuf field: model.v1.PlatformType.PlatformTypeEnum type = 1;
     */
    type: PlatformType_PlatformTypeEnum;
}
/**
 * @generated from protobuf enum model.v1.PlatformType.PlatformTypeEnum
 */
export enum PlatformType_PlatformTypeEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: IAAS = 1;
     */
    IAAS = 1,
    /**
     * @generated from protobuf enum value: SAAS = 2;
     */
    SAAS = 2,
    /**
     * @generated from protobuf enum value: ON_PREM = 3;
     */
    ON_PREM = 3
}
/**
 * Structure for Platform
 *
 * @generated from protobuf message model.v1.Platform
 */
export interface Platform {
    /**
     * @generated from protobuf field: model.v1.Platform.PlatformEnum platform = 1;
     */
    platform: Platform_PlatformEnum;
    /**
     * @generated from protobuf field: model.v1.PlatformType type = 2;
     */
    type?: PlatformType;
}
/**
 * @generated from protobuf enum model.v1.Platform.PlatformEnum
 */
export enum Platform_PlatformEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: GCP = 1;
     */
    GCP = 1,
    /**
     * TODO: Add more platforms as needed
     *
     * @generated from protobuf enum value: AWS = 2;
     */
    AWS = 2
}
/**
 * Structure for Identity
 *
 * @generated from protobuf message model.v1.Identity
 */
export interface Identity {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: model.v1.Person owner = 3;
     */
    owner?: Person;
    /**
     * @generated from protobuf field: model.v1.IdentityType type = 4;
     */
    type?: IdentityType;
}
/**
 * @generated from protobuf message model.v1.Person
 */
export interface Person {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class IdentityType$Type extends MessageType<IdentityType> {
    constructor() {
        super("model.v1.IdentityType", [
            { no: 1, name: "type", kind: "enum", T: () => ["model.v1.IdentityType.IdentityTypeEnum", IdentityType_IdentityTypeEnum] },
            { no: 2, name: "platform", kind: "message", T: () => Platform }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message model.v1.IdentityType
 */
export const IdentityType = new IdentityType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformType$Type extends MessageType<PlatformType> {
    constructor() {
        super("model.v1.PlatformType", [
            { no: 1, name: "type", kind: "enum", T: () => ["model.v1.PlatformType.PlatformTypeEnum", PlatformType_PlatformTypeEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message model.v1.PlatformType
 */
export const PlatformType = new PlatformType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Platform$Type extends MessageType<Platform> {
    constructor() {
        super("model.v1.Platform", [
            { no: 1, name: "platform", kind: "enum", T: () => ["model.v1.Platform.PlatformEnum", Platform_PlatformEnum] },
            { no: 2, name: "type", kind: "message", T: () => PlatformType }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message model.v1.Platform
 */
export const Platform = new Platform$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Identity$Type extends MessageType<Identity> {
    constructor() {
        super("model.v1.Identity", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "owner", kind: "message", T: () => Person },
            { no: 4, name: "type", kind: "message", T: () => IdentityType }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message model.v1.Identity
 */
export const Identity = new Identity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Person$Type extends MessageType<Person> {
    constructor() {
        super("model.v1.Person", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message model.v1.Person
 */
export const Person = new Person$Type();
