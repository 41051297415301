import { type AwsSsoUserData } from 'gen/torch/v1/api';

const AWS_SSO_USERS_MOCK: AwsSsoUserData[] = [
  {
    id: 'a1b2c3d4-e5f6-7890-a1b2-c3d4e5f67890',
    stableId: 'abcdef12-3456-7890-abcd-ef1234567890',
    platformInstanceId: 'platform-instance-1',
    externalResourceName: 'john.doe@demo.torch.security',
    userName: 'john.doe@demo.torch.security',
    displayName: 'John Doe',
    email: 'john.doe@demo.torch.security'
  },
  {
    id: 'b2c3d4e5-f678-90a1-b2c3-d4e5f6789012',
    stableId: '12345678-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-1',
    externalResourceName: 'jane.smith@demo.torch.security',
    userName: 'jane.smith@demo.torch.security',
    displayName: 'Jane Smith',
    email: 'jane.smith@demo.torch.security'
  },
  {
    id: 'c3d4e5f6-7890-a1b2-c3d4-e5f678901234',
    stableId: '34567890-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-2',
    externalResourceName: 'robert.johnson@demo.torch.security',
    userName: 'robert.johnson@demo.torch.security',
    displayName: 'Robert Johnson',
    email: 'robert.johnson@demo.torch.security'
  },
  {
    id: 'd4e5f678-90a1-b2c3-d4e5-f67890123456',
    stableId: '56789012-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-2',
    externalResourceName: 'emily.davis@demo.torch.security',
    userName: 'emily.davis@demo.torch.security',
    displayName: 'Emily Davis',
    email: 'emily.davis@demo.torch.security'
  },
  {
    id: 'e5f67890-a1b2-c3d4-e5f6-789012345678',
    stableId: '78901234-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-3',
    externalResourceName: 'michael.brown@demo.torch.security',
    userName: 'michael.brown@demo.torch.security',
    displayName: 'Michael Brown',
    email: 'michael.brown@demo.torch.security'
  },
  {
    id: 'f6789012-34a5-b6c7-d8e9-f01234567890',
    stableId: '90123456-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-3',
    externalResourceName: 'linda.wilson@demo.torch.security',
    userName: 'linda.wilson@demo.torch.security',
    displayName: 'Linda Wilson',
    email: 'linda.wilson@demo.torch.security'
  },
  {
    id: '67890123-45b6-c7d8-e9f0-1234567890ab',
    stableId: 'abcdef12-3456-7890-abcd-ef1234567890',
    platformInstanceId: 'platform-instance-4',
    externalResourceName: 'james.miller@demo.torch.security',
    userName: 'james.miller@demo.torch.security',
    displayName: 'James Miller',
    email: 'james.miller@demo.torch.security'
  },
  {
    id: '78901234-56c7-d8e9-f012-34567890abcd',
    stableId: '23456789-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-4',
    externalResourceName: 'susan.wilson@demo.torch.security',
    userName: 'susan.wilson@demo.torch.security',
    displayName: 'Susan Wilson',
    email: 'susan.wilson@demo.torch.security'
  },
  {
    id: '89012345-67d8-e9f0-1234-567890abcdef',
    stableId: '34567890-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-5',
    externalResourceName: 'david.jones@demo.torch.security',
    userName: 'david.jones@demo.torch.security',
    displayName: 'David Jones',
    email: 'david.jones@demo.torch.security'
  },
  {
    id: '90123456-78e9-f012-3456-7890abcdef12',
    stableId: '45678901-abcd-ef12-3456-7890abcdef12',
    platformInstanceId: 'platform-instance-5',
    externalResourceName: 'laura.moore@demo.torch.security',
    userName: 'laura.moore@demo.torch.security',
    displayName: 'Laura Moore',
    email: 'laura.moore@demo.torch.security'
  }
];

export default AWS_SSO_USERS_MOCK;
