import { useState, useEffect } from 'react';

const DEFAULT_DELAY_MS = 100;

export default function useDebounce<T> (
  value: T,
  delay: number = DEFAULT_DELAY_MS
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
