import Button from '@atlaskit/button/new';
import { type ComponentProps } from 'react';

interface NavigationButtonProps
  extends Omit<ComponentProps<typeof Button>, 'onClick'> {
  pageKey: string
  currentPage?: string
  onClick?: (pageKey: string) => void
}

export default function NavigationButton ({
  pageKey,
  currentPage,
  onClick,
  ...props
}: NavigationButtonProps): JSX.Element {
  const isSelected = currentPage === pageKey;

  return (
    <Button
      onClick={() => onClick?.(pageKey)}
      appearance="subtle"
      isSelected={isSelected}
      {...props}
    ></Button>
  );
}
