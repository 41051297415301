import { useMemo, useState } from 'react';

export interface usePaginationProps {
  totalLength: number
  pageSize: number
  setPage: (page: number) => void
  timestampIndicator?: JSX.Element | null
}

export interface PaginationData {
  pages: number[]
  onPageChange: (e: any, page: number) => void
  currentPage: number
  pageDisplayStatus: string
}

const usePagination = ({
  totalLength,
  pageSize,
  setPage
}: usePaginationProps): PaginationData => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (e: any, page: number): void => {
    if (page >= 1) {
      setCurrentPage(page);
      setPage(page);
    }
  };

  const pages = useMemo(() => {
    // Reset to first page if total length changes
    setCurrentPage(1);
    if (totalLength !== 0) {
      return [...Array(Math.ceil(totalLength / pageSize))].map((e, i) => i + 1);
    }

    return [1];
  }, [totalLength]);

  let pageDisplayStatus = '';
  if (totalLength === 0) {
    pageDisplayStatus = 'No results found';
  } else {
    pageDisplayStatus = `Showing ${pageSize * (currentPage - 1) + 1} - ${Math.min(pageSize * currentPage, totalLength)} out of ${totalLength}`;
  }

  return {
    pages,
    onPageChange,
    currentPage,
    pageDisplayStatus
  };
};

export default usePagination;
