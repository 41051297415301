import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type FormEvent, type ReactElement, useState, useEffect } from 'react';
import {
  faRetweet,
  faLock,
  faCalendar,
  faEnvelope
} from '@fortawesome/pro-regular-svg-icons';
import { ParentType_ParentTypeEnum, type ApiApiKey } from 'gen/torch/v1/api';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import VAULT_MAPPER from 'mappers/vaultMapper';
import styled from 'styled-components';
import useDebounce from 'hooks/useDebounce';
import useSetNote from 'mutations/useSetNote';
import { writeToNodeAndRefresh } from 'utils/AgGridUtils';
import { type GridApi, type IRowNode } from 'ag-grid-community';
import IdentifierSelect from '../IdentifierSelect/IdentifierSelect';
import InformationRow from './InformationRow';
import { OwnerRenderer } from 'pages/InventoryPage/cells/renderers';
import { DateTime } from 'luxon';
import RiskInformation from './RiskInformation';

const NoteInput = styled.input`
  border: none;
  font: ${token('font.body')};
`;

const formatTimestamp = (timestamp: Timestamp): string => {
  return Timestamp.toDate(timestamp).toLocaleDateString();
};

const formatTimestampRelative = (timestamp: Timestamp): string | null => {
  return DateTime.fromJSDate(Timestamp.toDate(timestamp)).toRelative();
};

export default function InformationSection ({
  apiKey,
  apiKeys,
  gridParams,
  onSelect
}: {
  apiKey: ApiApiKey
  apiKeys: ApiApiKey[]
  gridParams: { api: GridApi<ApiApiKey>, node: IRowNode<ApiApiKey> }
  onSelect?: (selectedApiKey: ApiApiKey) => void
}): ReactElement {
  const [noteValue, setNoteValue] = useState(apiKey.note);
  const [noteValueEdited, setNoteValueEdited] = useState(false);
  const debouncedNoteValue = useDebounce(noteValue, 500);

  useEffect(() => {
    setNoteValue(apiKey.note);
  }, [apiKey]);

  const { mutate: setNote } = useSetNote({
    onSuccess: () => {
      const rowNode = gridParams.api.getRowNode(apiKey.id);
      if (apiKey != null && rowNode !== undefined) {
        writeToNodeAndRefresh<ApiApiKey>({
          node: rowNode,
          gridApi: gridParams.api,
          key: 'note',
          value: debouncedNoteValue
        });
      }
    }
  });

  const onChangeComment = (e: FormEvent<HTMLInputElement>): void => {
    setNoteValueEdited(true);
    setNoteValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (noteValueEdited) {
      setNote({
        note: debouncedNoteValue,
        parent: apiKey,
        parentType: ParentType_ParentTypeEnum.API_KEYS
      });
    }
  }, [debouncedNoteValue]);

  const handleOwnerSelect = (newOwner: string): void =>
    onSelect?.({ ...apiKey, owner: newOwner });

  return (
    <Box
      style={{
        marginTop: token('space.300'),
        paddingLeft: token('space.400'),
        paddingRight: token('space.400')
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: token('space.150')
        }}
      >
        <InformationRow title="Identifier">
          <IdentifierSelect
            currentApiKey={apiKey}
            apiKeys={apiKeys}
            onSelect={onSelect}
          />
        </InformationRow>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: token('space.150'),
              flex: 1
            }}
          >
            <InformationRow title="Last rotated" icon={faRetweet}>
              {apiKey.lastRotatedAt !== undefined
                ? formatTimestampRelative(apiKey.lastRotatedAt)
                : 'Unknown date'}
            </InformationRow>
            <InformationRow title="Last used" icon={faLock}>
              {apiKey.lastUsed !== undefined
                ? formatTimestampRelative(apiKey.lastUsed)
                : 'Unknown date'}
            </InformationRow>
            <InformationRow title="Created" icon={faCalendar}>
              {apiKey.createdAt !== undefined
                ? formatTimestamp(apiKey.createdAt)
                : 'Unknown date'}{' '}
              on {VAULT_MAPPER[apiKey.vault].name ?? 'unknown vault'}
            </InformationRow>
            <InformationRow title="Owner">
              <OwnerRenderer
                value={apiKey.owner}
                node={gridParams.node}
                data={apiKey}
                api={gridParams.api}
                onSelect={handleOwnerSelect}
              />
            </InformationRow>
            <InformationRow title="Notes" icon={faEnvelope}>
              <NoteInput
                value={noteValue}
                onChange={onChangeComment}
                placeholder="Add comment"
                style={{
                  height: token('space.250'),
                  border: 'none',
                  outline: 'none'
                }}
              />
            </InformationRow>
          </Box>
          <Box style={{ flex: 1 }}>
            <RiskInformation apiKey={apiKey} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
