import { type GridApi, type IRowNode } from 'ag-grid-community';

interface WriteToNodeAndRefreshParams<Row = any, Value = any> {
  node: IRowNode<Row>
  gridApi: GridApi<Row> | undefined
  key: string
  value: Value
}

export const writeToNodeAndRefresh = <Row = any, Value = any>({
  node,
  gridApi,
  key,
  value
}: WriteToNodeAndRefreshParams<Row, Value>): void => {
  node.setDataValue(key, value);
  gridApi?.refreshCells({ rowNodes: [node], columns: [key] });
};

export const getFilteredRows = <Row = any>(gridApi: GridApi<Row> | undefined): Row[] => {
  const allRows: Row[] = [];

  if (gridApi != null) {
    gridApi.forEachNodeAfterFilter((node: any) => {
      if (node.data != null) {
        allRows.push(node.data as Row);
      }
    });
  }

  return allRows;
};
