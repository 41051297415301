import { StatusCard, StatusCardContainer } from 'components/StatusCard';
import { ViolationType_ViolationTypeEnum } from 'gen/torch/v1/violation_engine';
import { useMemo } from 'react';
import { getViolationDisplayName } from 'utils/ViolationUtils';
import { type ApiApiKey } from 'gen/torch/v1/api';

interface InventoryPageHeadersProps {
  accessKeys: ApiApiKey[]
  setFilter: (field: string, value: string) => Promise<void>
}

export default function InventoryPageHeaders ({
  accessKeys,
  setFilter
}: InventoryPageHeadersProps): JSX.Element {
  const countViolationsByType = (
    type: ViolationType_ViolationTypeEnum
  ): number => {
    return (
      accessKeys?.filter(
        (key) =>
          key.violations.find((violation) => violation.type === type) != null
      ).length ?? 0
    );
  };

  const rotationOverduesCount = useMemo(
    () =>
      countViolationsByType(
        ViolationType_ViolationTypeEnum.KEY_ROTATION_OVERDUE
      ),
    [accessKeys]
  );
  const stalesCount = useMemo(
    () =>
      countViolationsByType(ViolationType_ViolationTypeEnum.ORPHANED_IDENTITY),
    [accessKeys]
  );

  // TODO - Add empty and error states and display progress state only on isFetching from the query
  return (
    <StatusCardContainer>
      <StatusCard value="TBD" title="Overall security score" />
      <StatusCard
        value={String(rotationOverduesCount + stalesCount)}
        title="Violations"
        stats={[
          {
            label: 'Rotation overdue',
            value: rotationOverduesCount,
            onClick: () => {
              setFilter(
                'violations',
                getViolationDisplayName(
                  ViolationType_ViolationTypeEnum.KEY_ROTATION_OVERDUE
                )
              ).catch(console.error);
            }
          },
          {
            label: 'Stale secret',
            value: stalesCount,
            onClick: () => {
              setFilter(
                'violations',
                getViolationDisplayName(
                  ViolationType_ViolationTypeEnum.ORPHANED_IDENTITY
                )
              ).catch(console.error);
            }
          }
        ]}
      />
      <StatusCard
        value={String(accessKeys?.length ?? 0)}
        title="Total application secrets"
        stats={[
          {
            icon: 'awsSecretsManager',
            label: 'AWS Secret Manager',
            value: accessKeys?.length ?? 0
          }
        ]}
      />
    </StatusCardContainer>
  );
}
