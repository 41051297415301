import {
  type ReactElement,
  type ComponentProps,
  type PropsWithChildren
} from 'react';
import AtlasTooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';

type VariantType = 'primary' | 'secondary';
type TooltipProps = PropsWithChildren &
ComponentProps<typeof AtlasTooltip> & { variant?: VariantType };

const commonStyles: ComponentProps<typeof TooltipPrimitive>['style'] = {
  borderRadius: '3px',
  boxSizing: 'content-box',
  padding: `${token('space.100')} ${token('space.150')}`
};

const InlineDialogPrimary = styled(TooltipPrimitive)({
  background: token('color.text'),
  color: token('color.text.inverse'),
  ...commonStyles
});

const InlineDialogSecondary = styled(TooltipPrimitive)({
  background: token('color.text.inverse'),
  color: token('color.text'),
  ...commonStyles
});

function Tooltip ({
  children,
  variant = 'primary',
  ...props
}: TooltipProps): ReactElement {
  return (
    <AtlasTooltip
      delay={0}
      component={
        variant === 'primary' ? InlineDialogPrimary : InlineDialogSecondary
      }
      {...props}
    >
      {children}
    </AtlasTooltip>
  );
}

export default Tooltip;
