import {
  ViolationConfigState_ViolationConfigStateEnum,
  type ViolationConfig
} from 'gen/torch/v1/violation_engine';

const VIOLATION_CONFIGS_MOCK: ViolationConfig[] = [
  {
    id: '78eebc89-9628-462f-ad0f-71626aa5b78d',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    apiKeyStableId: '1caf7376-603a-5521-a5bb-5be9a250c4f3',
    violationType: 2,
    daysUntilTriggered: 90,
    state: ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOT_NOTIFYING
  },
  {
    id: 'dbe5c7f3-6816-42f8-9181-52b712f82160',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    apiKeyStableId: '1caf7376-603a-5521-a5bb-5be9a250c4f3',
    violationType: 3,
    daysUntilTriggered: 90,
    state: ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOT_NOTIFYING
  }
];
export default VIOLATION_CONFIGS_MOCK;
