import { useApi } from 'contexts/ApiProvider';
import { type Empty } from 'gen/google/protobuf/empty';
import { type ApiApiKey } from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import { API_KEYS_QUERY_KEY } from 'queries/useApiKeys';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';
import handleError from 'utils/handleError';

interface ReopenRiskParams {
  violationStableId: ApiApiKey['violations'][number]['stableId']
}

interface UseReopenRiskProps {
  onSuccess?: () => void
  onError?: (e?: Error) => void
}

const useReopenRisk = ({
  onSuccess,
  onError
}: UseReopenRiskProps): UseMutationResult<
Empty,
Error,
ReopenRiskParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError =
    'We encountered an issue while attempting to reopen the risk. Please try again later or contact support if the problem continues.';

  return useMutation(
    async ({ violationStableId }: ReopenRiskParams): Promise<Empty> => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return {
          success: true
          // Note - When an onSuccess is being implement - make sure to pass here the necessary parameters it expects
        };
      }

      await queryClient.cancelQueries([API_KEYS_QUERY_KEY]);
      return await api.reopenRisk({
        violationStableId: violationStableId
      }).response;
    },
    {
      onSuccess,
      onError: (e: Error) => {
        handleError({ message: apiError, error: e, onError });
      }
    }
  );
};

export default useReopenRisk;
