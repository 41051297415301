import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ReactElement } from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export type DataType = Array<{
  label: string
  labelShort: string
  value: number
}>;

const CustomTooltip = ({
  active,
  payload
}: {
  active?: boolean
  payload?: Array<{ payload: DataType[number] }>
}): ReactElement | null => {
  if ((active ?? false) && payload !== undefined && payload.length > 0) {
    return (
      <Box
        style={{
          background: token('elevation.surface.hovered'),
          border: `1px solid ${token('color.border.selected')}`,
          padding: token('space.200')
        }}
      >
        <p className="label">{`${payload[0].payload.label} : ${payload[0].payload.value}`}</p>
      </Box>
    );
  }

  return null;
};

export default function UsageChart ({
  data,
  width,
  height
}: {
  data: DataType
  width: number
  height: number
}): ReactElement {
  return (
    <LineChart width={width} height={height} data={data}>
      <XAxis dataKey="labelShort" />
      <YAxis dataKey="value" />
      <Tooltip content={<CustomTooltip />} />
      <Line
        type="linear"
        dataKey="value"
        stroke={token('color.chart.brand')}
        strokeWidth={3}
        yAxisId={0}
      />
    </LineChart>
  );
}
