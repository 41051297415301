import { Box } from '@atlaskit/primitives';
import { TopNavigation } from '@atlaskit/page-layout';
import { token } from '@atlaskit/tokens';
import ProfileMenu from 'components/ProfileMenu';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import NavigationButtons from './NavigationButtons';

const Logo = styled.img`
  width: 103px;

  &:hover {
    cursor: pointer;
  }
`;

export default function TopNavigationBar (): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <TopNavigation height={80}>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            height: '100%',
            padding: `0px ${token('space.400')}`,
            alignItems: 'center',
            backgroundColor: token('elevation.surface'),
            boxShadow: token('elevation.shadow.overlay'),
            gap: token('space.200'),
            // avoid layout shifting when switching pages (scrollbar)
            width: '100vw'
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: token('space.200')
            }}
          >
            <Box>
              <Logo
                src="/assets/torch/torch-logo.png"
                onClick={() => {
                  navigate('/app/identity-inventory');
                }}
              />
            </Box>
            <Box
              style={{
                fontSize: '14px',
                lineHeight: '14px',
                color: token('color.text'),
                width: '180px'
              }}
            >
              Non-Human Identity Management
            </Box>
          </Box>
          <Box style={{}}>
            <NavigationButtons />
          </Box>
          <Box
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            <ProfileMenu />
          </Box>
        </Box>
      </TopNavigation>
    </>
  );
}
