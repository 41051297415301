import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { type ReactElement } from 'react';
import AWSIcon from 'assets/icons/platforms/AWSIcon';
import { Platform_PlatformEnum } from 'gen/model/v1/identity';

const PLATFORM_ICONS: Record<string, () => ReactElement> = {
  [Platform_PlatformEnum.AWS]: AWSIcon
};

function EnvironmentRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  const Icon =
    data?.environment != null
      ? (PLATFORM_ICONS[data.environment] ?? null)
      : null;

  return (
    <Box
      key={data?.id}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: token('space.100'),
        height: '100%',
        fontSize: '12px'
      }}
    >
      {Icon != null && <Icon />}
      <Box style={{ lineHeight: '14px' }}>{value}</Box>
    </Box>
  );
}

export default EnvironmentRenderer;
