import { type ICellRendererParams } from 'ag-grid-community';
import { BadgeGroup } from 'components/BadgeGroup';
import { type ApiRoleConsumers, type ApiApiKey } from 'gen/torch/v1/api';
import { flatten, uniqBy } from 'lodash';
import { useMemo } from 'react';

function ConsumersRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, ApiRoleConsumers[]>): JSX.Element {
  const edgeConsumers = useMemo(() => {
    return uniqBy(
      flatten([
        ...(value?.map(
          (roleConsumers) =>
            roleConsumers?.consumers?.filter(
              (consumer) => consumer.consumerType !== 'AWS_IAM_ROLE'
            ) ?? []
        ) ?? []),
        ...(value?.map((roleConsumers) =>
          flatten(
            roleConsumers?.consumers
              ?.filter((consumer) => consumer.consumerType === 'AWS_IAM_ROLE')
              ?.map((consumer) => consumer.consumerConsumers ?? []) ?? []
          )
        ) ?? [])
      ]),
      'consumerExternalId'
    ).sort((a, b) =>
      a.consumerDisplayName.localeCompare(b.consumerDisplayName)
    );
  }, [data?.roleConsumers]);

  return (
    <BadgeGroup
      badges={edgeConsumers.map((consumer) => {
        return { value: consumer.consumerDisplayName };
      })}
      badgeMaxWidth={100}
    />
  );
}

export default ConsumersRenderer;
