import {
  type PropsWithChildren,
  useMemo,
  useState,
  type ReactElement
} from 'react';
import UsageChart, { type DataType } from 'components/charts/UsageChart';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ErrorBoundary } from 'react-error-boundary';
import Toggle, { type ToggleOptionType } from 'components/Toggle/Toggle';
import AccessGraph from '../AccessGraph/AccessGraph';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { faExpand } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccessGraphModal from '../AccessGraph/AccessGraphModal';
import Blur from 'components/Blur/Blur';
import styled from 'styled-components';
import FallbackErrorMessage from 'components/FallbackErrorMessage';

// TODO: use real data
const EXAMPLE_DATA_YEAR: DataType = [
  {
    label: 'January',
    labelShort: 'Jan',
    value: 4
  },
  {
    label: 'February',
    labelShort: 'Feb',
    value: 2
  },
  {
    label: 'March',
    labelShort: 'Mar',
    value: 8
  },
  {
    label: 'April',
    labelShort: 'Apr',
    value: 6
  },
  {
    label: 'May',
    labelShort: 'May',
    value: 0
  },
  {
    label: 'June',
    labelShort: 'Jun',
    value: 2
  },
  {
    label: 'July',
    labelShort: 'Jul',
    value: 12
  }
];

const EXAMPLE_DATA_MONTH: DataType = [...new Array(20).fill(0)].map(
  (_, index) => ({
    label: String(index + 1),
    labelShort: String(index + 1),
    value: Math.floor(Math.random() * 10)
  })
);

const EXAMPLE_DATA_WEEK: DataType = [
  {
    label: 'Monday',
    labelShort: 'Mon',
    value: 6
  },
  {
    label: 'Tuesday',
    labelShort: 'Tue',
    value: 1
  },
  {
    label: 'Wednesday',
    labelShort: 'Wed',
    value: 22
  },
  {
    label: 'Thursday',
    labelShort: 'Thr',
    value: 9
  },
  {
    label: 'Friday',
    labelShort: 'Fri',
    value: 0
  },
  {
    label: 'Saturday',
    labelShort: 'Sat',
    value: 0
  },
  {
    label: 'Sunday',
    labelShort: 'Sun',
    value: 4
  }
];

const SECRET_USAGE_TOGGLE_VALUES: ToggleOptionType = [
  { label: 'Last week', value: 'last-week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' }
];

const ACCESS_GRAPH_TOGGLE_VALUES: ToggleOptionType = [
  { label: 'Day', value: 'day', isDisabled: true },
  { label: 'Week', value: 'week', isDisabled: true },
  { label: 'Month', value: 'month', isDisabled: true },
  { label: 'Potential', value: 'potential' }
];

const Slot = ({
  children,
  TitleBar,
  withBackground = false
}: PropsWithChildren<{
  TitleBar?: () => ReactElement
  withBackground?: boolean
}>): ReactElement => {
  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: withBackground
          ? token('elevation.surface.sunken')
          : undefined,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        padding: token('space.300'),
        gap: token('space.400'),
        borderRadius: token('space.200')
      }}
    >
      {TitleBar !== undefined && (
        <Box
          style={{
            font: token('font.heading.medium'),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '46px'
          }}
        >
          <TitleBar />
        </Box>
      )}
      {children}
    </Box>
  );
};

const ActivityLogMockImg = styled.img`
  width: 100%;
`;

export const ActivityTab = ({
  apiKey
}: {
  apiKey: ApiApiKey
}): ReactElement => {
  const [secretUsageToggleValue, setSecretUsageToggleValue] = useState(
    SECRET_USAGE_TOGGLE_VALUES[0].value
  );
  const [accessGraphToggleValue, setAccessGraphToggleValue] = useState(
    ACCESS_GRAPH_TOGGLE_VALUES[3].value
  );
  const [isAccessGraphModalOpen, setIsAccessGraphModalOpen] = useState(false);

  const chartData = useMemo(() => {
    if (secretUsageToggleValue === 'year') {
      return EXAMPLE_DATA_YEAR;
    }
    if (secretUsageToggleValue === 'month') {
      return EXAMPLE_DATA_MONTH;
    }
    return EXAMPLE_DATA_WEEK;
  }, [secretUsageToggleValue]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: token('space.400')
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: token('space.200')
        }}
      >
        <Slot
          withBackground
          TitleBar={() => (
            <>
              Access Graph
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: token('space.100')
                }}
              >
                <Toggle
                  options={ACCESS_GRAPH_TOGGLE_VALUES}
                  selectedOption={accessGraphToggleValue}
                  onChange={(value) => {
                    setAccessGraphToggleValue(value);
                  }}
                />
                <Box
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setIsAccessGraphModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faExpand} />
                </Box>
              </Box>
            </>
          )}
        >
          <ErrorBoundary FallbackComponent={FallbackErrorMessage}>
            <AccessGraph apiKey={apiKey} />
          </ErrorBoundary>
          <AccessGraphModal
            isOpen={isAccessGraphModalOpen}
            setIsOpen={setIsAccessGraphModalOpen}
            apiKey={apiKey}
            accessGraphToggleValue={accessGraphToggleValue}
            setAccessGraphToggleValue={setAccessGraphToggleValue}
          />
        </Slot>
        <Slot
          TitleBar={() => (
            <>
              Secret usage
              <Blur>
                <Toggle
                  options={SECRET_USAGE_TOGGLE_VALUES}
                  selectedOption={secretUsageToggleValue}
                  onChange={(value) => {
                    setSecretUsageToggleValue(value);
                  }}
                />
              </Blur>
            </>
          )}
        >
          <Blur infoText="Please enable Secret Usage Analytics on CloudTrail to view this data">
            <ErrorBoundary FallbackComponent={FallbackErrorMessage}>
              <UsageChart data={chartData} width={530} height={220} />
            </ErrorBoundary>
          </Blur>
        </Slot>
      </Box>
      <Slot TitleBar={() => <>Activity Log</>}>
        <Blur infoText="Please enable Secret Usage Analytics on CloudTrail to view this data">
          <ActivityLogMockImg
            alt="activity log"
            src="/assets/mockedComponents/activityLogMock.png"
          />
        </Blur>
      </Slot>
    </Box>
  );
};
