import { useQuery, type UseQueryResult } from 'react-query';
import { useApi } from 'contexts/ApiProvider';
import { type ViolationConfig } from 'gen/torch/v1/violation_engine';
import handleError from 'utils/handleError';
import LocalStorage from 'LocalStorage';
import VIOLATION_CONFIGS_MOCK from 'mocks/demo/useViolationConfigMock';

export const VIOLATION_CONFIG_QUERY_KEY = 'violationConfig';

interface UseViolationConfigProps {
  apiKeyStableId: string
}

const useViolationConfig = (
  props: UseViolationConfigProps
): UseQueryResult<ViolationConfig[], Error> => {
  const api = useApi();

  const apiError =
    'We encountered an issue while listing violation configurations for the identity. Please try again later or contact support if the problem persists.';

  return useQuery<ViolationConfig[], Error>(
    VIOLATION_CONFIG_QUERY_KEY,
    async () => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return VIOLATION_CONFIGS_MOCK;
      }
      const response = await api.listViolationConfigsForIdentity({
        apiKeyStableId: props.apiKeyStableId
      }).response;
      return response.violationConfigs;
    },
    {
      onError: (e: Error) => {
        handleError({
          message: apiError,
          error: e
        });
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false
    }
  );
};

export default useViolationConfig;
