import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ApiViolation, type ApiApiKey } from 'gen/torch/v1/api';
import { type PropsWithChildren, type ReactElement } from 'react';
import IdentifierEnvironment from './IdentifierEnvironment';
import IdentifierSeparator from './IdentifierSeparator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import VAULT_MAPPER from 'mappers/vaultMapper';
import AWSIcon from 'assets/icons/platforms/AWSIcon';
import ViolationsBadges from 'components/ViolationsBadges';

const Identifier = ({
  children,
  vault,
  violations = []
}: PropsWithChildren<{
  vault: string
  violations?: ApiViolation[]
}>): ReactElement => {
  const VaultIcon = VAULT_MAPPER[vault]?.Icon ?? null;
  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: token('space.100')
      }}
    >
      {VaultIcon !== null && <VaultIcon />}
      <Box
        style={{
          font: token('font.body'),
          fontWeight: token('font.weight.semibold')
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default function IdentifierRow ({
  apiKey,
  withExpand = false,
  onClick
}: {
  apiKey: ApiApiKey
  withExpand?: boolean
  onClick?: () => void
}): ReactElement {
  return (
    <Box
      onClick={onClick}
      style={{
        display: 'flex',
        gap: token('space.150'),
        alignItems: 'center',
        height: 'auto',
        backgroundColor: token('elevation.surface'),
        cursor: 'pointer',
        padding: `${token('space.100')} ${token('space.150')}`,
        borderRadius: token('space.150')
      }}
    >
      <IdentifierEnvironment Icon={AWSIcon}>
        {apiKey.environmentName}
      </IdentifierEnvironment>
      <IdentifierSeparator />
      <Identifier vault={apiKey.vault} violations={apiKey.violations}>
        {apiKey.identifier}
      </Identifier>
      {withExpand && (
        <>
          <Box style={{ width: '20%' }}>
            <ViolationsBadges apiKey={apiKey} />
          </Box>
          <IdentifierSeparator />
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{
              width: token('space.250'),
              height: token('space.250')
            }}
          />
        </>
      )}
    </Box>
  );
}
