import { token } from '@atlaskit/tokens';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { type SyntheticEvent, useState, memo } from 'react';
import { FlexRowCenter, FlexRowCenterSpaceBetween } from 'styled/Flex';
import { IconButton } from '@atlaskit/button/new';
import { type AgGridReact } from 'ag-grid-react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { TextField } from 'styled/Form';
import ResetFiltersButton from './ResetFiltersButton';
import MoreVertical from '@atlaskit/icon/glyph/more-vertical';
import { type FilterModel } from 'ag-grid-community';

function AccessKeysFiltersRow ({
  onSearchChange,
  gridRef,
  filters
}: {
  onSearchChange: (search: string) => void
  gridRef: React.RefObject<AgGridReact<ApiApiKey>>
  filters: FilterModel
}): JSX.Element {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSetSearch = useDebounceCallback((value: string) => {
    onSearchChange(value);
  });

  return (
    <FlexRowCenterSpaceBetween width="100%" height="40px">
      <TextField
        value={searchInput}
        onChange={(e: SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          setSearchInput(target?.value ?? '');
          debouncedSetSearch(target?.value ?? '');
        }}
        placeholder="Search"
        backgroundColor={token('color.background.accent.gray.subtlest')}
        borderRadius={token('space.100')}
      />
      <FlexRowCenter gap={token('space.100')}>
        <ResetFiltersButton
          resetEnabled={
            searchInput.length > 0 || Object.keys(filters).length > 0
          }
          reset={() => {
            setSearchInput('');
            debouncedSetSearch('');
            gridRef.current?.api.setFilterModel({});
          }}
        />
        <IconButton
          icon={MoreVertical}
          label="more"
          appearance="subtle"
          isDisabled
        />
      </FlexRowCenter>
    </FlexRowCenterSpaceBetween>
  );
}

export default memo(AccessKeysFiltersRow);
