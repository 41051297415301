// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/tokens.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { ObjectIdentifier } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { PlatformInstance } from "./platform_instances";
/**
 * @generated from protobuf message torch.v1.UpsertTokenRequest
 */
export interface UpsertTokenRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string platform_instance_id = 3;
     */
    platformInstanceId: string;
    /**
     * @generated from protobuf field: torch.v1.TokenType token_type = 4;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: string value = 5;
     */
    value: string;
}
/**
 * @generated from protobuf message torch.v1.ListTokensRequest
 */
export interface ListTokensRequest {
    /**
     * @generated from protobuf field: string tenant_id = 1;
     */
    tenantId: string;
}
/**
 * @generated from protobuf message torch.v1.ListAllTokensRequest
 */
export interface ListAllTokensRequest {
    /**
     * @generated from protobuf field: torch.v1.TokenType token_type = 1;
     */
    tokenType: TokenType;
}
/**
 * @generated from protobuf message torch.v1.ListTokensResponse
 */
export interface ListTokensResponse {
    /**
     * @generated from protobuf field: repeated torch.v1.Token results = 1;
     */
    results: Token[];
}
/**
 * @generated from protobuf message torch.v1.Token
 */
export interface Token {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: torch.v1.PlatformInstance platform_instance = 3;
     */
    platformInstance?: PlatformInstance;
    /**
     * @generated from protobuf field: torch.v1.TokenType token_type = 4;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: string value = 5;
     */
    value: string;
}
/**
 * @generated from protobuf enum torch.v1.TokenType
 */
export enum TokenType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: GCP_SERVICE_ACCOUNT_JSON = 1;
     */
    GCP_SERVICE_ACCOUNT_JSON = 1,
    /**
     * @generated from protobuf enum value: AWS_USER_ACCESS_KEY_PAIR = 2;
     */
    AWS_USER_ACCESS_KEY_PAIR = 2,
    /**
     * @generated from protobuf enum value: AWS_ROLE_ARN = 3;
     */
    AWS_ROLE_ARN = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class UpsertTokenRequest$Type extends MessageType<UpsertTokenRequest> {
    constructor() {
        super("torch.v1.UpsertTokenRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform_instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "token_type", kind: "enum", T: () => ["torch.v1.TokenType", TokenType] },
            { no: 5, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.UpsertTokenRequest
 */
export const UpsertTokenRequest = new UpsertTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTokensRequest$Type extends MessageType<ListTokensRequest> {
    constructor() {
        super("torch.v1.ListTokensRequest", [
            { no: 1, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ListTokensRequest
 */
export const ListTokensRequest = new ListTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllTokensRequest$Type extends MessageType<ListAllTokensRequest> {
    constructor() {
        super("torch.v1.ListAllTokensRequest", [
            { no: 1, name: "token_type", kind: "enum", T: () => ["torch.v1.TokenType", TokenType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ListAllTokensRequest
 */
export const ListAllTokensRequest = new ListAllTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTokensResponse$Type extends MessageType<ListTokensResponse> {
    constructor() {
        super("torch.v1.ListTokensResponse", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ListTokensResponse
 */
export const ListTokensResponse = new ListTokensResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("torch.v1.Token", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform_instance", kind: "message", T: () => PlatformInstance },
            { no: 4, name: "token_type", kind: "enum", T: () => ["torch.v1.TokenType", TokenType] },
            { no: 5, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.Token
 */
export const Token = new Token$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.TokensService
 */
export const TokensService = new ServiceType("torch.v1.TokensService", [
    { name: "Upsert", options: {}, I: UpsertTokenRequest, O: ObjectIdentifier },
    { name: "Read", options: {}, I: ObjectIdentifier, O: Token },
    { name: "ListTokens", options: {}, I: ListTokensRequest, O: ListTokensResponse },
    { name: "ListAllTokens", options: {}, I: ListAllTokensRequest, O: ListTokensResponse }
]);
