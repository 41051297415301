import { token } from '@atlaskit/tokens';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Tooltip from 'components/Tooltip';
import { Box } from '@atlaskit/primitives';

const GAP_IN_PX = 4;
const GAP_CSS = token('space.050');
const PADDING_IN_PX = 8;
const PADDING_CSS = token('space.100');

const BADGE_MIN_WIDTH = 30;
const BADGE_MAX_WIDTH = 160;

const Badge = styled.div<{
  width: string
  backgroundColor?: string
  color?: string
  padding?: string
  badgeMaxWidth?: number
}>`
  background-color: ${(props) =>
    props.backgroundColor ?? token('color.background.accent.blue.subtle')};
  color: ${(props) => props.color ?? token('color.text.inverse')};
  border-radius: 12px;
  padding: ${(props) =>
    props.padding ?? `${token('space.025')} ${PADDING_CSS}`};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: ${(props) => props.badgeMaxWidth ?? BADGE_MAX_WIDTH}px;
  flex-shrink: 0;
  width: ${(props) => props.width}; /* Dynamic width */
  box-sizing: border-box;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_CSS};
  height: 100%;
  overflow: hidden;
  width: 100%; /* Ensure the container takes the full width of the cell */
  position: relative;
`;

const OverflowBadge = styled(Badge)`
  background-color: ${token('color.background.neutral')};
  color: ${token('color.text')};
  font-weight: 500;
`;

interface BadgeModel {
  value: string
  icon?: JSX.Element
  backgroundColor?: string
  color?: string
  tooltip?: string
}

interface BadgeGroupProps {
  badges: BadgeModel[]
  widerBadges?: boolean
  badgePadding?: string
  badgeMaxWidth?: number
}

function BadgeGroup ({
  badges,
  widerBadges,
  badgePadding,
  badgeMaxWidth
}: BadgeGroupProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const overflowBadgeRef = useRef<HTMLDivElement>(null);
  const [visibleBadges, setVisibleBadges] = useState<JSX.Element[]>([]);
  const [hiddenBadgeCount, setHiddenBadgeCount] = useState<number>(0);

  const calculateTextWidth = (text: string, font: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context == null) {
      return 0;
    }

    context.font = font;
    const metrics = context.measureText(text);

    return metrics.width;
  };

  const updateVisibleBadges = (): void => {
    const container = containerRef.current;
    const overflowBadge = overflowBadgeRef.current;
    if (container != null) {
      const fontStyle = window.getComputedStyle(container).font;
      let availableWidth =
        container.clientWidth -
        (overflowBadge?.clientWidth ?? 0) -
        GAP_IN_PX -
        2 * PADDING_IN_PX;
      let visibleCount = 0;
      const badgeWidths: number[] = [];
      const badgeFullWidths: number[] = [];

      for (let i = 0; i < badges.length; i++) {
        const expectedBadgeWidth =
          calculateTextWidth(badges[i].value, fontStyle) +
          PADDING_IN_PX * 2 +
          (i > 0 ? GAP_IN_PX : 0);

        const limitedBadgeWidth = Math.min(
          Math.max(BADGE_MIN_WIDTH, expectedBadgeWidth),
          badgeMaxWidth ?? BADGE_MAX_WIDTH
        );

        if (limitedBadgeWidth > availableWidth) {
          break;
        }

        visibleCount++;
        badgeFullWidths.push(expectedBadgeWidth);
        badgeWidths.push(limitedBadgeWidth);
        availableWidth -= limitedBadgeWidth;
      }

      setVisibleBadges(
        badges.slice(0, visibleCount).map((badge, index) => (
          <Tooltip
            key={badge.tooltip ?? badge.value}
            content={badge.tooltip ?? badge.value}
            position="bottom"
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Badge
                width={
                  widerBadges == null || !widerBadges
                    ? `${badgeWidths[index]}px`
                    : ''
                }
                backgroundColor={badge.backgroundColor}
                color={badge.color}
                padding={badgePadding}
                badgeMaxWidth={badgeMaxWidth}
              >
                {badge.icon} {badge.value}
              </Badge>
            </Box>
          </Tooltip>
        ))
      );
      setHiddenBadgeCount(badges.length - visibleCount);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateVisibleBadges);
    const container = containerRef.current;

    if (container != null) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container != null) {
        resizeObserver.unobserve(container);
      }
    };
  }, [badges]);

  const invisibleBadges = badges.slice(visibleBadges.length);

  return (
    <BadgeContainer ref={containerRef}>
      {visibleBadges}
      {hiddenBadgeCount > 0 && (
        <Tooltip
          content={
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              {invisibleBadges.map((badge) => (
                <Box key={badge.value}>{badge.value}</Box>
              ))}
            </Box>
          }
          position="right"
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <OverflowBadge ref={overflowBadgeRef} width="auto">
              +{hiddenBadgeCount}
            </OverflowBadge>
          </Box>
        </Tooltip>
      )}
    </BadgeContainer>
  );
}

export default BadgeGroup;
