import { type ApiApiKey } from 'gen/torch/v1/api';
import { useCallback, useState } from 'react';
import { type GridApi } from 'ag-grid-community';
import { getFilteredRows } from 'utils/AgGridUtils';
import useDebounceCallback from 'hooks/useDebounceCallback';

export interface useFilteredCountProps {
  gridApi: GridApi<ApiApiKey> | undefined
  accessKeys: ApiApiKey[] | null
  onFiltersChanged?: (filteredRows: ApiApiKey[]) => void
}

export interface FiltersCount {
  filteredIdentitiesLength: number
  onFilterChanged: () => void
}

const useFilteredCount = ({
  gridApi,
  accessKeys,
  onFiltersChanged: onFiltersChangedCallback
}: useFilteredCountProps): FiltersCount => {
  const [filteredIdentitiesLength, setFilteredIdentitiesLength] =
    useState<number>(accessKeys == null ? 0 : accessKeys.length);

  const onFilterChanged = useCallback(() => {
    const filteredRows = getFilteredRows<ApiApiKey>(gridApi);
    setFilteredIdentitiesLength(filteredRows.length);
    onFiltersChangedCallback?.(filteredRows);
  }, [gridApi]);

  const onFilterChangedDebounced = useDebounceCallback(onFilterChanged);

  return {
    filteredIdentitiesLength,
    onFilterChanged: () => {
      onFilterChangedDebounced('');
    }
  };
};

export default useFilteredCount;
