import { Timestamp } from 'gen/google/protobuf/timestamp';
import { type ApiApiKey } from 'gen/torch/v1/api';

const API_KEYS_MOCK: ApiApiKey[] = [
  {
    id: '3a4fd234-9776-4b87-9876-efae62c52786',
    stableId: '1caf7376-603a-5521-a5bb-5be9a250c4f3',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:snowflake-secret-9797',
    apiProviderSlug: 'snowflake',
    violations: [
      {
        id: 'efd97742-7eae-44f9-8987-47fa2b9957dd',
        stableId: '28f60554-9fb9-50e0-ad79-9fcffbea92f9',
        type: 3,
        severity: 2,
        daysUntilTriggered: 90,
        isEnabled: false
      },
      {
        id: '8e9623ca-09a9-4f17-83d0-b78df11a8b8a',
        stableId: 'b4b8bbe0-73f0-524e-8bc8-065731bdca48',
        type: 2,
        severity: 2,
        daysUntilTriggered: 90,
        isEnabled: false
      }
    ],
    identifier: 'snowflake-secret-9797',
    environment: 2,
    applicationName: 'Snowflake',
    applicationDomain: 'https://www.snowflake.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-28T14:02:53.465Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-14T10:33:35.517Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-02-15T00:00:00Z')),
    owner: 'Michael Brown',
    roleConsumers: [
      {
        role: 'role8568',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7850',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6068',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4717',
            consumerDisplayName: 'role4717',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2172',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2172',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8750',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4790',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5508',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6664',
            consumerDisplayName: 'role6664',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6500',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6500',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8479',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9125',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5338',
            consumerDisplayName: 'role5338',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5543',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5543',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4226',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6986',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1001',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7270',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6170',
            consumerDisplayName: 'role6170',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3552',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3552',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1512',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5203',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3889',
            consumerDisplayName: 'role3889',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6072',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6072',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5214',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2908',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7824',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3199',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3998',
            consumerDisplayName: 'role3998',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7501',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7501',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2813',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8022',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4083',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1432',
            consumerDisplayName: 'role1432',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9018',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9018',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6267',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3318',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5319',
            consumerDisplayName: 'role5319',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8270',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8270',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2599',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2132',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3282',
            consumerDisplayName: 'role3282',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2278',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2278',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4578',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service9887',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service9887',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4447',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6823',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9920',
            consumerDisplayName: 'role9920',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9007',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9007',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9218',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3468',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4249',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5624',
            consumerDisplayName: 'role5624',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8113',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8113',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4328',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4990',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2768',
            consumerDisplayName: 'role2768',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2961',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2961',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2850',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5575',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4924',
            consumerDisplayName: 'role4924',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7545',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7545',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7405',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5157',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1032',
            consumerDisplayName: 'role1032',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7489',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7489',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5517',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2011',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8631',
            consumerDisplayName: 'role8631',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9807',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9807',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9040',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3215',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1416',
            consumerDisplayName: 'role1416',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8273',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8273',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2608',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1734',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9458',
            consumerDisplayName: 'role9458',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6611',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6611',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4280',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6943',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4241',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6849',
            consumerDisplayName: 'role6849',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6083',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1304',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9702',
            consumerDisplayName: 'role9702',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3895',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3895',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4245',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9290',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4475',
            consumerDisplayName: 'role4475',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1536',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1536',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6193',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: 'c97e9de3-e3c8-403b-815a-66466a5bdad7',
    stableId: '8b32e34f-a3de-5abf-914b-dc9f288ff45b',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:snowflake-secret-2903',
    apiProviderSlug: 'snowflake',
    violations: [
      {
        id: '51ce5863-4761-4892-9797-a84748d7257a',
        stableId: 'c014c2e8-24ad-5dc2-800b-4bb5e2e322fb',
        type: 3,
        severity: 2,
        daysUntilTriggered: 90,
        isEnabled: false
      },
      {
        id: 'd77adc4b-8ef3-4794-a4c3-555a57a33f05',
        stableId: 'cca1155a-5fe7-5352-817d-98f94b19e315',
        type: 2,
        severity: 2,
        daysUntilTriggered: 90,
        isEnabled: false
      }
    ],
    identifier: 'snowflake-secret-2903',
    environment: 2,
    applicationName: 'Snowflake',
    applicationDomain: 'https://www.snowflake.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-08-07T08:36:02.322Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-17T08:53:36.711Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-04-17T00:00:00Z')),
    owner: 'Laura Moore',
    roleConsumers: [
      {
        role: 'role1330',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9414',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7139',
            consumerDisplayName: 'role7139',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3771',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3771',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2377',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8263',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8940',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6814',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5852',
            consumerDisplayName: 'role5852',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3828',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3828',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8203',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8774',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6322',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9900',
            consumerDisplayName: 'role9900',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4000',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4000',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7741',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3032',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4638',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1778',
            consumerDisplayName: 'role1778',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3706',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3706',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1971',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3263',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8424',
            consumerDisplayName: 'role8424',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3372',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3372',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8293',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4384',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1217',
            consumerDisplayName: 'role1217',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8559',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8559',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5527',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3845',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5936',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5748',
            consumerDisplayName: 'role5748',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6723',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6723',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9279',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3460',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8386',
            consumerDisplayName: 'role8386',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5886',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5886',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3946',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6454',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9957',
            consumerDisplayName: 'role9957',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9471',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9471',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8424',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7195',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2393',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4055',
            consumerDisplayName: 'role4055',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9959',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9959',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2844',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1144',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5929',
            consumerDisplayName: 'role5929',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8529',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8529',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7656',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8284',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4098',
            consumerDisplayName: 'role4098',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1617',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4309',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8755',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3330',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8476',
            consumerDisplayName: 'role8476',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4875',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4875',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5787',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9146',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6530',
            consumerDisplayName: 'role6530',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9585',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9585',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3247',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8490',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4324',
            consumerDisplayName: 'role4324',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1235',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1235',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8046',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6172',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1875',
            consumerDisplayName: 'role1875',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4644',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4644',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2179',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6291',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7028',
            consumerDisplayName: 'role7028',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6864',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6864',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1393',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3572',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7809',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3200',
            consumerDisplayName: 'role3200',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4598',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4598',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9297',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service5624',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service5624',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6134',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1703',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6575',
            consumerDisplayName: 'role6575',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4449',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4449',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5925',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8728',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7152',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6698',
            consumerDisplayName: 'role6698',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6314',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6314',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '626451b2-ba8f-4807-a2bc-fa1b752fd3da',
    stableId: '3345df5b-0472-580a-9c96-f74941b342eb',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:twilio-secret-5602',
    apiProviderSlug: 'twilio',
    violations: [],
    identifier: 'twilio-secret-5602',
    environment: 2,
    applicationName: 'Twilio',
    applicationDomain: 'https://www.twilio.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-05T09:11:36.038Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-06-16T17:23:12.224Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-07T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role7444',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1792',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3790',
            consumerDisplayName: 'role3790',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1615',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1615',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5648',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9589',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5891',
            consumerDisplayName: 'role5891',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6325',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6325',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8274',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1869',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8418',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5785',
            consumerDisplayName: 'role5785',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5197',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5197',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2753',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9028',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1165',
            consumerDisplayName: 'role1165',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8963',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8963',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7348',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1256',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6906',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7472',
            consumerDisplayName: 'role7472',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9575',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9575',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9021',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6704',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1552',
            consumerDisplayName: 'role1552',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6666',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6666',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6066',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9786',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6779',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5116',
            consumerDisplayName: 'role5116',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8396',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8396',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4632',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9694',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9113',
            consumerDisplayName: 'role9113',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6782',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6782',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6494',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4307',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6028',
            consumerDisplayName: 'role6028',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5990',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5990',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9794',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4178',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2775',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4597',
            consumerDisplayName: 'role4597',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2728',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2728',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3323',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7871',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1737',
            consumerDisplayName: 'role1737',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4982',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4982',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5445',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3255',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8351',
            consumerDisplayName: 'role8351',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3147',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3147',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8117',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8934',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5409',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1240',
            consumerDisplayName: 'role1240',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8753',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8753',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4303',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8766',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1297',
            consumerDisplayName: 'role1297',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2976',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2524',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7769',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9015',
            consumerDisplayName: 'role9015',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1453',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1453',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7274',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3933',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6680',
            consumerDisplayName: 'role6680',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6810',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6810',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4683',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8556',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app4393',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app4393',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4520',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5383',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2473',
            consumerDisplayName: 'role2473',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8271',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8271',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7851',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4494',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5713',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4299',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3160',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9762',
            consumerDisplayName: 'role9762',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6399',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6399',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3845',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2386',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9183',
            consumerDisplayName: 'role9183',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3241',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3241',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6396',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5229',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7354',
            consumerDisplayName: 'role7354',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7731',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7731',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: 'a4d1dae3-be75-4eed-beb3-fe6649cd4756',
    stableId: '6dcacf1b-80a5-524c-9c52-7810374d853d',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:twilio-secret-8075',
    apiProviderSlug: 'twilio',
    violations: [],
    identifier: 'twilio-secret-8075',
    environment: 2,
    applicationName: 'Twilio',
    applicationDomain: 'https://www.twilio.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-04T20:16:24.227Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-11-26T08:05:20.563Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-06T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role3639',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9723',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6159',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3976',
            consumerDisplayName: 'role3976',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5070',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5070',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7203',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8110',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5461',
            consumerDisplayName: 'role5461',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6614',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6614',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3735',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2774',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2310',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7237',
            consumerDisplayName: 'role7237',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7694',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7694',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7144',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6053',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5639',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1031',
            consumerDisplayName: 'role1031',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5559',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5559',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5004',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8730',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5706',
            consumerDisplayName: 'role5706',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1739',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1739',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4532',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8328',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2835',
            consumerDisplayName: 'role2835',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2425',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6027',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4398',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1583',
            consumerDisplayName: 'role1583',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4578',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4578',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7833',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2639',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5025',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7725',
            consumerDisplayName: 'role7725',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5551',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5551',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5518',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4649',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4988',
            consumerDisplayName: 'role4988',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1187',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1187',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2976',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1698',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3959',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9118',
            consumerDisplayName: 'role9118',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5001',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5001',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4657',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9179',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4174',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8196',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4044',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1585',
            consumerDisplayName: 'role1585',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6146',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6146',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3124',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1622',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6572',
            consumerDisplayName: 'role6572',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3259',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3259',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6213',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6984',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6535',
            consumerDisplayName: 'role6535',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7528',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7528',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3862',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8199',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4917',
            consumerDisplayName: 'role4917',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3781',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3781',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9698',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5330',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1430',
            consumerDisplayName: 'role1430',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5430',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5430',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1252',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9278',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2167',
            consumerDisplayName: 'role2167',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5759',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5759',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6143',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8293',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1773',
            consumerDisplayName: 'role1773',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6887',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6887',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5284',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service9065',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service9065',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2165',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5129',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8458',
            consumerDisplayName: 'role8458',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8931',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8931',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8049',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5562',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5182',
            consumerDisplayName: 'role5182',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1530',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1530',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1797',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1371',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9080',
            consumerDisplayName: 'role9080',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7541',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7541',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3973',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '70dc0d00-e248-485e-b2a3-58dd168bc924',
    stableId: 'aac52e60-3156-5185-aa08-34294ac51344',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:zendesk-secret-8954',
    apiProviderSlug: 'zendesk',
    violations: [],
    identifier: 'zendesk-secret-8954',
    environment: 2,
    applicationName: 'Zendesk',
    applicationDomain: 'https://www.zendesk.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-05T09:11:36.648Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-06-16T17:23:16.938Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-07T00:00:00Z')),
    owner: 'Susan Wilson',
    roleConsumers: [
      {
        role: 'role2400',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7761',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6933',
            consumerDisplayName: 'role6933',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7474',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7474',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4533',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3028',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4433',
            consumerDisplayName: 'role4433',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3633',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3633',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3953',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7954',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app9159',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app9159',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2410',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7809',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2495',
            consumerDisplayName: 'role2495',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9000',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9000',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6509',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6981',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2887',
            consumerDisplayName: 'role2887',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2178',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2178',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5386',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5029',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8048',
            consumerDisplayName: 'role8048',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1204',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1204',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6917',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1343',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7877',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4710',
            consumerDisplayName: 'role4710',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1831',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1831',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4232',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5287',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6793',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3973',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6427',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3672',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2292',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1987',
            consumerDisplayName: 'role1987',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5727',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5727',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3730',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6767',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5573',
            consumerDisplayName: 'role5573',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4761',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4761',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9947',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4472',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1185',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3993',
            consumerDisplayName: 'role3993',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8274',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8274',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1937',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9751',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7041',
            consumerDisplayName: 'role7041',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2864',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2864',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6279',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9070',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8228',
            consumerDisplayName: 'role8228',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9136',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9136',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6445',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8929',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1640',
            consumerDisplayName: 'role1640',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3540',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3870',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8779',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3551',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2916',
            consumerDisplayName: 'role2916',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4645',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4645',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7233',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2381',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3846',
            consumerDisplayName: 'role3846',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3661',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3661',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6775',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7039',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9613',
            consumerDisplayName: 'role9613',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8444',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8444',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9927',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8920',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6903',
            consumerDisplayName: 'role6903',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6073',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6073',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5556',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3888',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5003',
            consumerDisplayName: 'role5003',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9020',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9020',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9718',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7419',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3269',
            consumerDisplayName: 'role3269',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2553',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2553',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9939',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4295',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5761',
            consumerDisplayName: 'role5761',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3963',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3963',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6821',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2345',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1427',
            consumerDisplayName: 'role1427',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4702',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4702',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '6f36de9f-96f3-43dc-bdcf-f37e3d70712f',
    stableId: '9e242df4-82af-5045-9e0e-b88f311cf585',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:zendesk-secret-5210',
    apiProviderSlug: 'zendesk',
    violations: [],
    identifier: 'zendesk-secret-5210',
    environment: 2,
    applicationName: 'Zendesk',
    applicationDomain: 'https://www.zendesk.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-07T20:57:45.496Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-18T10:47:11.170Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-07-25T00:00:00Z')),
    owner: 'Emily Davis',
    roleConsumers: [
      {
        role: 'role4649',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5420',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3876',
            consumerDisplayName: 'role3876',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3357',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3357',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9671',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7018',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4067',
            consumerDisplayName: 'role4067',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7361',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7361',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4118',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6868',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4916',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1184',
            consumerDisplayName: 'role1184',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5268',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5268',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7997',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1176',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4906',
            consumerDisplayName: 'role4906',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6838',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6838',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4835',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1154',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5062',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6222',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8026',
            consumerDisplayName: 'role8026',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5367',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5367',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5951',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4518',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5795',
            consumerDisplayName: 'role5795',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7673',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8126',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7665',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3960',
            consumerDisplayName: 'role3960',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9034',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9034',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4806',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5588',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2109',
            consumerDisplayName: 'role2109',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9763',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9763',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9012',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4005',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4430',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1735',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7020',
            consumerDisplayName: 'role7020',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9172',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9172',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4752',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2201',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6560',
            consumerDisplayName: 'role6560',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8640',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8640',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4883',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5664',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8937',
            consumerDisplayName: 'role8937',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8983',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8983',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2444',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6923',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2519',
            consumerDisplayName: 'role2519',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6063',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6063',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1044',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4779',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4932',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4603',
            consumerDisplayName: 'role4603',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7786',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7786',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8014',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service6637',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service6637',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3942',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1413',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9762',
            consumerDisplayName: 'role9762',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2110',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2110',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4606',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3128',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2888',
            consumerDisplayName: 'role2888',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7748',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7748',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6149',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9395',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1369',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5592',
            consumerDisplayName: 'role5592',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7883',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7883',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6957',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3040',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6760',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2142',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2754',
            consumerDisplayName: 'role2754',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8204',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8204',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4571',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4957',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9991',
            consumerDisplayName: 'role9991',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8777',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8777',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5051',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1496',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7777',
            consumerDisplayName: 'role7777',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2818',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2818',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9103',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6615',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4709',
            consumerDisplayName: 'role4709',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6653',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6653',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '521ed2c0-f307-4374-ac51-bcbe715e4d49',
    stableId: 'f2f309c6-6ee0-5bdc-af75-cc8a0b7646a4',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:github-secret-7704',
    apiProviderSlug: 'github',
    violations: [],
    identifier: 'github-secret-7704',
    environment: 2,
    applicationName: 'GitHub',
    applicationDomain: 'https://github.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-08-01T09:44:41.226Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-08T12:51:44.255Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-11T00:00:00Z')),
    owner: 'David Jones',
    roleConsumers: [
      {
        role: 'role9333',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9261',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9935',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1593',
            consumerDisplayName: 'role1593',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2392',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2392',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2412',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8927',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4319',
            consumerDisplayName: 'role4319',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9228',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9228',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1725',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service3061',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service3061',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7009',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9965',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4112',
            consumerDisplayName: 'role4112',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2314',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2314',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6061',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2654',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6855',
            consumerDisplayName: 'role6855',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8596',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8596',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4653',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1952',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9900',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1973',
            consumerDisplayName: 'role1973',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7834',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7834',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6594',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6841',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6544',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1373',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4302',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5199',
            consumerDisplayName: 'role5199',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9285',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9285',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9698',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1283',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3538',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4087',
            consumerDisplayName: 'role4087',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8355',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8355',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4298',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8479',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8617',
            consumerDisplayName: 'role8617',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1482',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1482',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4185',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1617',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4568',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1405',
            consumerDisplayName: 'role1405',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5954',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5954',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8281',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3651',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4868',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2305',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5518',
            consumerDisplayName: 'role5518',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5389',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5389',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7463',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9243',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3136',
            consumerDisplayName: 'role3136',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2702',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2702',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8271',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3230',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5728',
            consumerDisplayName: 'role5728',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5949',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5949',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9052',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5855',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4602',
            consumerDisplayName: 'role4602',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7645',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7645',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2683',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3723',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1093',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5075',
            consumerDisplayName: 'role5075',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9917',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5151',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7084',
            consumerDisplayName: 'role7084',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5746',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5746',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9158',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2617',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5416',
            consumerDisplayName: 'role5416',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4369',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4369',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3492',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5131',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8282',
            consumerDisplayName: 'role8282',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2575',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2575',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6839',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3581',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5157',
            consumerDisplayName: 'role5157',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3187',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3187',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1813',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6683',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6205',
            consumerDisplayName: 'role6205',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1092',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1092',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3993',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2411',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6564',
            consumerDisplayName: 'role6564',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4501',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4501',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: 'f520a42a-757a-4f20-a9b4-90476d1f6af6',
    stableId: '96064a8b-5d2e-53b3-8ebd-894987e94d26',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:github-secret-6212',
    apiProviderSlug: 'github',
    violations: [],
    identifier: 'github-secret-6212',
    environment: 2,
    applicationName: 'GitHub',
    applicationDomain: 'https://github.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-12-18T10:39:13.487Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-03-06T15:24:51.932Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-03-06T00:00:00Z')),
    owner: 'Jane Smith',
    roleConsumers: [
      {
        role: 'role1108',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5906',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6891',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3769',
            consumerDisplayName: 'role3769',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2181',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2181',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4292',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1165',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6028',
            consumerDisplayName: 'role6028',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2495',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2495',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7455',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7316',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4881',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3967',
            consumerDisplayName: 'role3967',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2340',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2340',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7404',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7903',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6497',
            consumerDisplayName: 'role6497',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3196',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3196',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3652',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8260',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4187',
            consumerDisplayName: 'role4187',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5727',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5727',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9419',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8445',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7740',
            consumerDisplayName: 'role7740',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6142',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8521',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9490',
            consumerDisplayName: 'role9490',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2282',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2282',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7781',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4935',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3405',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1139',
            consumerDisplayName: 'role1139',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4278',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4278',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2564',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9594',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5847',
            consumerDisplayName: 'role5847',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2869',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2869',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9124',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3832',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5241',
            consumerDisplayName: 'role5241',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6528',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6528',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3723',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3782',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8172',
            consumerDisplayName: 'role8172',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3403',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3403',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3168',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3891',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2958',
            consumerDisplayName: 'role2958',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7145',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7145',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9476',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2347',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2254',
            consumerDisplayName: 'role2254',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5821',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5821',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3836',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7131',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1645',
            consumerDisplayName: 'role1645',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8205',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8205',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5378',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2905',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1285',
            consumerDisplayName: 'role1285',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2817',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2817',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5822',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4501',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9145',
            consumerDisplayName: 'role9145',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4534',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4534',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5606',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9730',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4403',
            consumerDisplayName: 'role4403',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7878',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7878',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4740',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3928',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5640',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9285',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7577',
            consumerDisplayName: 'role7577',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1073',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1073',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2239',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1931',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app6632',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app6632',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7892',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7477',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8309',
            consumerDisplayName: 'role8309',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5619',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5619',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9159',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4478',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9822',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1337',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5364',
            consumerDisplayName: 'role5364',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5485',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5485',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7373',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2317',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '54bcfad4-a5cf-467a-9bbe-793d05accf0b',
    stableId: 'b83f80b9-f266-561b-bed8-b2d876d96153',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:facebook-secret-2235',
    apiProviderSlug: 'facebook',
    violations: [],
    identifier: 'facebook-secret-2235',
    environment: 2,
    applicationName: 'Facebook',
    applicationDomain: 'https://www.facebook.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-05T09:11:43.540Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-06-16T17:23:13.611Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-07T00:00:00Z')),
    owner: 'Susan Wilson',
    roleConsumers: [
      {
        role: 'role1696',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2541',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7758',
            consumerDisplayName: 'role7758',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7433',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7433',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8180',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6125',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6726',
            consumerDisplayName: 'role6726',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4049',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4049',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1401',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5305',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2674',
            consumerDisplayName: 'role2674',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5768',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5768',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2761',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8604',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6429',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8276',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5573',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2282',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4239',
            consumerDisplayName: 'role4239',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8313',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8313',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6857',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5269',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3073',
            consumerDisplayName: 'role3073',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7891',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7891',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9250',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1509',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2780',
            consumerDisplayName: 'role2780',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8167',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8167',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3307',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2758',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8753',
            consumerDisplayName: 'role8753',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8138',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8138',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8593',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4772',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3233',
            consumerDisplayName: 'role3233',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6350',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6350',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9008',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5390',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6109',
            consumerDisplayName: 'role6109',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9506',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9506',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4879',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9129',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7712',
            consumerDisplayName: 'role7712',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7649',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7649',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6417',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service2715',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service2715',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3044',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4295',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7952',
            consumerDisplayName: 'role7952',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8376',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8376',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2959',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5540',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5829',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2329',
            consumerDisplayName: 'role2329',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7079',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7079',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8621',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3208',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3753',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1495',
            consumerDisplayName: 'role1495',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4395',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4395',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4873',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3627',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6730',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8933',
            consumerDisplayName: 'role8933',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5822',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5822',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9058',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2507',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3778',
            consumerDisplayName: 'role3778',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6080',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6080',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1290',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6612',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3894',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5798',
            consumerDisplayName: 'role5798',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8842',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3295',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2039',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1105',
            consumerDisplayName: 'role1105',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1009',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1009',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8629',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4302',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3049',
            consumerDisplayName: 'role3049',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1116',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1116',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3624',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1135',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8554',
            consumerDisplayName: 'role8554',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1340',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1340',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2635',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2398',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8357',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5043',
            consumerDisplayName: 'role5043',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4961',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4961',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '9b41f71a-999c-4a0c-81c9-126a7333079b',
    stableId: '8b6eae7c-b17e-5e4c-9fe6-00ac58961191',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:facebook-secret-5371',
    apiProviderSlug: 'facebook',
    violations: [],
    identifier: 'facebook-secret-5371',
    environment: 2,
    applicationName: 'Facebook',
    applicationDomain: 'https://www.facebook.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-12-05T14:17:48.489Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-06T19:11:04.623Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-05-02T00:00:00Z')),
    owner: 'Susan Wilson',
    roleConsumers: [
      {
        role: 'role4340',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2680',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6584',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5750',
            consumerDisplayName: 'role5750',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9757',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9757',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8720',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9309',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2306',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9868',
            consumerDisplayName: 'role9868',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8048',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8048',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7763',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1121',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4516',
            consumerDisplayName: 'role4516',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2138',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2138',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1832',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1970',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7644',
            consumerDisplayName: 'role7644',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9500',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9500',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4703',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2996',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2381',
            consumerDisplayName: 'role2381',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3522',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3522',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8879',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7019',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3689',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8748',
            consumerDisplayName: 'role8748',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7688',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7688',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7924',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5229',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4241',
            consumerDisplayName: 'role4241',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1438',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1438',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3258',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6323',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6716',
            consumerDisplayName: 'role6716',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6996',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2205',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2371',
            consumerDisplayName: 'role2371',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9945',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9945',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7609',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1737',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4988',
            consumerDisplayName: 'role4988',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1434',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1434',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2756',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2872',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4361',
            consumerDisplayName: 'role4361',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6746',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6746',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7478',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3679',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7125',
            consumerDisplayName: 'role7125',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1019',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1019',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1694',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4325',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8106',
            consumerDisplayName: 'role8106',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7426',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7426',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8910',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4971',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1186',
            consumerDisplayName: 'role1186',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8978',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8978',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7155',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6373',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4139',
            consumerDisplayName: 'role4139',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7063',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7063',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2216',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app9912',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app9912',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4973',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2348',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7271',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7966',
            consumerDisplayName: 'role7966',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1176',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1176',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8897',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4810',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4527',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6597',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6082',
            consumerDisplayName: 'role6082',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5697',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5697',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2129',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2303',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2822',
            consumerDisplayName: 'role2822',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3208',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3208',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9524',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8355',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3915',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9157',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5154',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5709',
            consumerDisplayName: 'role5709',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1342',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1342',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6541',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9105',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9551',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9010',
            consumerDisplayName: 'role9010',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3746',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3746',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '7b152eb4-fb95-4e8b-a442-7899d3366946',
    stableId: 'b89a304f-d677-5b57-9d2e-387afe7ff391',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:slack-secret-2688',
    apiProviderSlug: 'slack',
    violations: [],
    identifier: 'slack-secret-2688',
    environment: 2,
    applicationName: 'Slack',
    applicationDomain: 'https://slack.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-08T12:32:08.482Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-02-06T12:49:26.891Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-04T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role1795',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8047',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3439',
            consumerDisplayName: 'role3439',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6314',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6314',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3029',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9260',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8356',
            consumerDisplayName: 'role8356',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8646',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8646',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3299',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3255',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5273',
            consumerDisplayName: 'role5273',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6071',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6071',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5507',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7695',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7838',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6186',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1234',
            consumerDisplayName: 'role1234',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8071',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8071',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7016',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9958',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9302',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1875',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8915',
            consumerDisplayName: 'role8915',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7628',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7628',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5695',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7253',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6562',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4095',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9018',
            consumerDisplayName: 'role9018',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4380',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4380',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4373',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7343',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2070',
            consumerDisplayName: 'role2070',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6846',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6846',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9000',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7173',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2445',
            consumerDisplayName: 'role2445',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6473',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6473',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7291',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1592',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8286',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2129',
            consumerDisplayName: 'role2129',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8625',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8625',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4954',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6412',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8668',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5604',
            consumerDisplayName: 'role5604',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9408',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9408',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9108',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service8840',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service8840',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6739',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8886',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8054',
            consumerDisplayName: 'role8054',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2336',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2336',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4236',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2408',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4990',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2065',
            consumerDisplayName: 'role2065',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5877',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5877',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6391',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9405',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9143',
            consumerDisplayName: 'role9143',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1095',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1095',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3197',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7705',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3038',
            consumerDisplayName: 'role3038',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8820',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8820',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3922',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7143',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6540',
            consumerDisplayName: 'role6540',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7569',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7569',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8040',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8865',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9092',
            consumerDisplayName: 'role9092',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5673',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5673',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1749',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9945',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7749',
            consumerDisplayName: 'role7749',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6819',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3371',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4041',
            consumerDisplayName: 'role4041',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8723',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8723',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4713',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4953',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8681',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2467',
            consumerDisplayName: 'role2467',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2036',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2036',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9579',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8053',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9775',
            consumerDisplayName: 'role9775',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3835',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3835',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '80e611aa-3951-4153-8ea6-ccbd71ded77d',
    stableId: 'fa92d4a1-5e54-5298-8284-69f815f8e87d',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:slack-secret-5241',
    apiProviderSlug: 'slack',
    violations: [],
    identifier: 'slack-secret-5241',
    environment: 2,
    applicationName: 'Slack',
    applicationDomain: 'https://slack.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-12-05T14:17:54.603Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-06T19:11:27.240Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-05-02T00:00:00Z')),
    owner: 'David Jones',
    roleConsumers: [
      {
        role: 'role9410',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3826',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4303',
            consumerDisplayName: 'role4303',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4209',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4209',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5483',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5098',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5797',
            consumerDisplayName: 'role5797',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4586',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4586',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3162',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6937',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7335',
            consumerDisplayName: 'role7335',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2699',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2699',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6924',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1875',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2556',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6181',
            consumerDisplayName: 'role6181',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6667',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6667',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3296',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2701',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8353',
            consumerDisplayName: 'role8353',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6487',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6487',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8870',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1821',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7603',
            consumerDisplayName: 'role7603',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8254',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8254',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7356',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4678',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8386',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7514',
            consumerDisplayName: 'role7514',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5594',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5594',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1160',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7952',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7324',
            consumerDisplayName: 'role7324',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5148',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5148',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2618',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3190',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1500',
            consumerDisplayName: 'role1500',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9813',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9813',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6008',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5878',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1536',
            consumerDisplayName: 'role1536',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9185',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9185',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8276',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1153',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5450',
            consumerDisplayName: 'role5450',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3938',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3938',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7109',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6565',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1762',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5871',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2487',
            consumerDisplayName: 'role2487',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3440',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3440',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7246',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7107',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1148',
            consumerDisplayName: 'role1148',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9101',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6564',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1338',
            consumerDisplayName: 'role1338',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1607',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1607',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2189',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6392',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8214',
            consumerDisplayName: 'role8214',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4311',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4311',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3887',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5092',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service7402',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service7402',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1254',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2390',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5242',
            consumerDisplayName: 'role5242',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1450',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1450',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7388',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4776',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8868',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1360',
            consumerDisplayName: 'role1360',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8887',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8887',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2611',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2142',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4167',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9709',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7325',
            consumerDisplayName: 'role7325',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4972',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4972',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5001',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7327',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1213',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2918',
            consumerDisplayName: 'role2918',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9189',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9189',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5231',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2520',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8684',
            consumerDisplayName: 'role8684',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4436',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4436',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4998',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '3bad6e7c-7cab-434f-8d84-2e56d87437b3',
    stableId: 'b1ba65d8-5569-59e2-81c5-70cf0e86fd00',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:intercom-secret-6432',
    apiProviderSlug: 'intercom',
    violations: [],
    identifier: 'intercom-secret-6432',
    environment: 2,
    applicationName: 'Intercom',
    applicationDomain: 'https://www.intercom.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-05T11:44:44.736Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-18T10:15:18.224Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-07-25T00:00:00Z')),
    owner: 'Emily Davis',
    roleConsumers: [
      {
        role: 'role7214',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1975',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3235',
            consumerDisplayName: 'role3235',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5565',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5565',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7736',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7975',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5822',
            consumerDisplayName: 'role5822',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5978',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5978',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7346',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service7096',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service7096',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3605',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1525',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9098',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3938',
            consumerDisplayName: 'role3938',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8734',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8734',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8176',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2957',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4477',
            consumerDisplayName: 'role4477',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1491',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1491',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9597',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7197',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8236',
            consumerDisplayName: 'role8236',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7779',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7779',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9792',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8824',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3558',
            consumerDisplayName: 'role3558',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4311',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4311',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2886',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8881',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1428',
            consumerDisplayName: 'role1428',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9750',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9750',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2967',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6617',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2949',
            consumerDisplayName: 'role2949',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7388',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7388',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1764',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2817',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9140',
            consumerDisplayName: 'role9140',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7103',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7103',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2483',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8018',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4063',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1051',
            consumerDisplayName: 'role1051',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1290',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1290',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2261',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7508',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8380',
            consumerDisplayName: 'role8380',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7941',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7941',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1594',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2388',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9672',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2761',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3325',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2025',
            consumerDisplayName: 'role2025',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4104',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4104',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7970',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3916',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5332',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2863',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2148',
            consumerDisplayName: 'role2148',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2772',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2772',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3266',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6908',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9898',
            consumerDisplayName: 'role9898',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2995',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9161',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1364',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3159',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7028',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3187',
            consumerDisplayName: 'role3187',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4343',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4343',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6403',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8975',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7745',
            consumerDisplayName: 'role7745',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6927',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6927',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7125',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9754',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5637',
            consumerDisplayName: 'role5637',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4109',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4109',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8587',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7983',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9303',
            consumerDisplayName: 'role9303',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4638',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4638',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2197',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4454',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4890',
            consumerDisplayName: 'role4890',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5196',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5196',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2637',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7951',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7699',
            consumerDisplayName: 'role7699',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1688',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1688',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '14251920-eeea-42f1-889d-0d11d6d33249',
    stableId: 'ac1a73fb-93bd-5e31-8e5a-75a769442095',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:intercom-secret-2262',
    apiProviderSlug: 'intercom',
    violations: [],
    identifier: 'intercom-secret-2262',
    environment: 2,
    applicationName: 'Intercom',
    applicationDomain: 'https://www.intercom.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-07T20:17:27.822Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-14T10:29:02.283Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-07-25T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role3309',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8003',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5889',
            consumerDisplayName: 'role5889',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9184',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9184',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9925',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service4200',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service4200',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6206',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7630',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4855',
            consumerDisplayName: 'role4855',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1368',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1368',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8310',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5885',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8197',
            consumerDisplayName: 'role8197',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7147',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7147',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4588',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4379',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8328',
            consumerDisplayName: 'role8328',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2305',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2305',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3053',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3846',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2569',
            consumerDisplayName: 'role2569',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6829',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6829',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6545',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4976',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1873',
            consumerDisplayName: 'role1873',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6382',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6382',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9913',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6693',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4529',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4561',
            consumerDisplayName: 'role4561',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5374',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5374',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6036',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1963',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8325',
            consumerDisplayName: 'role8325',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5141',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5141',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7708',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2365',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3191',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9912',
            consumerDisplayName: 'role9912',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1461',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1461',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6989',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4322',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5036',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5899',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1070',
            consumerDisplayName: 'role1070',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9041',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9041',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8785',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6510',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4185',
            consumerDisplayName: 'role4185',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8628',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8628',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9763',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7781',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8524',
            consumerDisplayName: 'role8524',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2111',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2111',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9413',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9711',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7112',
            consumerDisplayName: 'role7112',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3158',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3158',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9586',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7370',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9000',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3179',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3989',
            consumerDisplayName: 'role3989',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2751',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2751',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1023',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3478',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7644',
            consumerDisplayName: 'role7644',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8566',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6587',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2617',
            consumerDisplayName: 'role2617',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1009',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1009',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7473',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3855',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1135',
            consumerDisplayName: 'role1135',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7818',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7818',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4756',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1475',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1592',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1420',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1937',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9302',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5410',
            consumerDisplayName: 'role5410',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7899',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7899',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3465',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3623',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5306',
            consumerDisplayName: 'role5306',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7595',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7595',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6165',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1433',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7305',
            consumerDisplayName: 'role7305',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4388',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4388',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '4ca2c6ba-e337-4ee1-8f34-3d7a91a368d0',
    stableId: '61c2ae8d-996e-5c66-acb8-60a2a1cdb245',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:stripe-secret-5689',
    apiProviderSlug: 'stripe',
    violations: [],
    identifier: 'stripe-secret-5689',
    environment: 2,
    applicationName: 'Stripe',
    applicationDomain: 'https://stripe.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-07T20:32:29.399Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-18T11:04:46.996Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-08T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role5622',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7087',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2238',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7908',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5435',
            consumerDisplayName: 'role5435',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3054',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3054',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3824',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3452',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2917',
            consumerDisplayName: 'role2917',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7185',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7185',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7827',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3512',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9013',
            consumerDisplayName: 'role9013',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2518',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2518',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2486',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5512',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5073',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6476',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1918',
            consumerDisplayName: 'role1918',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1502',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1502',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2285',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8681',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6978',
            consumerDisplayName: 'role6978',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8733',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8733',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7789',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1044',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9828',
            consumerDisplayName: 'role9828',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5251',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5251',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4236',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2108',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4323',
            consumerDisplayName: 'role4323',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9738',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9738',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9863',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3448',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9370',
            consumerDisplayName: 'role9370',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2972',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2972',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8701',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6359',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9274',
            consumerDisplayName: 'role9274',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5894',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5894',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4884',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8019',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5625',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1505',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5809',
            consumerDisplayName: 'role5809',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3751',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3751',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8352',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5450',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6447',
            consumerDisplayName: 'role6447',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9512',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9512',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4383',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1479',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7803',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5048',
            consumerDisplayName: 'role5048',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7654',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7654',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9090',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7718',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5026',
            consumerDisplayName: 'role5026',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2139',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2139',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9167',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9618',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5662',
            consumerDisplayName: 'role5662',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3898',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3898',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5824',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1212',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2074',
            consumerDisplayName: 'role2074',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8267',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2702',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8693',
            consumerDisplayName: 'role8693',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5536',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5536',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4882',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5062',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3956',
            consumerDisplayName: 'role3956',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7190',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7190',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8040',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7963',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9202',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3736',
            consumerDisplayName: 'role3736',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7558',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7558',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5272',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service9464',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service9464',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3366',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4123',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1653',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4528',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9095',
            consumerDisplayName: 'role9095',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5657',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5657',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5115',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1025',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7808',
            consumerDisplayName: 'role7808',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2279',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2279',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '413850e5-ba83-4c62-b291-e0daa481ac29',
    stableId: '1d4bbe85-f0eb-5246-8db7-e12522ae6b55',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:stripe-secret-7527',
    apiProviderSlug: 'stripe',
    violations: [],
    identifier: 'stripe-secret-7527',
    environment: 2,
    applicationName: 'Stripe',
    applicationDomain: 'https://stripe.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-04T18:55:54.404Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-11-22T20:08:59.247Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-07-28T00:00:00Z')),
    owner: 'Jane Smith',
    roleConsumers: [
      {
        role: 'role7190',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6760',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7557',
            consumerDisplayName: 'role7557',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1655',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1655',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5573',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7293',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7916',
            consumerDisplayName: 'role7916',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5634',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5634',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7040',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5817',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7364',
            consumerDisplayName: 'role7364',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1030',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1030',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3181',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9012',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8644',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2962',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9230',
            consumerDisplayName: 'role9230',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6257',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6257',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2000',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7687',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5814',
            consumerDisplayName: 'role5814',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1517',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1517',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9980',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3170',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7700',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4547',
            consumerDisplayName: 'role4547',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2811',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2811',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6067',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5959',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3255',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4303',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2356',
            consumerDisplayName: 'role2356',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9358',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9358',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4105',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app8126',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app8126',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7897',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1969',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7919',
            consumerDisplayName: 'role7919',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9778',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9778',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3456',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7410',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4956',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1393',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3072',
            consumerDisplayName: 'role3072',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7644',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7644',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7914',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1308',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3745',
            consumerDisplayName: 'role3745',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3264',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3264',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4330',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6729',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1232',
            consumerDisplayName: 'role1232',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9325',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9325',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1968',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7218',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8513',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4990',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3166',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4170',
            consumerDisplayName: 'role4170',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5121',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5121',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1643',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7292',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8507',
            consumerDisplayName: 'role8507',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5296',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5296',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9671',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6138',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5976',
            consumerDisplayName: 'role5976',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8542',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4305',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3939',
            consumerDisplayName: 'role3939',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4283',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4283',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4164',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3900',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9821',
            consumerDisplayName: 'role9821',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3126',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3126',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1120',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8245',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7491',
            consumerDisplayName: 'role7491',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8725',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8725',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7181',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1287',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8230',
            consumerDisplayName: 'role8230',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7496',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7496',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8329',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8604',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7264',
            consumerDisplayName: 'role7264',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3369',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3369',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8269',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6682',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3613',
            consumerDisplayName: 'role3613',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8073',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8073',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '14b2234f-3753-45c6-96bf-fcd117520725',
    stableId: 'be19c378-b224-5fc2-9c6f-c7265e894d74',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:openai-secret-8227',
    apiProviderSlug: 'openai',
    violations: [],
    identifier: 'openai-secret-8227',
    environment: 2,
    applicationName: 'OpenAI',
    applicationDomain: 'https://www.openai.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2024-05-20T19:54:39.784Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-05-20T19:54:44.797Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-05-30T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role4475',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8703',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9087',
            consumerDisplayName: 'role9087',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4572',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4572',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9765',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7985',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8539',
            consumerDisplayName: 'role8539',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3601',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3601',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4559',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7422',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9492',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2969',
            consumerDisplayName: 'role2969',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6223',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6223',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1315',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6450',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5786',
            consumerDisplayName: 'role5786',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1849',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1849',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1936',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5448',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2921',
            consumerDisplayName: 'role2921',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9753',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9753',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3621',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1894',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9538',
            consumerDisplayName: 'role9538',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5540',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5540',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9113',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4078',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9445',
            consumerDisplayName: 'role9445',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6327',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6327',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3269',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2367',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3459',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1444',
            consumerDisplayName: 'role1444',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3740',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8212',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5806',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5066',
            consumerDisplayName: 'role5066',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6156',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6156',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8091',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7412',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8992',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5765',
            consumerDisplayName: 'role5765',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8975',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8975',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5217',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3515',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1727',
            consumerDisplayName: 'role1727',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4184',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4184',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4065',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3968',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3860',
            consumerDisplayName: 'role3860',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4511',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4511',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3630',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1575',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app7963',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app7963',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6159',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6407',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6615',
            consumerDisplayName: 'role6615',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1002',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1002',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5722',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6887',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8845',
            consumerDisplayName: 'role8845',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7746',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7746',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6821',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2425',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8573',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4032',
            consumerDisplayName: 'role4032',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9080',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9080',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3665',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5086',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6318',
            consumerDisplayName: 'role6318',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1970',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1970',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9483',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4245',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5248',
            consumerDisplayName: 'role5248',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4265',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4265',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2412',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5544',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3999',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5161',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5036',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7597',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2509',
            consumerDisplayName: 'role2509',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5294',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5294',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3625',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1530',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7184',
            consumerDisplayName: 'role7184',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9447',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9447',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6305',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7541',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7330',
            consumerDisplayName: 'role7330',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5302',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5302',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '2da97670-d301-4e37-9b6a-1e4a8f6816fd',
    stableId: 'b01fb3fb-ecb8-55ce-a81d-6d6d3347ad36',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:openai-secret-3421',
    apiProviderSlug: 'openai',
    violations: [],
    identifier: 'openai-secret-3421',
    environment: 2,
    applicationName: 'OpenAI',
    applicationDomain: 'https://www.openai.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-12-28T09:17:58.003Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-28T09:24:01.934Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-02-15T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role1517',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3670',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9107',
            consumerDisplayName: 'role9107',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2386',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2386',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2410',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3091',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9988',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2215',
            consumerDisplayName: 'role2215',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9655',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9655',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3008',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5498',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3698',
            consumerDisplayName: 'role3698',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8394',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2346',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9849',
            consumerDisplayName: 'role9849',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5239',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5239',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6864',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6031',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6603',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8479',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2256',
            consumerDisplayName: 'role2256',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7542',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7542',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3482',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6829',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1002',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1760',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6061',
            consumerDisplayName: 'role6061',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3331',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3331',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6216',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9981',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2143',
            consumerDisplayName: 'role2143',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5304',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5304',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4969',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2749',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5103',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3731',
            consumerDisplayName: 'role3731',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9856',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9856',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7724',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5095',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9820',
            consumerDisplayName: 'role9820',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6989',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6989',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9806',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5375',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6937',
            consumerDisplayName: 'role6937',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7128',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7128',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7218',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3594',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1777',
            consumerDisplayName: 'role1777',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2839',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2839',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8041',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7181',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9940',
            consumerDisplayName: 'role9940',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9107',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9107',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2652',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5140',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1563',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5559',
            consumerDisplayName: 'role5559',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4653',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4653',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2438',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7547',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9828',
            consumerDisplayName: 'role9828',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6974',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6974',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2340',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5676',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4542',
            consumerDisplayName: 'role4542',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6746',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6746',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4951',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5967',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7401',
            consumerDisplayName: 'role7401',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1182',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1182',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1315',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5040',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2787',
            consumerDisplayName: 'role2787',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1604',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1604',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5637',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6836',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8377',
            consumerDisplayName: 'role8377',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5843',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5843',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4788',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7439',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7582',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service8367',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service8367',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6925',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6471',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3434',
            consumerDisplayName: 'role3434',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2391',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2391',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4901',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2350',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3358',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7226',
            consumerDisplayName: 'role7226',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4042',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4042',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '4446496e-6ef3-45b7-9c34-a22f220653c2',
    stableId: 'ad2f3294-d136-5bd2-9f28-f567242e0112',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:datadog-secret-6502',
    apiProviderSlug: 'datadog',
    violations: [],
    identifier: 'datadog-secret-6502',
    environment: 2,
    applicationName: 'Datadog',
    applicationDomain: 'https://www.datadoghq.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-05-15T11:43:55.953Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-01-24T10:22:36.663Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-02-15T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role7114',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service1829',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service1829',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8257',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4669',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5742',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8047',
            consumerDisplayName: 'role8047',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6636',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6636',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9766',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4266',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7210',
            consumerDisplayName: 'role7210',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2398',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2398',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2478',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5926',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6214',
            consumerDisplayName: 'role6214',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1084',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1084',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3159',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9644',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8674',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8782',
            consumerDisplayName: 'role8782',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2041',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2041',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7872',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2062',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3902',
            consumerDisplayName: 'role3902',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2159',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2159',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1062',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4764',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9123',
            consumerDisplayName: 'role9123',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1002',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1002',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8499',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4154',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4885',
            consumerDisplayName: 'role4885',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3438',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3438',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8410',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6016',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3942',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7261',
            consumerDisplayName: 'role7261',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4970',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4970',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1128',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2187',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9238',
            consumerDisplayName: 'role9238',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8387',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8387',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4142',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7990',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8541',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7209',
            consumerDisplayName: 'role7209',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7127',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7127',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6284',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7336',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3031',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8462',
            consumerDisplayName: 'role8462',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9642',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9642',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7673',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4994',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1798',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9739',
            consumerDisplayName: 'role9739',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9414',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9414',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4405',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2437',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6326',
            consumerDisplayName: 'role6326',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9554',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9554',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5347',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8760',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7866',
            consumerDisplayName: 'role7866',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6802',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6802',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2111',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7566',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6080',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1508',
            consumerDisplayName: 'role1508',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9547',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9547',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6132',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4781',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3156',
            consumerDisplayName: 'role3156',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2632',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2955',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3460',
            consumerDisplayName: 'role3460',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2246',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2246',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4216',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1883',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8639',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6635',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7342',
            consumerDisplayName: 'role7342',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3059',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3059',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6610',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5524',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5189',
            consumerDisplayName: 'role5189',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8090',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8090',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9637',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7273',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3265',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4486',
            consumerDisplayName: 'role4486',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5114',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5114',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: 'd83cbdca-6046-4f20-bc4f-ad3fc85757cc',
    stableId: '298e6088-553c-5df0-ad8e-a98c1d297343',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:datadog-secret-6016',
    apiProviderSlug: 'datadog',
    violations: [],
    identifier: 'datadog-secret-6016',
    environment: 2,
    applicationName: 'Datadog',
    applicationDomain: 'https://www.datadoghq.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-02-21T16:07:34.255Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-11-28T13:50:38.107Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-01-30T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role8670',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9729',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4535',
            consumerDisplayName: 'role4535',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9810',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9810',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5469',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6368',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9673',
            consumerDisplayName: 'role9673',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7117',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7117',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3563',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9852',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8581',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2746',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4959',
            consumerDisplayName: 'role4959',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4715',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4715',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8653',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2199',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7759',
            consumerDisplayName: 'role7759',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5410',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5410',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7547',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3250',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2585',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9017',
            consumerDisplayName: 'role9017',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6952',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6952',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4117',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9514',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8369',
            consumerDisplayName: 'role8369',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6691',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6691',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2291',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8928',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8561',
            consumerDisplayName: 'role8561',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5711',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5711',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5835',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1693',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9618',
            consumerDisplayName: 'role9618',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1206',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1206',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2757',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3082',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5182',
            consumerDisplayName: 'role5182',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3928',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3928',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9130',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3168',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9210',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1536',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3327',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7568',
            consumerDisplayName: 'role7568',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7421',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7421',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7677',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9089',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3398',
            consumerDisplayName: 'role3398',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7469',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7469',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4554',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5757',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3651',
            consumerDisplayName: 'role3651',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7801',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service2611',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service2611',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5251',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8333',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8101',
            consumerDisplayName: 'role8101',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7901',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7901',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7904',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9494',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3125',
            consumerDisplayName: 'role3125',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5450',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5450',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1168',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2479',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8957',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3605',
            consumerDisplayName: 'role3605',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7544',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7544',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9610',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5790',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9556',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4658',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3416',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7919',
            consumerDisplayName: 'role7919',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8161',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8161',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4713',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5498',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5974',
            consumerDisplayName: 'role5974',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8460',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8460',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5364',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6638',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4636',
            consumerDisplayName: 'role4636',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6984',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6984',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2289',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2985',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3321',
            consumerDisplayName: 'role3321',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3227',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3227',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9266',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3432',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8467',
            consumerDisplayName: 'role8467',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9868',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9868',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '3034ef35-4d2c-4aa5-949c-fcc4057f0f60',
    stableId: '626b529c-3ee3-5460-a9dd-f32a7dccbfb2',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:okta-secret-6168',
    apiProviderSlug: 'okta',
    violations: [],
    identifier: 'okta-secret-6168',
    environment: 2,
    applicationName: 'Okta',
    applicationDomain: 'https://www.okta.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-08T12:29:39.562Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-15T07:40:12.805Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-04-25T00:00:00Z')),
    owner: 'Jane Smith',
    roleConsumers: [
      {
        role: 'role4917',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6780',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8028',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5835',
            consumerDisplayName: 'role5835',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1379',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5705',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7286',
            consumerDisplayName: 'role7286',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5962',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5962',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4037',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3234',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9044',
            consumerDisplayName: 'role9044',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1234',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1234',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3795',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9401',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3971',
            consumerDisplayName: 'role3971',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2910',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2910',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5952',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1164',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3363',
            consumerDisplayName: 'role3363',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4305',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4305',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5605',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2681',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1914',
            consumerDisplayName: 'role1914',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2259',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2259',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3877',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2341',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8676',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6742',
            consumerDisplayName: 'role6742',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3088',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3088',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7701',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6340',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8321',
            consumerDisplayName: 'role8321',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6706',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6706',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3499',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9569',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9283',
            consumerDisplayName: 'role9283',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7916',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7916',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2323',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9939',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9591',
            consumerDisplayName: 'role9591',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5566',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5566',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8713',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3240',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2215',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8945',
            consumerDisplayName: 'role8945',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7795',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7795',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3579',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6474',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2130',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9834',
            consumerDisplayName: 'role9834',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6847',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6847',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1966',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9007',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2209',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9131',
            consumerDisplayName: 'role9131',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4378',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4378',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5766',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6359',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4464',
            consumerDisplayName: 'role4464',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1943',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1943',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8127',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5017',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2812',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7041',
            consumerDisplayName: 'role7041',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4024',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4024',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6936',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2131',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'service7409',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'service7409',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4738',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7313',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6832',
            consumerDisplayName: 'role6832',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8095',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8095',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6960',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2573',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2122',
            consumerDisplayName: 'role2122',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5097',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5097',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9929',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7011',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7608',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8222',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3655',
            consumerDisplayName: 'role3655',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4265',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4265',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2191',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8092',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3087',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9483',
            consumerDisplayName: 'role9483',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service4327',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service4327',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6885',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9921',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4741',
            consumerDisplayName: 'role4741',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9516',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9516',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '102e87d0-41ac-4432-a682-54159681024c',
    stableId: '5283cb83-2b19-5a01-9f16-c15c08c56eb6',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:okta-secret-8909',
    apiProviderSlug: 'okta',
    violations: [],
    identifier: 'okta-secret-8909',
    environment: 2,
    applicationName: 'Okta',
    applicationDomain: 'https://www.okta.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2023-02-13T16:24:14.537Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2023-12-18T11:39:46.451Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-13T00:00:00Z')),
    owner: 'Emily Davis',
    roleConsumers: [
      {
        role: 'role2020',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2848',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3824',
            consumerDisplayName: 'role3824',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5328',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5328',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3685',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7769',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6321',
            consumerDisplayName: 'role6321',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9957',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9957',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7939',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8167',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8194',
            consumerDisplayName: 'role8194',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3442',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3442',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6339',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4719',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7220',
            consumerDisplayName: 'role7220',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8150',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8150',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5761',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6485',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2615',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1722',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5806',
            consumerDisplayName: 'role5806',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1728',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1728',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1147',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3034',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1293',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7360',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9694',
            consumerDisplayName: 'role9694',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7881',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7881',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6542',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app3525',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app3525',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8916',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5031',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8254',
            consumerDisplayName: 'role8254',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5070',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5070',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2261',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5529',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9432',
            consumerDisplayName: 'role9432',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6602',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6602',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8961',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2724',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6241',
            consumerDisplayName: 'role6241',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2427',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2427',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8707',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6184',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7010',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6022',
            consumerDisplayName: 'role6022',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7812',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7812',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3925',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6937',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7398',
            consumerDisplayName: 'role7398',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5047',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3828',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role9073',
            consumerDisplayName: 'role9073',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1500',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1500',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3093',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8676',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2204',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4962',
            consumerDisplayName: 'role4962',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7139',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7139',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3801',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6877',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6731',
            consumerDisplayName: 'role6731',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3939',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3939',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4196',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2564',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2228',
            consumerDisplayName: 'role2228',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4964',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4964',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8489',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2112',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6136',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4182',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7894',
            consumerDisplayName: 'role7894',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1888',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1888',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4937',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6090',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8239',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8868',
            consumerDisplayName: 'role8868',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8822',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8822',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7077',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1880',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6767',
            consumerDisplayName: 'role6767',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7077',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7077',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9008',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7387',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8271',
            consumerDisplayName: 'role8271',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1726',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1726',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2046',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role5515',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9447',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6150',
            consumerDisplayName: 'role6150',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2563',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2563',
                consumerConsumers: []
              }
            ]
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '3e493bc5-812d-4633-a022-c0f501f8d904',
    stableId: 'b2e4e53a-f8fc-5829-8d87-42d6c194d92c',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:google-secret-9182',
    apiProviderSlug: 'google',
    violations: [],
    identifier: 'google-secret-9182',
    environment: 2,
    applicationName: 'Google',
    applicationDomain: 'https://www.google.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-29T14:48:02.092Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-04-14T10:23:45.108Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-05-07T00:00:00Z')),
    owner: '',
    roleConsumers: [
      {
        role: 'role2453',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6193',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4830',
            consumerDisplayName: 'role4830',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app9194',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app9194',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3301',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app2595',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app2595',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1332',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8396',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6892',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2697',
            consumerDisplayName: 'role2697',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5491',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5491',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9075',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4804',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6404',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3596',
            consumerDisplayName: 'role3596',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7076',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7076',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9213',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2680',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1393',
            consumerDisplayName: 'role1393',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3841',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3841',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3151',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role6421',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6737',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1854',
            consumerDisplayName: 'role1854',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6706',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6706',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2004',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1185',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1614',
            consumerDisplayName: 'role1614',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2757',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2757',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8487',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4526',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9272',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3809',
            consumerDisplayName: 'role3809',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1509',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1509',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5662',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9916',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7505',
            consumerDisplayName: 'role7505',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6368',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6368',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9033',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7233',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7610',
            consumerDisplayName: 'role7610',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6833',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6833',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6163',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5688',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8821',
            consumerDisplayName: 'role8821',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9606',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9606',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5134',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7532',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6577',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5980',
            consumerDisplayName: 'role5980',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app7253',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app7253',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3938',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9337',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6673',
            consumerDisplayName: 'role6673',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service8933',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service8933',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3168',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6316',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3995',
            consumerDisplayName: 'role3995',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8543',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8543',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9322',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4017',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1165',
            consumerDisplayName: 'role1165',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service7172',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service7172',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5683',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role3497',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8719',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4730',
            consumerDisplayName: 'role4730',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3292',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3292',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5723',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6668',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4085',
            consumerDisplayName: 'role4085',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3418',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3418',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6098',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9465',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8908',
            consumerDisplayName: 'role8908',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6568',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6568',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4465',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1864',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1401',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8511',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4359',
            consumerDisplayName: 'role4359',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6359',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6359',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1806',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1827',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6793',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8053',
            consumerDisplayName: 'role8053',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app2360',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app2360',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4345',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3728',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2576',
            consumerDisplayName: 'role2576',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9703',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  },
  {
    id: '7b4e1de6-2f8d-4ce1-a20f-83ac16eadff3',
    stableId: 'f3ece1af-e981-5e19-9b75-4199ad7556d9',
    tenantId: 'fb489f41-77f1-4877-997f-9346341c41d4',
    platformInstanceId: '60458bee-487d-4678-937f-09af35beeb5e',
    externalResourceName:
      'arn:aws:secretsmanager:us-west-2:123456789012:secret:google-secret-5205',
    apiProviderSlug: 'google',
    violations: [],
    identifier: 'google-secret-5205',
    environment: 2,
    applicationName: 'Google',
    applicationDomain: 'https://www.google.com',
    vault: 'AWS_SECRETS_MANAGER',
    createdAt: Timestamp.fromDate(new Date('2022-11-05T09:11:41.409Z')),
    lastRotatedAt: Timestamp.fromDate(new Date('2024-06-16T17:23:14.680Z')),
    lastUsed: Timestamp.fromDate(new Date('2024-08-07T00:00:00Z')),
    owner: 'Michael Brown',
    roleConsumers: [
      {
        role: 'role4778',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5844',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8124',
            consumerDisplayName: 'role8124',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service9195',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service9195',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9254',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3587',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5195',
            consumerDisplayName: 'role5195',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app6336',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app6336',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role9746',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2930',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3092',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6351',
            consumerDisplayName: 'role6351',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service2209',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service2209',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7009',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...9447',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5166',
            consumerDisplayName: 'role5166',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3781',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3781',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4448',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7898',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8601',
            consumerDisplayName: 'role8601',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6499',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6499',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7676',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role9793',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6510',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1052',
            consumerDisplayName: 'role1052',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5948',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5948',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role1380',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7525',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4848',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4020',
            consumerDisplayName: 'role4020',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app3744',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app3744',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4862',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7290',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role6167',
            consumerDisplayName: 'role6167',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role1427',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2528',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4986',
            consumerDisplayName: 'role4986',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service5164',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service5164',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8697',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role7717',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'app4375',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'app4375',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8559',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6074',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2256',
            consumerDisplayName: 'role2256',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1082',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1082',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2931',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8538',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2551',
            consumerDisplayName: 'role2551',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8839',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8839',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role8355',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4398',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8270',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5690',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2239',
            consumerDisplayName: 'role2239',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app8708',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app8708',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role2959',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...4116',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role2116',
            consumerDisplayName: 'role2116',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4956',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4956',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5489',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role8580',
        consumers: [
          {
            consumerType: 'AWS_SSO',
            consumerExternalId: 'sso',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:saml-provider/AWSSSO_deadbeefdeadbeef_DEMO_USERS/sso',
            consumerDisplayName: 'sso',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role2978',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...3916',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role3130',
            consumerDisplayName: 'role3130',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service3883',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service3883',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6843',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...7212',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7535',
            consumerDisplayName: 'role7535',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app5222',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app5222',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5193',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...5807',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role5304',
            consumerDisplayName: 'role5304',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4859',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4859',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role3524',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...6010',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role1349',
            consumerDisplayName: 'role1349',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app4895',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app4895',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role4966',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'kubernetes-external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'kubernetes-external-secrets',
            consumerConsumers: []
          }
        ]
      },
      {
        role: 'role4698',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...1905',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role4494',
            consumerDisplayName: 'role4494',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'app1862',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'app1862',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role5590',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...8354',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role7539',
            consumerDisplayName: 'role7539',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service1087',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service1087',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role7865',
        consumers: [
          {
            consumerType: 'AWS_IAM_ROLE',
            consumerExternalId: 'AR0A...2731',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:role/role8305',
            consumerDisplayName: 'role8305',
            consumerConsumers: [
              {
                consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
                consumerExternalId: 'service6266',
                consumerExternalResourceName:
                  'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
                consumerDisplayName: 'service6266',
                consumerConsumers: []
              }
            ]
          }
        ]
      },
      {
        role: 'role6765',
        consumers: [
          {
            consumerType: 'AWS_EKS_SERVICE_ACCOUNT',
            consumerExternalId: 'external-secrets',
            consumerExternalResourceName:
              'arn:aws:iam::123456789012:oidc-provider/oidc.eks.us-west-2.amazonaws.com/id/DEADBEEFDEADBEEF',
            consumerDisplayName: 'external-secrets',
            consumerConsumers: []
          }
        ]
      }
    ],
    note: '',
    environmentName: 'AWS Dev'
  }
];

export default API_KEYS_MOCK;
