/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { token } from '@atlaskit/tokens';
import { Handle, type NodeProps, Position } from '@xyflow/react';
import { memo, type ReactElement } from 'react';
import NodeCell from './NodeCell';
import { Box } from '@atlaskit/primitives';

const RoleNode = memo(
  ({ data }: NodeProps & { data: { label: string } }): ReactElement => {
    return (
      <Box
        style={{
          height: '40px'
        }}
      >
        <NodeCell content={data.label} tooltip={data.label} />
        <Handle
          type="target"
          isConnectable={false}
          position={Position.Left}
          style={{
            left: token('space.negative.050')
          }}
        />
        <Handle
          type="source"
          isConnectable={false}
          position={Position.Right}
          style={{
            right: token('space.negative.050')
          }}
        />
      </Box>
    );
  }
);

RoleNode.displayName = 'RoleNode';

export default RoleNode;
