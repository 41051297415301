import { Box } from '@atlaskit/primitives';
import 'react';
import { type IconWidthProps } from '../IconWidthProps';

const AWSSecretsManagerIcon = ({
  width = '21',
  height = '21'
}: IconWidthProps): JSX.Element => (
  <Box
    style={{
      width: `${width}px`,
      height: `${height}px`,
      display: 'flex'
    }}
  >
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58407 14.1321H12.7401V11.2894H8.58407V14.1321ZM9.35205 9.95601H11.9814C11.9014 8.544 11.5227 8.00001 10.6854 8.00001C9.82272 8.00001 9.43605 8.544 9.35205 9.95601ZM13.4068 9.95601C13.7748 9.95601 14.0734 10.2533 14.0734 10.6227V14.7987C14.0734 15.1667 13.7748 15.4653 13.4068 15.4653H7.9161C7.5481 15.4653 7.24943 15.1667 7.24943 14.7987V10.6227C7.24943 10.2533 7.5481 9.95601 7.9161 9.95601H8.01876C8.0841 8.69734 8.46143 6.66667 10.6854 6.66667C12.8788 6.66667 13.2508 8.69734 13.3148 9.95601H13.4068ZM3.35875 15.88L4.43875 15.0987C3.62541 13.9733 3.16541 12.6653 3.04808 11.2893H4.66675V9.95599H3.04941C3.17075 8.58933 3.63075 7.28933 4.43875 6.17066L3.35875 5.38933C2.38408 6.73733 1.84008 8.30666 1.71608 9.95599H0.666748V11.2893H1.71475C1.83475 12.948 2.37875 14.5267 3.35875 15.88ZM15.0854 16.8187C13.9668 17.6293 12.6401 18.0693 11.2894 18.184V16.6667H9.95611V18.184C8.60277 18.0693 7.27744 17.6293 6.15877 16.8187L5.37744 17.8987C6.72677 18.876 8.30277 19.42 9.95611 19.5413V20.6667H11.2894V19.5413C12.9428 19.42 14.5174 18.876 15.8668 17.8987L15.0854 16.8187ZM6.15877 4.45067C7.27744 3.64134 8.60277 3.20001 9.95611 3.08667V4.66667H11.2894V3.08667C12.6401 3.20134 13.9668 3.64134 15.0854 4.45201L15.8668 3.37067C14.5201 2.39601 12.9174 1.87601 11.2894 1.75867V0.666672H9.95611V1.75867C8.32544 1.87601 6.72411 2.39601 5.37744 3.37201L6.15877 4.45067ZM19.5281 9.95599C19.4041 8.30533 18.8601 6.73466 17.8854 5.38933L16.8054 6.17199C17.6134 7.28933 18.0734 8.58799 18.1947 9.95599H16.6667V11.2893H18.1947C18.0787 12.6667 17.6187 13.9747 16.8054 15.0973L17.8854 15.88C18.8641 14.528 19.4094 12.9493 19.5281 11.2893H20.6667V9.95599H19.5281ZM16.3694 5.83069L19.0081 3.19202L18.0654 2.24935L15.4268 4.88802L16.3694 5.83069ZM4.87342 15.4401L2.23608 18.0788L3.17875 19.0214L5.81608 16.3828L4.87342 15.4401ZM7.9974 7.06789L2.47473 1.54655L1.53206 2.48922L7.05473 8.01055L7.9974 7.06789ZM15.6321 14.6895L20.1388 19.1961L19.1948 20.1388L14.6895 15.6321L15.6321 14.6895Z"
        fill="#6B6B6B"
      />
    </svg>
  </Box>
);

export default AWSSecretsManagerIcon;
