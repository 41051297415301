import { toast } from 'react-toastify';

export default function handleError ({
  message,
  error,
  onError
}: {
  message: string
  error?: Error
  onError?: (e?: Error | undefined) => void
}): void {
  console.error(message, error);
  toast.error(message);
  onError?.(error);
}
