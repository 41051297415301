import { useApi } from 'contexts/ApiProvider';
import {
  type SetNoteResponse,
  type ApiApiKey,
  type ParentType_ParentTypeEnum
} from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import { API_KEYS_QUERY_KEY } from 'queries/useApiKeys';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';
import handleError from 'utils/handleError';

interface SetNoteParams {
  parent: ApiApiKey | undefined
  parentType: ParentType_ParentTypeEnum
  note: string
}

interface UseSetNoteProps {
  onSuccess?: () => void
  onError?: (e: Error | undefined) => void
}

const useSetNote = ({
  onSuccess,
  onError
}: UseSetNoteProps): UseMutationResult<
SetNoteResponse,
unknown,
SetNoteParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError =
    'We encountered an issue while trying to set the note. Please try again later or contact support if the problem persists.';

  return useMutation(
    async ({ parent, note, parentType }: SetNoteParams) => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return {
          success: true
        };
      }
      await queryClient.cancelQueries([API_KEYS_QUERY_KEY]);
      return await api.setNote({
        parentStableId: parent?.stableId ?? '',
        parentType,
        note
      }).response;
    },
    {
      onSuccess: (resp) => {
        if (resp?.success) {
          onSuccess?.();
        } else {
          handleError({
            message: apiError,
            error: new Error(apiError),
            onError
          });
        }
      },
      onError: (e: Error) => {
        handleError({
          message: apiError,
          error: e,
          onError
        });
      }
    }
  );
};

export default useSetNote;
