import { Content, Main, PageLayout } from '@atlaskit/page-layout';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import ComingSoonPage from './ComingSoonPage';
import InventoryPage from './InventoryPage';
import PageNotFound from './PageNotFound';
import TopNavigationBar from 'components/TopNavigationBar';

export default function MainAppPage (): JSX.Element {
  const { currentPage } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage == null) {
      navigate('/app/secrets-inventory');
    }
  }, [navigate, currentPage]);

  let currentPageContent;
  if (currentPage == null) {
    return <></>;
  } else if (currentPage === '') {
    currentPageContent = <InventoryPage />;
  } else if (currentPage === 'dashboard') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'secrets-inventory') {
    currentPageContent = <InventoryPage />;
  } else if (currentPage === 'violations') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'compliance') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'rotate-keys') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'split-shared-use') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'optimize-permissions') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'deprovisioning') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'spiffe-server') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'integrations') {
    currentPageContent = <ComingSoonPage />;
  } else if (currentPage === 'settings') {
    currentPageContent = <ComingSoonPage />;
  } else {
    currentPageContent = <PageNotFound />;
  }

  return (
    <PageLayout>
      <TopNavigationBar />
      <Content>
        <Main>
          <Box
            style={{ padding: `${token('space.300')} ${token('space.400')}` }}
          >
            {currentPageContent}
          </Box>
        </Main>
      </Content>
    </PageLayout>
  );
}
