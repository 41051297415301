import 'react';
import type ColoredIconProps from './ColoredIconProps';
import { token } from '@atlaskit/tokens';

const PlusIcon = ({
  stroke = token('color.icon.accent.blue')
}: ColoredIconProps): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3.3335V12.6668"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33301 8.00015H12.6663"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
