import { Box, Stack } from '@atlaskit/primitives';
import ProgressBar from '@atlaskit/progress-bar';
import AccessKeysTable from './AccessKeysTableContainer';
import useApiKeys from 'queries/useApiKeys';
import { useRef } from 'react';
import { type AgGridReact } from 'ag-grid-react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import InventoryPageHeaders from './InventoryPageHeaders';

export default function InventoryPage (): JSX.Element {
  const { data: accessKeys } = useApiKeys();
  const gridRef = useRef<AgGridReact<ApiApiKey>>(null);

  const setFilter = async (field: string, value: string): Promise<void> => {
    await gridRef.current?.api.setColumnFilterModel(field, {
      type: 'contains',
      filter: value
    });
    gridRef.current?.api.onFilterChanged();
  };

  // TODO - Add empty and error states and display progress state only on isFetching from the query
  return accessKeys != null
    ? (
    <Stack grow="fill">
      <Box>
        <InventoryPageHeaders accessKeys={accessKeys} setFilter={setFilter} />
      </Box>
      <AccessKeysTable accessKeys={accessKeys} gridRef={gridRef} />
    </Stack>
      )
    : (
    <Box
      id="progress"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '90vh'
      }}
    >
      <ProgressBar isIndeterminate />
    </Box>
      );
}
