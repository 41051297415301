import { Box } from '@atlaskit/primitives';
import { type ICellRendererParams } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';

function TextRenderer ({
  value,
  valueFormatted
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {valueFormatted ?? value}
    </Box>
  );
}

export default TextRenderer;
