// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/api.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ApiService } from "./api";
import type { ListViolationConfigsForIdentityResponse } from "./violation_engine";
import type { ApiListViolationConfigsForIdentityRequest } from "./api";
import type { ApiUpsertViolationConfigRequest } from "./api";
import type { ApiReopenRiskRequest } from "./api";
import type { ApiAcceptRiskRequest } from "./api";
import type { ApiNotifyOwnerRequest } from "./api";
import type { ListAwsSsoUsersResponse } from "./api";
import type { GetSnapshotStartedAtResponse } from "./api";
import type { ApiListApiKeysResponse } from "./api";
import type { IsValidSessionResponse } from "./api";
import type { Empty } from "../../google/protobuf/empty";
import type { ObjectIdentifier } from "./common";
import type { CreateTokenRequest } from "./api";
import type { SetOwnerResponse } from "./api";
import type { SetOwnerRequest } from "./api";
import type { SetNoteResponse } from "./api";
import type { SetNoteRequest } from "./api";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SetStarredResponse } from "./api";
import type { SetStarredRequest } from "./api";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service torch.v1.ApiService
 */
export interface IApiServiceClient {
    /**
     * @generated from protobuf rpc: SetIsStarred(torch.v1.SetStarredRequest) returns (torch.v1.SetStarredResponse);
     */
    setIsStarred(input: SetStarredRequest, options?: RpcOptions): UnaryCall<SetStarredRequest, SetStarredResponse>;
    /**
     * @generated from protobuf rpc: SetNote(torch.v1.SetNoteRequest) returns (torch.v1.SetNoteResponse);
     */
    setNote(input: SetNoteRequest, options?: RpcOptions): UnaryCall<SetNoteRequest, SetNoteResponse>;
    /**
     * @generated from protobuf rpc: SetOwner(torch.v1.SetOwnerRequest) returns (torch.v1.SetOwnerResponse);
     */
    setOwner(input: SetOwnerRequest, options?: RpcOptions): UnaryCall<SetOwnerRequest, SetOwnerResponse>;
    /**
     * @generated from protobuf rpc: CreateToken(torch.v1.CreateTokenRequest) returns (torch.v1.ObjectIdentifier);
     */
    createToken(input: CreateTokenRequest, options?: RpcOptions): UnaryCall<CreateTokenRequest, ObjectIdentifier>;
    /**
     * @generated from protobuf rpc: IsValidSession(google.protobuf.Empty) returns (torch.v1.IsValidSessionResponse);
     */
    isValidSession(input: Empty, options?: RpcOptions): UnaryCall<Empty, IsValidSessionResponse>;
    /**
     * @generated from protobuf rpc: ListApiKeysCurrent(google.protobuf.Empty) returns (torch.v1.ApiListApiKeysResponse);
     */
    listApiKeysCurrent(input: Empty, options?: RpcOptions): UnaryCall<Empty, ApiListApiKeysResponse>;
    /**
     * @generated from protobuf rpc: GetSnapshotStartedAt(google.protobuf.Empty) returns (torch.v1.GetSnapshotStartedAtResponse);
     */
    getSnapshotStartedAt(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetSnapshotStartedAtResponse>;
    /**
     * @generated from protobuf rpc: ListAwsSsoUsers(google.protobuf.Empty) returns (torch.v1.ListAwsSsoUsersResponse);
     */
    listAwsSsoUsers(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListAwsSsoUsersResponse>;
    /**
     * @generated from protobuf rpc: NotifyOwner(torch.v1.ApiNotifyOwnerRequest) returns (google.protobuf.Empty);
     */
    notifyOwner(input: ApiNotifyOwnerRequest, options?: RpcOptions): UnaryCall<ApiNotifyOwnerRequest, Empty>;
    /**
     * @generated from protobuf rpc: AcceptRisk(torch.v1.ApiAcceptRiskRequest) returns (google.protobuf.Empty);
     */
    acceptRisk(input: ApiAcceptRiskRequest, options?: RpcOptions): UnaryCall<ApiAcceptRiskRequest, Empty>;
    /**
     * @generated from protobuf rpc: ReopenRisk(torch.v1.ApiReopenRiskRequest) returns (google.protobuf.Empty);
     */
    reopenRisk(input: ApiReopenRiskRequest, options?: RpcOptions): UnaryCall<ApiReopenRiskRequest, Empty>;
    /**
     * @generated from protobuf rpc: UpsertViolationConfig(torch.v1.ApiUpsertViolationConfigRequest) returns (google.protobuf.Empty);
     */
    upsertViolationConfig(input: ApiUpsertViolationConfigRequest, options?: RpcOptions): UnaryCall<ApiUpsertViolationConfigRequest, Empty>;
    /**
     * @generated from protobuf rpc: ListViolationConfigsForIdentity(torch.v1.ApiListViolationConfigsForIdentityRequest) returns (torch.v1.ListViolationConfigsForIdentityResponse);
     */
    listViolationConfigsForIdentity(input: ApiListViolationConfigsForIdentityRequest, options?: RpcOptions): UnaryCall<ApiListViolationConfigsForIdentityRequest, ListViolationConfigsForIdentityResponse>;
}
/**
 * @generated from protobuf service torch.v1.ApiService
 */
export class ApiServiceClient implements IApiServiceClient, ServiceInfo {
    typeName = ApiService.typeName;
    methods = ApiService.methods;
    options = ApiService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SetIsStarred(torch.v1.SetStarredRequest) returns (torch.v1.SetStarredResponse);
     */
    setIsStarred(input: SetStarredRequest, options?: RpcOptions): UnaryCall<SetStarredRequest, SetStarredResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetStarredRequest, SetStarredResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetNote(torch.v1.SetNoteRequest) returns (torch.v1.SetNoteResponse);
     */
    setNote(input: SetNoteRequest, options?: RpcOptions): UnaryCall<SetNoteRequest, SetNoteResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetNoteRequest, SetNoteResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetOwner(torch.v1.SetOwnerRequest) returns (torch.v1.SetOwnerResponse);
     */
    setOwner(input: SetOwnerRequest, options?: RpcOptions): UnaryCall<SetOwnerRequest, SetOwnerResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetOwnerRequest, SetOwnerResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateToken(torch.v1.CreateTokenRequest) returns (torch.v1.ObjectIdentifier);
     */
    createToken(input: CreateTokenRequest, options?: RpcOptions): UnaryCall<CreateTokenRequest, ObjectIdentifier> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateTokenRequest, ObjectIdentifier>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: IsValidSession(google.protobuf.Empty) returns (torch.v1.IsValidSessionResponse);
     */
    isValidSession(input: Empty, options?: RpcOptions): UnaryCall<Empty, IsValidSessionResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, IsValidSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListApiKeysCurrent(google.protobuf.Empty) returns (torch.v1.ApiListApiKeysResponse);
     */
    listApiKeysCurrent(input: Empty, options?: RpcOptions): UnaryCall<Empty, ApiListApiKeysResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ApiListApiKeysResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSnapshotStartedAt(google.protobuf.Empty) returns (torch.v1.GetSnapshotStartedAtResponse);
     */
    getSnapshotStartedAt(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetSnapshotStartedAtResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetSnapshotStartedAtResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAwsSsoUsers(google.protobuf.Empty) returns (torch.v1.ListAwsSsoUsersResponse);
     */
    listAwsSsoUsers(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListAwsSsoUsersResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ListAwsSsoUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: NotifyOwner(torch.v1.ApiNotifyOwnerRequest) returns (google.protobuf.Empty);
     */
    notifyOwner(input: ApiNotifyOwnerRequest, options?: RpcOptions): UnaryCall<ApiNotifyOwnerRequest, Empty> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApiNotifyOwnerRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AcceptRisk(torch.v1.ApiAcceptRiskRequest) returns (google.protobuf.Empty);
     */
    acceptRisk(input: ApiAcceptRiskRequest, options?: RpcOptions): UnaryCall<ApiAcceptRiskRequest, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApiAcceptRiskRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReopenRisk(torch.v1.ApiReopenRiskRequest) returns (google.protobuf.Empty);
     */
    reopenRisk(input: ApiReopenRiskRequest, options?: RpcOptions): UnaryCall<ApiReopenRiskRequest, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApiReopenRiskRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertViolationConfig(torch.v1.ApiUpsertViolationConfigRequest) returns (google.protobuf.Empty);
     */
    upsertViolationConfig(input: ApiUpsertViolationConfigRequest, options?: RpcOptions): UnaryCall<ApiUpsertViolationConfigRequest, Empty> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApiUpsertViolationConfigRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListViolationConfigsForIdentity(torch.v1.ApiListViolationConfigsForIdentityRequest) returns (torch.v1.ListViolationConfigsForIdentityResponse);
     */
    listViolationConfigsForIdentity(input: ApiListViolationConfigsForIdentityRequest, options?: RpcOptions): UnaryCall<ApiListViolationConfigsForIdentityRequest, ListViolationConfigsForIdentityResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApiListViolationConfigsForIdentityRequest, ListViolationConfigsForIdentityResponse>("unary", this._transport, method, opt, input);
    }
}
