/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { Box } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { type PropsWithChildren, type ReactElement } from 'react';

export default function Blur ({
  children,
  infoText
}: PropsWithChildren<{ infoText?: string }>): ReactElement {
  return (
    <Box
      style={{
        position: 'relative'
      }}
    >
      <Box
        style={{
          filter: 'blur(4px)',
          pointerEvents: 'none',
          userSelect: 'none',
          position: 'relative',
          width: '100%'
        }}
      >
        {children}
      </Box>
      {infoText !== undefined && (
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
          }}
        >
          <SectionMessage>
            <p>{infoText}</p>
          </SectionMessage>
        </Box>
      )}
    </Box>
  );
}
