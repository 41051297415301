import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';

import { type ApiApiKey } from 'gen/torch/v1/api';
import VAULT_MAPPER from 'mappers/vaultMapper';

function IdentifierRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  const vault = data?.vault;
  const Icon = vault != null ? VAULT_MAPPER[vault].Icon ?? null : null;
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        gap: token('space.100'),
        fontSize: '14px'
      }}
    >
      {Icon != null && <Icon />}
      {value}
    </Box>
  );
}

export default IdentifierRenderer;
