import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type ReactElement } from 'react';

export default function Environment ({
  children,
  Icon
}: PropsWithChildren<{
  Icon: () => ReactElement
}>): ReactElement {
  return (
    <Box
      style={{
        minWidth: '100px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: token('space.100')
      }}
    >
      <Icon />
      {children}
    </Box>
  );
}
