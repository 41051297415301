import { type RefObject, useEffect } from 'react';

// call function when user clicks outside passed via ref element
export default function useOutsideClick (
  ref: RefObject<HTMLElement | null>,
  onOutsideClick: () => void
): void {
  useEffect(() => {
    function handleClickOutside (event: MouseEvent): void {
      if (
        ref.current != null &&
        !ref.current.contains(event.target as HTMLElement)
      ) {
        onOutsideClick();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
