import { getEnvSpecificVar, type AppEnvironment } from '@torchsec/shared';

export const LOCAL_APP_ENV = 'local';
// TODO: Replace this placeholder within the nodejs server instead of using docker build
export const DOCKER_APP_ENV = '@@@TORCH_SECURITY_APP_ENV_PLACEHOLDER@@@';

export const CLIENT_APP_ENV =
  process.env.REACT_APP_IS_DOCKER === 'true' ? DOCKER_APP_ENV : LOCAL_APP_ENV;

export function getEnvSpecificVarClient (
  envMap: Record<AppEnvironment, string>
): string {
  return getEnvSpecificVar(envMap, CLIENT_APP_ENV as AppEnvironment);
}
