// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/tenants.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { ObjectIdentifier } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message torch.v1.CreateTenantRequest
 */
export interface CreateTenantRequest {
    /**
     * @generated from protobuf field: string slug = 1;
     */
    slug: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string workos_org_id = 3;
     */
    workosOrgId: string;
}
/**
 * @generated from protobuf message torch.v1.ReadByWorkosOrgIdRequest
 */
export interface ReadByWorkosOrgIdRequest {
    /**
     * @generated from protobuf field: string workos_org_id = 1;
     */
    workosOrgId: string;
}
/**
 * @generated from protobuf message torch.v1.Tenant
 */
export interface Tenant {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string slug = 2;
     */
    slug: string; // The unique slug of the tenant
    /**
     * @generated from protobuf field: string display_name = 3;
     */
    displayName: string; // The tenant display name
    /**
     * @generated from protobuf field: string workos_org_id = 4;
     */
    workosOrgId: string; // The WorkOS Organization ID
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateTenantRequest$Type extends MessageType<CreateTenantRequest> {
    constructor() {
        super("torch.v1.CreateTenantRequest", [
            { no: 1, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "workos_org_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.CreateTenantRequest
 */
export const CreateTenantRequest = new CreateTenantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadByWorkosOrgIdRequest$Type extends MessageType<ReadByWorkosOrgIdRequest> {
    constructor() {
        super("torch.v1.ReadByWorkosOrgIdRequest", [
            { no: 1, name: "workos_org_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ReadByWorkosOrgIdRequest
 */
export const ReadByWorkosOrgIdRequest = new ReadByWorkosOrgIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tenant$Type extends MessageType<Tenant> {
    constructor() {
        super("torch.v1.Tenant", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "workos_org_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.Tenant
 */
export const Tenant = new Tenant$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.TenantsService
 */
export const TenantsService = new ServiceType("torch.v1.TenantsService", [
    { name: "Create", options: {}, I: CreateTenantRequest, O: ObjectIdentifier },
    { name: "Read", options: {}, I: ObjectIdentifier, O: Tenant },
    { name: "ReadByWorkosOrgId", options: {}, I: ReadByWorkosOrgIdRequest, O: Tenant }
]);
