import { useEffect, useCallback, useRef } from 'react';

const DEFAULT_DELAY_MS = 100;

const useDebounceCallback = <T,>(
  callback: (value: T) => void,
  delay: number = DEFAULT_DELAY_MS
): ((value: T) => void) => {
  const handler = useRef<number | undefined>(undefined);

  const debounceCallback = useCallback(
    (value: T) => {
      if (handler.current != null) {
        clearTimeout(handler.current);
      }
      handler.current = window.setTimeout(() => {
        callback(value);
      }, delay);
    },
    [callback, delay]
  );

  useEffect(() => {
    return () => {
      if (handler.current != null) {
        clearTimeout(handler.current);
      }
    };
  }, []);

  return debounceCallback;
};

export default useDebounceCallback;
