import { CreateApiClient } from 'Api';
import { type ApiServiceClient } from 'gen/torch/v1/api.client';
import { createContext, useContext, type PropsWithChildren } from 'react';

const ApiContext = createContext<ApiServiceClient | undefined>(undefined);

interface ApiProviderProps {
  token: string
}
export default function ApiProvider ({
  token,
  children
}: PropsWithChildren<ApiProviderProps>): JSX.Element {
  const apiClient = CreateApiClient(token);

  return (
    <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
  );
}

export function useApi (): ApiServiceClient {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi hook must be used in ApiProvider');
  }
  return context;
}
