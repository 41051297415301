import {
  type ValueFormatterParams,
  type ValueGetterParams
} from 'ag-grid-community';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { DateTime } from 'luxon';

export const dateValueGetter = (
  params: ValueGetterParams<ApiApiKey, Timestamp>
): Date | null => {
  const colId: keyof ApiApiKey = params.column?.getColId() as keyof ApiApiKey;
  if (colId == null) return null;

  const timestamp: Timestamp = params.data?.[colId] as Timestamp;
  if (timestamp == null) return null;

  return Timestamp.toDate(timestamp);
};

const dateValueFormatter = (
  params: ValueFormatterParams<ApiApiKey, Timestamp>
): string => {
  const date = params.value;
  if (!(date instanceof Date)) return '';

  return DateTime.fromJSDate(date).toRelative() ?? '';
};

export default dateValueFormatter;
