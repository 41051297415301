import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import AWSSecretsManagerIcon from 'assets/icons/vaults/AWSSecretsManagerIcon';
import styled from 'styled-components';

interface StatusCardStat {
  label: string
  value: number
  icon?: keyof typeof SUPPORTED_ICONS
  onClick?: () => void
}

const SUPPORTED_ICONS: any = {
  awsSecretsManager: <AWSSecretsManagerIcon width="10" height="10" />
};

const StyledBox = styled.div<{ isClickable: boolean }>`
  background-color: ${token('color.background.accent.gray.subtlest')};
  color: ${token('color.text.subtle')};
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  border-radius: 12px;
  padding: ${token('space.050')} ${token('space.075')};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

  ${(props) =>
    props.isClickable
      ? `
    &:hover {
      background-color: ${token('color.background.accent.blue.subtle')};
      color: ${token('color.text.inverse')};
    }
    `
      : ''};
`;

interface StatusCardProps {
  value: string
  title: string
  stats?: StatusCardStat[]
}

export function StatusCard ({
  value,
  title,
  stats
}: StatusCardProps): JSX.Element {
  return (
    <Box
      style={{
        padding: `${token('space.200')} ${token('space.250')}`,
        border: `1px solid ${token('color.border')}`,
        borderRadius: token('space.150'),
        boxShadow: token('elevation.shadow.overflow'),
        display: 'flex',
        flexDirection: 'row',
        flex: 1
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: token('space.150'),
          width: '50%'
        }}
      >
        <Box
          style={{
            font: token('font.heading.small')
          }}
        >
          {title}
        </Box>
        <Box
          style={{ fontSize: '36px', lineHeight: '36px', fontWeight: 'bold' }}
        >
          {value}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: token('space.050'),
          width: '50%'
        }}
      >
        {stats?.map((stat) => (
          <Box
            key={stat.label}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              font: token('font.heading.small')
            }}
          >
            <Box
              style={{
                color: token('color.icon.subtle'),
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 400,
                display: 'flex',
                flexDirection: 'row',
                gap: token('space.100'),
                alignItems: 'center'
              }}
            >
              {stat.icon != null && SUPPORTED_ICONS[stat.icon]}
              {stat.label}
            </Box>
            <StyledBox
              isClickable={stat.onClick != null}
              onClick={stat.onClick}
            >
              {stat.value}
            </StyledBox>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
