import { type User } from '@torchsec/shared';

const LocalStorage = {
  getUser (): User | null {
    const rawUser = localStorage.getItem('user');
    if (rawUser == null) {
      return null;
    }

    return JSON.parse(rawUser) as User;
  },

  setUser (user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  },

  removeUser (): void {
    localStorage.removeItem('user');
  }
};

export default LocalStorage;
