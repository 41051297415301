import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ReactElement } from 'react';

export default function Separator (): ReactElement {
  return (
    <Box
      style={{
        backgroundColor: token('color.border'),
        width: '1px',
        alignSelf: 'stretch'
      }}
    />
  );
}
