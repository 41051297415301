/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
  useCallback,
  useEffect
} from 'react';
import CloseDrawer from 'components/Drawers/CloseDrawer';
import Blanket from 'components/Blanket/Blanket';
import { AnimatePresence, motion } from 'framer-motion';

export enum IDENTIFIERS {
  API_KEYS_DRAWER = 'API_KEYS_DRAWER',
  SETTINGS_DRAWER = 'SETTINGS_DRAWER'
}

interface DrawerContextType {
  openDrawer: (identifier: IDENTIFIERS, content: ReactNode) => void
  closeDrawer: (identifier: IDENTIFIERS) => void
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export default function DrawerProvider ({
  children
}: PropsWithChildren): JSX.Element {
  const [drawers, setDrawers] = useState<
  Array<{ identifier: IDENTIFIERS, content: ReactNode }>
  >([]);

  const openDrawer = useCallback(
    (identifier: IDENTIFIERS, content: ReactNode): void => {
      setDrawers((prevDrawers) => [
        ...prevDrawers.filter((drawer) => drawer.identifier !== identifier),
        { content, identifier }
      ]);
    },
    []
  );

  const closeDrawer = useCallback((identifier: IDENTIFIERS): void => {
    setDrawers((prevDrawers) =>
      prevDrawers.filter((drawer) => drawer.identifier !== identifier)
    );
  }, []);

  // Close top drawer on Esc press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && drawers.length > 0) {
        const topDrawer = drawers[drawers.length - 1];
        closeDrawer(topDrawer.identifier);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [drawers, closeDrawer]);

  // hide scroll when any drawer is open
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (drawers.length > 0) {
      setTimeout(() => {
        body?.classList.add('no-scroll');
      }, 0);
    }
    return () => {
      body?.classList.remove('no-scroll');
    };
  }, [drawers]);

  const renderDrawers = useCallback((): ReactNode => {
    return (
      <AnimatePresence>
        {drawers.map((drawer, index) => (
          <Blanket
            key={drawer.identifier}
            onClick={() => {
              closeDrawer(drawer.identifier);
            }}
          >
            <motion.div
              style={{
                position: 'fixed',
                top: 0,
                height: '100vh',
                zIndex: 100 + index
              }}
              initial={{
                transform: 'translateX(100vw) translateX(100%)'
              }}
              animate={{
                transform: 'translateX(100vw) translateX(-100%)'
              }}
              exit={{
                transform: 'translateX(100vw) translateX(100%)'
              }}
              transition={{
                duration: 0.5,
                ease: 'easeInOut'
              }}
            >
              {drawer.content}
              <CloseDrawer identifier={drawer.identifier} />
            </motion.div>
          </Blanket>
        ))}
      </AnimatePresence>
    );
  }, [drawers]);

  const value = useMemo(
    () => ({
      openDrawer,
      closeDrawer
    }),
    [openDrawer, closeDrawer]
  );

  return (
    <DrawerContext.Provider value={value}>
      <>
        {children}
        {renderDrawers()}
      </>
    </DrawerContext.Provider>
  );
}

export function useDrawer (): DrawerContextType {
  const context = useContext(DrawerContext);

  if (context == null) {
    throw new Error('useDrawer must be used in DrawerProvider');
  }

  return context;
}
