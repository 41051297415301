import { IconButton } from '@atlaskit/button/new';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import { type IconProps } from '@atlaskit/icon/types';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { faSortAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IHeaderParams, type SortDirection } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { useState } from 'react';

const SortIcon = (): JSX.Element => {
  return (
    <FontAwesomeIcon
      icon={faSortAlt}
      style={{ height: '12px', width: '12px' }}
      color={token('color.text.disabled')}
    />
  );
};

function AccessKeysTableHeader ({
  column,
  setSort,
  showColumnMenu,
  displayName
}: IHeaderParams<ApiApiKey>): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  if (displayName === '') {
    return <></>;
  }

  const [sort, setSortState] = useState<SortDirection | null>(null);
  const nextSort = sort === 'asc' ? 'desc' : sort === 'desc' ? null : 'asc';
  const hasFilters = column?.isFilterActive();

  const onSortRequested = (order: SortDirection | null): void => {
    setSort(order);
    setSortState(order);
  };

  const onFilterClicked = (event: React.MouseEvent<HTMLElement>): void => {
    showColumnMenu(event.currentTarget);
  };

  const CustomSortIcon = (iconProps: Partial<IconProps>): JSX.Element => {
    const IconComponent =
      sort === 'asc' ? ArrowUpIcon : sort === 'desc' ? ArrowDownIcon : SortIcon;

    const customSortIconProps: Partial<IconProps> = {
      size: 'small',
      primaryColor: token('color.text.subtle')
    };

    return <IconComponent label="" {...iconProps} {...customSortIconProps} />;
  };

  const CustomFilterIcon = (iconProps: Partial<IconProps>): JSX.Element => {
    const customFilterIconProps: Partial<IconProps> = {
      size: 'small',
      primaryColor: hasFilters
        ? token('color.text')
        : token('color.text.disabled')
    };

    return <FilterIcon label="" {...iconProps} {...customFilterIconProps} />;
  };

  return (
    <Box
      style={{
        fontWeight: token('font.weight.bold'),
        color: token('color.text.subtle'),
        fontSize: token('font.heading.xsmall'),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Box style={{ lineHeight: '26px' }}>
        {displayName}
        {(isHovered || sort != null) && (
          <IconButton
            spacing="compact"
            appearance="subtle"
            icon={CustomSortIcon}
            onClick={() => {
              onSortRequested(nextSort);
            }}
            label=""
          />
        )}
        {(isHovered || hasFilters) && (
          <IconButton
            spacing="compact"
            appearance="subtle"
            icon={CustomFilterIcon}
            onClick={onFilterClicked}
            label=""
          />
        )}
      </Box>
    </Box>
  );
}

export default AccessKeysTableHeader;
