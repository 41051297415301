/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { type PropsWithChildren, type ReactElement } from 'react';
import { motion } from 'framer-motion';

export default function Blanket ({
  children,
  onClick
}: PropsWithChildren<{ onClick?: () => void }>): ReactElement {
  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (e.currentTarget !== e.target) return;
    onClick?.();
  };

  return (
    <motion.div
      onClick={handleClick}
      style={{
        zIndex: 100,
        position: 'fixed',
        width: '100vw',
        top: 0,
        left: 0,
        height: '100vh'
      }}
      initial={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      animate={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      exit={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      transition={{
        duration: 0.5
      }}
    >
      {children}
    </motion.div>
  );
}
