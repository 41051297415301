import { Box } from '@atlaskit/primitives';

export default function PageMessage ({
  message
}: {
  message: string
}): JSX.Element {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <h4>{message}</h4>
    </Box>
  );
}
