import { Box } from '@atlaskit/primitives';
import HumanIcon from 'assets/icons/HumanIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import { useState } from 'react';

const SelectOwnerButton = ({
  isOpen,
  isSet
}: {
  isOpen: boolean
  isSet: boolean
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {isHovered && !isSet && !isOpen ? <PlusIcon /> : <HumanIcon />}
    </Box>
  );
};

export default SelectOwnerButton;
