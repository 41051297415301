import 'react';
import type ColoredIconProps from './ColoredIconProps';
import { token } from '@atlaskit/tokens';

const HumanIcon = ({
  stroke = token('color.icon.accent.gray')
}: ColoredIconProps): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3332 13.9998V12.6665C13.3332 11.9593 13.0522 11.281 12.5521 10.7809C12.052 10.2808 11.3737 9.99985 10.6665 9.99985H5.33317C4.62593 9.99985 3.94765 10.2808 3.44755 10.7809C2.94746 11.281 2.6665 11.9593 2.6665 12.6665V13.9998"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 7.33318C9.47243 7.33318 10.6663 6.13927 10.6663 4.66651C10.6663 3.19375 9.47243 1.99985 7.99967 1.99985C6.52692 1.99985 5.33301 3.19375 5.33301 4.66651C5.33301 6.13927 6.52692 7.33318 7.99967 7.33318Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HumanIcon;
