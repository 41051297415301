// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/common.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message torch.v1.ObjectIdentifier
 */
export interface ObjectIdentifier {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ObjectIdentifier$Type extends MessageType<ObjectIdentifier> {
    constructor() {
        super("torch.v1.ObjectIdentifier", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ObjectIdentifier
 */
export const ObjectIdentifier = new ObjectIdentifier$Type();
