import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ApiApiKey } from 'gen/torch/v1/api';

import { useState, type ComponentProps, type ReactElement } from 'react';
import InformationSection from './InformationSection/InformationSection';
import BottomTabs from './tabs/BottomTabs';
import Header from './Header';

export default function ApiKeysDrawer ({
  initialApiKey,
  apiKeys,
  gridParams
}: {
  initialApiKey: ApiApiKey
  gridParams: ComponentProps<typeof InformationSection>['gridParams']
  apiKeys: ApiApiKey[]
}): ReactElement {
  const [currentApiKey, setCurrentApiKey] = useState<ApiApiKey>(initialApiKey);

  return (
    <Box
      style={{
        backgroundColor: token('elevation.surface'),
        width: '1152px',
        height: '100vh',
        overflowY: 'auto'
      }}
    >
      <Header apiKey={currentApiKey} />
      <InformationSection
        apiKeys={apiKeys}
        apiKey={currentApiKey}
        gridParams={gridParams}
        onSelect={(selectedApiKey) => {
          setCurrentApiKey(selectedApiKey);
        }}
      />
      <BottomTabs apiKey={currentApiKey} />
    </Box>
  );
}
