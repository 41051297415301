import Avatar from '@atlaskit/avatar';
import Button, { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useUser } from 'contexts/UserProvider';

export default function ProfileMenu (): JSX.Element {
  const { user } = useUser();

  function logoutOnClick (): void {
    window.location.href = '/auth/logout';
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: token('space.100')
      }}
    >
      <IconButton
        icon={NotificationIcon}
        label="notification"
        appearance="subtle"
        isDisabled
      />
      <IconButton
        icon={SettingsIcon}
        label="settings"
        appearance="subtle"
        isDisabled
      />
      <DropdownMenu<HTMLButtonElement>
        trigger={({ triggerRef, ...props }) => (
          <IconButton
            {...props}
            icon={UserAvatarCircleIcon}
            label="profile"
            ref={triggerRef}
            appearance="subtle"
          />
        )}
        shouldRenderToParent
      >
        <DropdownItemGroup>
          <DropdownItem>
            <Avatar size="small" />
          </DropdownItem>
          <DropdownItem>
            <div>{user?.email != null ? user.email : ''}</div>
          </DropdownItem>
          <DropdownItem>
            <Button onClick={logoutOnClick}>Logout</Button>
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
      <Box
        style={{
          fontSize: '14px',
          lineHeight: '16px'
        }}
      >
        {user?.email}
      </Box>
    </Box>
  );
}
