import { Box } from '@atlaskit/primitives';
import SelectOwnerButton from './SelectOwnerButton';
import SelectDropdown, {
  type SelectDropdownOption
} from 'components/SelectDropdown/SelectDropdown';
import useAwsSsoUsers from 'queries/useAwsSsoUsers';
import { useMemo } from 'react';
import Avatar from '@atlaskit/avatar';
import { type AwsSsoUserData } from 'gen/torch/v1/api';

interface OwnerSelectionDropdownProps {
  onSelect: (value: SelectDropdownOption) => void
  ownersAppOccurencesCount?: Record<string, number>
  isSet: boolean
  currentValue?: string | null
}

const OwnerSelectionDropdown: React.FC<OwnerSelectionDropdownProps> = ({
  onSelect,
  ownersAppOccurencesCount,
  isSet,
  currentValue
}): JSX.Element | null => {
  const { data: awsSsoUsers, isFetched } = useAwsSsoUsers();

  const handleSort = (a: AwsSsoUserData, b: AwsSsoUserData): number => {
    const aOccurenceCount = ownersAppOccurencesCount?.[a.displayName] ?? 0;
    const bOccurenceCount = ownersAppOccurencesCount?.[b.displayName] ?? 0;

    if (bOccurenceCount !== aOccurenceCount) {
      return bOccurenceCount - aOccurenceCount;
    }

    return a.displayName.localeCompare(b.displayName);
  };

  const ownerOptions = useMemo(() => {
    return awsSsoUsers != null
      ? awsSsoUsers.sort(handleSort).map((user) => ({
        value: user.stableId,
        label: user.displayName,
        icon: <Avatar size="small" borderColor="transparent" />
      }))
      : [];
  }, [awsSsoUsers, ownersAppOccurencesCount]);

  return isFetched
    ? (
    <Box style={{ height: '100%' }}>
      <SelectDropdown
        options={ownerOptions}
        onSelect={onSelect}
        trigger={(isOpen) => (
          <SelectOwnerButton isOpen={isOpen} isSet={isSet} />
        )}
        currentValue={currentValue}
        search
      />
    </Box>
      )
    : null;
};

export default OwnerSelectionDropdown;
