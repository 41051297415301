import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Handle, type NodeProps, Position } from '@xyflow/react';
import VAULT_MAPPER from 'mappers/vaultMapper';
import { memo, type ReactElement } from 'react';

const IdentifierNode = memo(
  ({
    data
  }: NodeProps & { data: { label: string, vault: string } }): ReactElement => {
    const vault = data?.vault;
    const Icon = vault != null ? (VAULT_MAPPER[vault].Icon ?? null) : null;
    return (
      <Box
        style={{
          padding: token('space.100'),
          backgroundColor: token('elevation.surface'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          gap: token('space.100'),
          maxWidth: '200px',
          height: '80px',
          overflowWrap: 'anywhere',
          borderRadius: token('space.400')
        }}
      >
        {Icon != null && <Icon />}
        {data.label}
        <Handle
          type="target"
          isConnectable={false}
          position={Position.Left}
          style={{
            left: token('space.negative.050')
          }}
        />
        <Handle
          type="source"
          isConnectable={false}
          position={Position.Right}
          style={{
            right: token('space.negative.050')
          }}
        />
      </Box>
    );
  }
);

IdentifierNode.displayName = 'IdentifierNode';

export default IdentifierNode;
