import { useQuery, type UseQueryResult } from 'react-query';
import { useApi } from 'contexts/ApiProvider';
import { type AwsSsoUserData } from 'gen/torch/v1/api';
import handleError from 'utils/handleError';
import LocalStorage from 'LocalStorage';
import AWS_SSO_USERS_MOCK from 'mocks/demo/useAwsSsoUsersMock';

export const AWS_SSO_USERS_QUERY_KEY = 'awsSsoUsers';

const useAwsSsoUsers = (): UseQueryResult<AwsSsoUserData[], Error> => {
  const api = useApi();

  const apiError =
    'We encountered an issue while listing the AWS SSO users. Please try again later or contact support if the problem persists.';

  return useQuery<AwsSsoUserData[], Error>(
    AWS_SSO_USERS_QUERY_KEY,
    async () => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return AWS_SSO_USERS_MOCK;
      }
      const response = await api.listAwsSsoUsers({}).response;
      return response.awsSsoUsers;
    },
    {
      onError: (e: Error) => {
        handleError({
          message: apiError,
          error: e
        });
      }
    }
  );
};

export default useAwsSsoUsers;
