import { type ValueGetterParams } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import APPLICATION_MAPPER from 'mappers/applicationMapper';

export interface ApplicationValue {
  name: string
  category: string
}

export const ApplicationGetter = (
  params: ValueGetterParams<ApiApiKey, string>
): string => {
  const applicationValue = {
    name: params.data?.applicationName ?? '',
    category:
      APPLICATION_MAPPER[params.data?.applicationDomain ?? '']?.category ?? ''
  };

  return JSON.stringify(applicationValue);
};

export default ApplicationGetter;
