import { type ICellRendererParams } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import ViolationsBadges from 'components/ViolationsBadges';

export default function ViolationsRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, string[]>): JSX.Element {
  return (
    <ViolationsBadges apiKey={data} />
  );
}
