import { getEnvSpecificVarClient } from 'Env';
import { PostHogProvider } from 'posthog-js/react';
import { type PropsWithChildren } from 'react';

const postHogApiKeyEnvMap = {
  // See https://us.posthog.com/project/77064/settings/project#variables
  local: 'phc_anoWyVq6T5Oq4otVPiNmmXYicIuYFDES5JoWdEouSv',
  // See https://us.posthog.com/project/77060/settings/project#variables
  dev: 'phc_6XhKVeqP56Whm2bm4EYDbhoTZk4YoprwBEdvVf0cggq',
  // See https://us.posthog.com/project/77061/settings/project#variables
  staging: 'phc_wiMJMKYTqHwvcJfXof0393YusWtz2VcpZK8NDQ4mOMZ',
  // See https://us.posthog.com/project/77062/settings/project#variables
  prod: 'phc_6F2nWlAVx0R2fJUBMwqXglMWNrSb1QNhG9szUemC7rC',
  // See https://us.posthog.com/project/77063/settings/project#variables
  demo: 'phc_NWkFqlXoZApYmbxOrdy6M8EKKVRyeqVQN8pRbwTlpEz'
};

const options = {
  api_host: 'https://us.i.posthog.com'
};

export default function TorchPostHogProvider ({
  children
}: PropsWithChildren<any>): JSX.Element {
  return (
    <PostHogProvider
      apiKey={getEnvSpecificVarClient(postHogApiKeyEnvMap)}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
}
