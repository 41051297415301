import '@atlaskit/css-reset';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import UserProvider from 'contexts/UserProvider';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Flip, ToastContainer } from 'react-toastify';
import App from './App';
import './index.css';

import { token } from '@atlaskit/tokens';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import TorchPostHogProvider from 'PostHog';

const queryClient = new QueryClient();

const StyledToastContainer = styled(ToastContainer)`
  font-family: ${token('font.family.body')};
  font-size: 12px;
  svg {
    fill: ${token('color.text.subtle')};
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') ?? document.body
);
root.render(
  <TorchPostHogProvider>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <App />
        <StyledToastContainer
          position="bottom-right"
          transition={Flip}
          autoClose={2000}
          hideProgressBar
        />
      </QueryClientProvider>
    </UserProvider>
  </TorchPostHogProvider>
);
