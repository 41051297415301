import { Box } from '@atlaskit/primitives';
import ProgressBar from '@atlaskit/progress-bar';
import { setGlobalTheme } from '@atlaskit/tokens';
import ApiProvider from 'contexts/ApiProvider';
import { useUser } from 'contexts/UserProvider';
import { type User } from '@torchsec/shared';
import { useCallback, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import MainAppPage from './pages/MainAppPage';
import { CreateApiClient } from 'Api';
import { Empty } from 'gen/google/protobuf/empty';
import DrawerProvider from 'contexts/DrawerProvider';

interface AppInternalProps {
  user: User | null
}
function AppInternal ({ user }: AppInternalProps): JSX.Element {
  const redirectToLogin = (): JSX.Element => {
    useEffect(() => {
      window.location.href = '/auth/login';
    }, []);
    return <></>;
  };

  let token = '';
  if (user?.accessToken != null) {
    token = user.accessToken;
  }
  return (
    <Router>
      <ApiProvider token={token}>
        <DrawerProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/app" />} />
            <Route
              path="/app"
              element={user != null ? <MainAppPage /> : redirectToLogin()}
            />
            <Route
              path="/app/:currentPage"
              element={user != null ? <MainAppPage /> : redirectToLogin()}
            />
          </Routes>
        </DrawerProvider>
      </ApiProvider>
    </Router>
  );
}

export default function App (): JSX.Element {
  const { user, logout, setCurrentUser } = useUser();
  const [triedLogin, setTriedLogin] = useState(false);

  void setGlobalTheme({
    colorMode: 'light',
    typography: 'typography-adg3'
  });

  async function getClientApiUser (): Promise<User | null> {
    const response = await fetch('/api/get-user');

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    return data;
  }

  const fetchUserInternal = useCallback(async (): Promise<User | null> => {
    if (user != null) {
      setCurrentUser(user);
      return user;
    }

    const apiUser = await getClientApiUser();
    if (apiUser == null) {
      return null;
    }

    const isValidSessionResponse = await CreateApiClient(apiUser.accessToken)
      .isValidSession(Empty)
      .response.catch((err) => {
        console.error(err);
        logout();
        return null;
      });

    if (isValidSessionResponse == null || !isValidSessionResponse.isValid) {
      logout();
      return null;
    }

    return apiUser;
  }, [user]);

  const tryLogin = useCallback(async (): Promise<void> => {
    const fetchedUser = await fetchUserInternal();
    if (fetchedUser != null) {
      setCurrentUser(fetchedUser);
    }

    setTriedLogin(true);
  }, [fetchUserInternal, setCurrentUser, setTriedLogin]);

  useEffect(() => {
    if (!triedLogin) {
      void tryLogin();
    }
  }, [user, logout, setCurrentUser]);

  return triedLogin
    ? (
    <AppInternal user={user} />
      )
    : (
    <Box
      id="progress"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <ProgressBar isIndeterminate />
    </Box>
      );
}
