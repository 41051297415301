import { Box } from '@atlaskit/primitives';
import 'react';

const NoteIcon = (): JSX.Element => (
  <Box
    style={{
      width: '16px',
      height: '16px',
      display: 'flex'
    }}
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5C2.60218 2.5 2.22064 2.65804 1.93934 2.93934C1.65804 3.22064 1.5 3.60218 1.5 4V10C1.5 10.3978 1.65804 10.7794 1.93934 11.0607C2.22064 11.342 2.60218 11.5 3 11.5H4.5V13.1L6.567 11.638L6.761 11.5H13C13.3978 11.5 13.7794 11.342 14.0607 11.0607C14.342 10.7794 14.5 10.3978 14.5 10V4C14.5 3.60218 14.342 3.22064 14.0607 2.93934C13.7794 2.65804 13.3978 2.5 13 2.5H3ZM0 4C0 3.20435 0.31607 2.44129 0.87868 1.87868C1.44129 1.31607 2.20435 1 3 1H13C13.7956 1 14.5587 1.31607 15.1213 1.87868C15.6839 2.44129 16 3.20435 16 4V10C16 10.7956 15.6839 11.5587 15.1213 12.1213C14.5587 12.6839 13.7956 13 13 13H7.239L4.183 15.162L3 16V13C2.20435 13 1.44129 12.6839 0.87868 12.1213C0.31607 11.5587 0 10.7956 0 10L0 4ZM3 8.25C3 8.05109 3.07902 7.86032 3.21967 7.71967C3.36032 7.57902 3.55109 7.5 3.75 7.5H9.25C9.44891 7.5 9.63968 7.57902 9.78033 7.71967C9.92098 7.86032 10 8.05109 10 8.25C10 8.44891 9.92098 8.63968 9.78033 8.78033C9.63968 8.92098 9.44891 9 9.25 9H3.75C3.55109 9 3.36032 8.92098 3.21967 8.78033C3.07902 8.63968 3 8.44891 3 8.25ZM3.75 5C3.55109 5 3.36032 5.07902 3.21967 5.21967C3.07902 5.36032 3 5.55109 3 5.75C3 5.94891 3.07902 6.13968 3.21967 6.28033C3.36032 6.42098 3.55109 6.5 3.75 6.5H12.25C12.4489 6.5 12.6397 6.42098 12.7803 6.28033C12.921 6.13968 13 5.94891 13 5.75C13 5.55109 12.921 5.36032 12.7803 5.21967C12.6397 5.07902 12.4489 5 12.25 5H3.75Z"
        fill="#6B6B6B"
      />
    </svg>
  </Box>
);

export default NoteIcon;
