import Pagination from '@atlaskit/pagination';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import usePagination, { type usePaginationProps } from '../hooks/usePagination';

function ClientSidePagination ({
  totalLength,
  pageSize,
  setPage,
  timestampIndicator
}: usePaginationProps): JSX.Element {
  const { pages, onPageChange, pageDisplayStatus } = usePagination({
    totalLength,
    pageSize,
    setPage
  });

  return (
    <Box
      style={{
        width: '100%',
        marginTop: token('space.050'),
        paddingBottom: token('space.200'),
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        style={{
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: token('color.text.subtlest')
        }}
      >
        {pageDisplayStatus}
      </Box>
      <Pagination
        pages={pages}
        onChange={onPageChange}
        nextLabel="Next"
        label="Page"
        pageLabel="Page"
        previousLabel="Previous"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
      {timestampIndicator}
    </Box>
  );
}

export default ClientSidePagination;
