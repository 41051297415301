import { token } from '@atlaskit/tokens';
import { type ColDef } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import {
  dateValueFormatter,
  dateValueGetter,
  violationsValueGetter
} from './cells/formatters';
import {
  ApplicationRenderer,
  ConsumersRenderer,
  IdentifierRenderer,
  NoteRenderer,
  TextRenderer,
  ViolationsRenderer
} from './cells/renderers';
import OwnerRenderer from './cells/renderers/OwnerRenderer';
import AccessKeysTableHeader from './headers/AccessKeysTableHeader';
import EnvironmentRenderer from './cells/renderers/EnvironmentRenderer';
import ApplicationGetter from './cells/getters/applicationValueGetter';

const dateConfig: Partial<ColDef<ApiApiKey>> = {
  valueGetter: dateValueGetter,
  valueFormatter: dateValueFormatter,
  filter: 'agDateColumnFilter'
};

const cellStyle = {
  display: 'flex',
  alignItems: 'center'
};

type ApiKeyKey = keyof ApiApiKey;

const violationsColDef: ColDef<ApiApiKey> = {
  field: 'violations' as ApiKeyKey,
  headerName: 'Risk & Violations',
  flex: 1,
  minWidth: 210,
  valueGetter: violationsValueGetter,
  cellRenderer: ViolationsRenderer,
  filter: 'agTextColumnFilter',
  cellStyle
};
const identifierColDef: ColDef<ApiApiKey> = {
  field: 'identifier' as ApiKeyKey,
  headerName: 'Identifier',
  flex: 1,
  minWidth: 130,
  cellRenderer: IdentifierRenderer,
  filter: 'agTextColumnFilter'
};
const consumersColDef: ColDef<ApiApiKey> = {
  field: 'roleConsumers' as ApiKeyKey,
  headerName: 'Consumers',
  flex: 1,
  minWidth: 200,
  cellRenderer: ConsumersRenderer
};
const environmentColDef: ColDef<ApiApiKey> = {
  field: 'environmentName' as ApiKeyKey,
  headerName: 'Env',
  flex: 1,
  minWidth: 130,
  maxWidth: 130,
  cellRenderer: EnvironmentRenderer,
  filter: 'agTextColumnFilter',
  cellStyle
};
const applicationColDef: ColDef<ApiApiKey> = {
  field: 'applicationName' as ApiKeyKey,
  headerName: 'Application',
  flex: 1,
  minWidth: 150,
  cellRenderer: ApplicationRenderer,
  filterValueGetter: ApplicationGetter,
  filter: 'agTextColumnFilter'
};
const ownerColDef: ColDef<ApiApiKey> = {
  field: 'owner' as ApiKeyKey,
  headerName: 'Owner',
  flex: 1,
  minWidth: 120,
  cellRenderer: OwnerRenderer,
  filter: 'agTextColumnFilter'
};
const createdAtColDef: ColDef<ApiApiKey> = {
  field: 'createdAt' as ApiKeyKey,
  headerName: 'Created',
  flex: 1,
  minWidth: 130,
  maxWidth: 130,
  cellRenderer: TextRenderer,
  ...dateConfig
};
const lastRotatedAtColDef: ColDef<ApiApiKey> = {
  field: 'lastRotatedAt' as ApiKeyKey,
  headerName: 'Last Rotated',
  flex: 1,
  minWidth: 160,
  maxWidth: 160,
  cellRenderer: TextRenderer,
  ...dateConfig
};
const lastUsedColDef: ColDef<ApiApiKey> = {
  field: 'lastUsed' as ApiKeyKey,
  headerName: 'Last Used',
  flex: 1,
  minWidth: 140,
  maxWidth: 140,
  cellRenderer: TextRenderer,
  ...dateConfig
};
const noteColDef: ColDef<ApiApiKey> = {
  field: 'note' as ApiKeyKey,
  headerName: '',
  flex: 1,
  minWidth: 50,
  cellRenderer: NoteRenderer
};

const demoRawAccessKeysColumnDefinitions: Array<ColDef<ApiApiKey>> = [
  violationsColDef,
  applicationColDef,
  environmentColDef,
  identifierColDef,
  consumersColDef,
  ownerColDef,
  createdAtColDef,
  lastRotatedAtColDef,
  lastUsedColDef,
  noteColDef
];

const rawAccessKeysColumnDefinitions: Array<ColDef<ApiApiKey>> = [
  violationsColDef,
  applicationColDef,
  environmentColDef,
  identifierColDef,
  consumersColDef,
  ownerColDef,
  createdAtColDef,
  lastRotatedAtColDef,
  lastUsedColDef,
  noteColDef
];

export function getAccessKeysColumnDefinitions (
  isDemo: boolean
): Array<ColDef<ApiApiKey>> {
  const rawColDefs = isDemo
    ? demoRawAccessKeysColumnDefinitions
    : rawAccessKeysColumnDefinitions;
  return rawColDefs.map(
    (col): ColDef<ApiApiKey> => ({
      ...col,
      headerComponent: AccessKeysTableHeader,
      cellStyle: {
        color: token('color.text'),
        fontSize: '12px',
        whiteSpace: 'pre'
      }
    })
  );
}

export default getAccessKeysColumnDefinitions;
