import { useQuery, type UseQueryResult } from 'react-query';
import { useApi } from 'contexts/ApiProvider';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import { DateTime } from 'luxon';
import handleError from 'utils/handleError';
import LocalStorage from 'LocalStorage';

export const SNAPSHOT_STARTED_AT_QUERY_KEY = 'snapshotStartedAt';

const getRelativeDate = (date: Date): string | null => {
  return DateTime.fromJSDate(date).toRelative();
};

const useLastUpdateRelativeDate = (): UseQueryResult<string | null, Error> => {
  const api = useApi();

  const apiError =
    'We encountered an issue while fetching the last update date. Please try again later or contact support if the problem continues.';

  return useQuery<string | null, Error>(
    SNAPSHOT_STARTED_AT_QUERY_KEY,
    async () => {
      let startedAt: Timestamp | undefined;
      if (LocalStorage.getUser()?.isDemo === true) {
        // If the user is in demo mode - always show the last update as 1 hour ago
        startedAt = Timestamp.fromDate(new Date(Date.now() - 60 * 60 * 1000));
      } else {
        const response = await api.getSnapshotStartedAt({}).response;
        startedAt = response.startedAt;
      }
      if (startedAt === undefined) return null;

      const date = Timestamp.toDate(startedAt);
      return getRelativeDate(date);
    },
    {
      onError: (e: Error) => {
        handleError({
          message: apiError,
          error: e
        });
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false
    }
  );
};

export default useLastUpdateRelativeDate;
