// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/api.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { ListViolationConfigsForIdentityResponse } from "./violation_engine";
import { Empty } from "../../google/protobuf/empty";
import { ObjectIdentifier } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { TokenType } from "./tokens";
import { Platform_PlatformEnum } from "../../model/v1/identity";
import { ViolationSeverity_ViolationSeverityEnum } from "./violation_engine";
import { Timestamp } from "../../google/protobuf/timestamp";
import { ViolationConfigState_ViolationConfigStateEnum } from "./violation_engine";
import { ViolationType_ViolationTypeEnum } from "./violation_engine";
/**
 * @generated from protobuf message torch.v1.ApiListViolationConfigsForIdentityRequest
 */
export interface ApiListViolationConfigsForIdentityRequest {
    /**
     * @generated from protobuf field: string api_key_stable_id = 1;
     */
    apiKeyStableId: string;
}
/**
 * @generated from protobuf message torch.v1.ApiUpsertViolationConfigRequest
 */
export interface ApiUpsertViolationConfigRequest {
    /**
     * @generated from protobuf field: string platform_instance_id = 1;
     */
    platformInstanceId: string;
    /**
     * @generated from protobuf field: string api_key_stable_id = 2;
     */
    apiKeyStableId: string;
    /**
     * @generated from protobuf field: torch.v1.ViolationType.ViolationTypeEnum violation_type = 3;
     */
    violationType: ViolationType_ViolationTypeEnum;
    /**
     * @generated from protobuf field: int32 days_until_triggered = 4;
     */
    daysUntilTriggered: number;
    /**
     * @generated from protobuf field: torch.v1.ViolationConfigState.ViolationConfigStateEnum state = 5;
     */
    state: ViolationConfigState_ViolationConfigStateEnum;
}
/**
 * @generated from protobuf message torch.v1.ApiNotifyOwnerRequest
 */
export interface ApiNotifyOwnerRequest {
    /**
     * @generated from protobuf field: string violation_stable_id = 1;
     */
    violationStableId: string;
}
/**
 * @generated from protobuf message torch.v1.ApiAcceptRiskRequest
 */
export interface ApiAcceptRiskRequest {
    /**
     * @generated from protobuf field: string violation_stable_id = 1;
     */
    violationStableId: string;
}
/**
 * @generated from protobuf message torch.v1.ApiReopenRiskRequest
 */
export interface ApiReopenRiskRequest {
    /**
     * @generated from protobuf field: string violation_stable_id = 1;
     */
    violationStableId: string;
}
/**
 * @generated from protobuf message torch.v1.GetSnapshotStartedAtResponse
 */
export interface GetSnapshotStartedAtResponse {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp started_at = 1;
     */
    startedAt?: Timestamp;
}
/**
 * @generated from protobuf message torch.v1.IsValidSessionResponse
 */
export interface IsValidSessionResponse {
    /**
     * @generated from protobuf field: bool is_valid = 1;
     */
    isValid: boolean;
}
/**
 * @generated from protobuf message torch.v1.SetStarredResponse
 */
export interface SetStarredResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * @generated from protobuf message torch.v1.ApiViolation
 */
export interface ApiViolation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string stable_id = 6;
     */
    stableId: string;
    /**
     * @generated from protobuf field: torch.v1.ViolationType.ViolationTypeEnum type = 2;
     */
    type: ViolationType_ViolationTypeEnum;
    /**
     * @generated from protobuf field: torch.v1.ViolationSeverity.ViolationSeverityEnum severity = 3;
     */
    severity: ViolationSeverity_ViolationSeverityEnum;
    /**
     * @generated from protobuf field: int32 days_until_triggered = 4;
     */
    daysUntilTriggered: number;
    /**
     * @generated from protobuf field: bool is_enabled = 5;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf message torch.v1.ApiConsumer
 */
export interface ApiConsumer {
    /**
     * @generated from protobuf field: string consumer_type = 1;
     */
    consumerType: string;
    /**
     * @generated from protobuf field: string consumer_external_id = 2;
     */
    consumerExternalId: string;
    /**
     * @generated from protobuf field: string consumer_external_resource_name = 3;
     */
    consumerExternalResourceName: string;
    /**
     * @generated from protobuf field: string consumer_display_name = 4;
     */
    consumerDisplayName: string;
    /**
     * @generated from protobuf field: repeated torch.v1.ApiConsumer consumer_consumers = 5;
     */
    consumerConsumers: ApiConsumer[];
}
/**
 * @generated from protobuf message torch.v1.ApiRoleConsumers
 */
export interface ApiRoleConsumers {
    /**
     * @generated from protobuf field: string role = 1;
     */
    role: string;
    /**
     * @generated from protobuf field: repeated torch.v1.ApiConsumer consumers = 2;
     */
    consumers: ApiConsumer[];
}
/**
 * @generated from protobuf message torch.v1.CreateTokenRequest
 */
export interface CreateTokenRequest {
    /**
     * @generated from protobuf field: model.v1.Platform.PlatformEnum platform = 1;
     */
    platform: Platform_PlatformEnum;
    /**
     * @generated from protobuf field: string platform_instance_external_id = 2;
     */
    platformInstanceExternalId: string;
    /**
     * @generated from protobuf field: torch.v1.TokenType token_type = 3;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: string value = 4;
     */
    value: string;
}
/**
 * @generated from protobuf message torch.v1.SetStarredRequest
 */
export interface SetStarredRequest {
    /**
     * @generated from protobuf field: string identity_id = 1;
     */
    identityId: string;
    /**
     * @generated from protobuf field: bool is_starred = 2;
     */
    isStarred: boolean;
}
/**
 * @generated from protobuf message torch.v1.ParentType
 */
export interface ParentType {
    /**
     * @generated from protobuf field: torch.v1.ParentType.ParentTypeEnum parent_type = 1;
     */
    parentType: ParentType_ParentTypeEnum;
}
/**
 * @generated from protobuf enum torch.v1.ParentType.ParentTypeEnum
 */
export enum ParentType_ParentTypeEnum {
    /**
     * @generated from protobuf enum value: API_KEYS = 0;
     */
    API_KEYS = 0
}
/**
 * @generated from protobuf message torch.v1.SetNoteRequest
 */
export interface SetNoteRequest {
    /**
     * @generated from protobuf field: string parent_stable_id = 1;
     */
    parentStableId: string;
    /**
     * @generated from protobuf field: torch.v1.ParentType.ParentTypeEnum parent_type = 2;
     */
    parentType: ParentType_ParentTypeEnum;
    /**
     * @generated from protobuf field: string note = 3;
     */
    note: string;
}
/**
 * @generated from protobuf message torch.v1.SetNoteResponse
 */
export interface SetNoteResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * @generated from protobuf message torch.v1.OwnerType
 */
export interface OwnerType {
    /**
     * @generated from protobuf field: torch.v1.OwnerType.OwnerTypeEnum owner_type = 1;
     */
    ownerType: OwnerType_OwnerTypeEnum;
}
/**
 * @generated from protobuf enum torch.v1.OwnerType.OwnerTypeEnum
 */
export enum OwnerType_OwnerTypeEnum {
    /**
     * @generated from protobuf enum value: UNASSIGNED = 0;
     */
    UNASSIGNED = 0,
    /**
     * @generated from protobuf enum value: MANUAL = 1;
     */
    MANUAL = 1,
    /**
     * @generated from protobuf enum value: AWS_SSO_USER = 2;
     */
    AWS_SSO_USER = 2
}
/**
 * @generated from protobuf message torch.v1.SetOwnerRequest
 */
export interface SetOwnerRequest {
    /**
     * @generated from protobuf field: torch.v1.ParentType.ParentTypeEnum parent_type = 1;
     */
    parentType: ParentType_ParentTypeEnum;
    /**
     * @generated from protobuf field: string parent_stable_id = 2;
     */
    parentStableId: string;
    /**
     * @generated from protobuf field: torch.v1.OwnerType.OwnerTypeEnum owner_type = 3;
     */
    ownerType: OwnerType_OwnerTypeEnum;
    /**
     * @generated from protobuf field: string owner_stable_id = 4;
     */
    ownerStableId: string;
    /**
     * @generated from protobuf field: string owner_text = 5;
     */
    ownerText: string;
}
/**
 * @generated from protobuf message torch.v1.SetOwnerResponse
 */
export interface SetOwnerResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
    /**
     * @generated from protobuf field: torch.v1.OwnerType.OwnerTypeEnum owner_type = 3;
     */
    ownerType: OwnerType_OwnerTypeEnum;
    /**
     * @generated from protobuf field: string owner_stable_id = 4;
     */
    ownerStableId: string;
    /**
     * @generated from protobuf field: string owner_text = 5;
     */
    ownerText: string;
}
/**
 * @generated from protobuf message torch.v1.ApiApiKey
 */
export interface ApiApiKey {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string stable_id = 2;
     */
    stableId: string;
    /**
     * @generated from protobuf field: string tenant_id = 3;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string platform_instance_id = 4;
     */
    platformInstanceId: string;
    /**
     * @generated from protobuf field: string external_resource_name = 5;
     */
    externalResourceName: string;
    /**
     * @generated from protobuf field: string api_provider_slug = 7;
     */
    apiProviderSlug: string;
    /**
     * @generated from protobuf field: repeated torch.v1.ApiViolation violations = 9;
     */
    violations: ApiViolation[];
    /**
     * @generated from protobuf field: string identifier = 10;
     */
    identifier: string;
    /**
     * @generated from protobuf field: int32 environment = 11;
     */
    environment: number;
    /**
     * @generated from protobuf field: string application_name = 12;
     */
    applicationName: string;
    /**
     * @generated from protobuf field: string application_domain = 13;
     */
    applicationDomain: string;
    /**
     * @generated from protobuf field: string vault = 14;
     */
    vault: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 15;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_rotated_at = 16;
     */
    lastRotatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_used = 17;
     */
    lastUsed?: Timestamp;
    /**
     * @generated from protobuf field: string owner = 18;
     */
    owner: string;
    /**
     * @generated from protobuf field: repeated torch.v1.ApiRoleConsumers role_consumers = 19;
     */
    roleConsumers: ApiRoleConsumers[];
    /**
     * @generated from protobuf field: string note = 20;
     */
    note: string;
    /**
     * @generated from protobuf field: string environment_name = 21;
     */
    environmentName: string;
}
/**
 * @generated from protobuf message torch.v1.ApiListApiKeysResponse
 */
export interface ApiListApiKeysResponse {
    /**
     * @generated from protobuf field: repeated torch.v1.ApiApiKey results = 1;
     */
    results: ApiApiKey[];
}
/**
 * @generated from protobuf message torch.v1.AwsSsoUserData
 */
export interface AwsSsoUserData {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string stable_id = 2;
     */
    stableId: string;
    /**
     * @generated from protobuf field: string platform_instance_id = 3;
     */
    platformInstanceId: string;
    /**
     * @generated from protobuf field: string external_resource_name = 4;
     */
    externalResourceName: string; // Currently contains the email address
    /**
     * @generated from protobuf field: string user_name = 5;
     */
    userName: string;
    /**
     * @generated from protobuf field: string display_name = 6;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 7;
     */
    email: string;
}
/**
 * @generated from protobuf message torch.v1.ListAwsSsoUsersResponse
 */
export interface ListAwsSsoUsersResponse {
    /**
     * @generated from protobuf field: repeated torch.v1.AwsSsoUserData aws_sso_users = 1;
     */
    awsSsoUsers: AwsSsoUserData[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ApiListViolationConfigsForIdentityRequest$Type extends MessageType<ApiListViolationConfigsForIdentityRequest> {
    constructor() {
        super("torch.v1.ApiListViolationConfigsForIdentityRequest", [
            { no: 1, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiListViolationConfigsForIdentityRequest
 */
export const ApiListViolationConfigsForIdentityRequest = new ApiListViolationConfigsForIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiUpsertViolationConfigRequest$Type extends MessageType<ApiUpsertViolationConfigRequest> {
    constructor() {
        super("torch.v1.ApiUpsertViolationConfigRequest", [
            { no: 1, name: "platform_instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "violation_type", kind: "enum", T: () => ["torch.v1.ViolationType.ViolationTypeEnum", ViolationType_ViolationTypeEnum] },
            { no: 4, name: "days_until_triggered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "state", kind: "enum", T: () => ["torch.v1.ViolationConfigState.ViolationConfigStateEnum", ViolationConfigState_ViolationConfigStateEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiUpsertViolationConfigRequest
 */
export const ApiUpsertViolationConfigRequest = new ApiUpsertViolationConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiNotifyOwnerRequest$Type extends MessageType<ApiNotifyOwnerRequest> {
    constructor() {
        super("torch.v1.ApiNotifyOwnerRequest", [
            { no: 1, name: "violation_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiNotifyOwnerRequest
 */
export const ApiNotifyOwnerRequest = new ApiNotifyOwnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiAcceptRiskRequest$Type extends MessageType<ApiAcceptRiskRequest> {
    constructor() {
        super("torch.v1.ApiAcceptRiskRequest", [
            { no: 1, name: "violation_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiAcceptRiskRequest
 */
export const ApiAcceptRiskRequest = new ApiAcceptRiskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiReopenRiskRequest$Type extends MessageType<ApiReopenRiskRequest> {
    constructor() {
        super("torch.v1.ApiReopenRiskRequest", [
            { no: 1, name: "violation_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiReopenRiskRequest
 */
export const ApiReopenRiskRequest = new ApiReopenRiskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSnapshotStartedAtResponse$Type extends MessageType<GetSnapshotStartedAtResponse> {
    constructor() {
        super("torch.v1.GetSnapshotStartedAtResponse", [
            { no: 1, name: "started_at", kind: "message", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.GetSnapshotStartedAtResponse
 */
export const GetSnapshotStartedAtResponse = new GetSnapshotStartedAtResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsValidSessionResponse$Type extends MessageType<IsValidSessionResponse> {
    constructor() {
        super("torch.v1.IsValidSessionResponse", [
            { no: 1, name: "is_valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.IsValidSessionResponse
 */
export const IsValidSessionResponse = new IsValidSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStarredResponse$Type extends MessageType<SetStarredResponse> {
    constructor() {
        super("torch.v1.SetStarredResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetStarredResponse
 */
export const SetStarredResponse = new SetStarredResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiViolation$Type extends MessageType<ApiViolation> {
    constructor() {
        super("torch.v1.ApiViolation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["torch.v1.ViolationType.ViolationTypeEnum", ViolationType_ViolationTypeEnum] },
            { no: 3, name: "severity", kind: "enum", T: () => ["torch.v1.ViolationSeverity.ViolationSeverityEnum", ViolationSeverity_ViolationSeverityEnum] },
            { no: 4, name: "days_until_triggered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiViolation
 */
export const ApiViolation = new ApiViolation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiConsumer$Type extends MessageType<ApiConsumer> {
    constructor() {
        super("torch.v1.ApiConsumer", [
            { no: 1, name: "consumer_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "consumer_external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "consumer_external_resource_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "consumer_display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "consumer_consumers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiConsumer }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiConsumer
 */
export const ApiConsumer = new ApiConsumer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiRoleConsumers$Type extends MessageType<ApiRoleConsumers> {
    constructor() {
        super("torch.v1.ApiRoleConsumers", [
            { no: 1, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "consumers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiConsumer }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiRoleConsumers
 */
export const ApiRoleConsumers = new ApiRoleConsumers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTokenRequest$Type extends MessageType<CreateTokenRequest> {
    constructor() {
        super("torch.v1.CreateTokenRequest", [
            { no: 1, name: "platform", kind: "enum", T: () => ["model.v1.Platform.PlatformEnum", Platform_PlatformEnum] },
            { no: 2, name: "platform_instance_external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "token_type", kind: "enum", T: () => ["torch.v1.TokenType", TokenType] },
            { no: 4, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.CreateTokenRequest
 */
export const CreateTokenRequest = new CreateTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetStarredRequest$Type extends MessageType<SetStarredRequest> {
    constructor() {
        super("torch.v1.SetStarredRequest", [
            { no: 1, name: "identity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_starred", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetStarredRequest
 */
export const SetStarredRequest = new SetStarredRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentType$Type extends MessageType<ParentType> {
    constructor() {
        super("torch.v1.ParentType", [
            { no: 1, name: "parent_type", kind: "enum", T: () => ["torch.v1.ParentType.ParentTypeEnum", ParentType_ParentTypeEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ParentType
 */
export const ParentType = new ParentType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNoteRequest$Type extends MessageType<SetNoteRequest> {
    constructor() {
        super("torch.v1.SetNoteRequest", [
            { no: 1, name: "parent_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "parent_type", kind: "enum", T: () => ["torch.v1.ParentType.ParentTypeEnum", ParentType_ParentTypeEnum] },
            { no: 3, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetNoteRequest
 */
export const SetNoteRequest = new SetNoteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNoteResponse$Type extends MessageType<SetNoteResponse> {
    constructor() {
        super("torch.v1.SetNoteResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetNoteResponse
 */
export const SetNoteResponse = new SetNoteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OwnerType$Type extends MessageType<OwnerType> {
    constructor() {
        super("torch.v1.OwnerType", [
            { no: 1, name: "owner_type", kind: "enum", T: () => ["torch.v1.OwnerType.OwnerTypeEnum", OwnerType_OwnerTypeEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.OwnerType
 */
export const OwnerType = new OwnerType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOwnerRequest$Type extends MessageType<SetOwnerRequest> {
    constructor() {
        super("torch.v1.SetOwnerRequest", [
            { no: 1, name: "parent_type", kind: "enum", T: () => ["torch.v1.ParentType.ParentTypeEnum", ParentType_ParentTypeEnum] },
            { no: 2, name: "parent_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "owner_type", kind: "enum", T: () => ["torch.v1.OwnerType.OwnerTypeEnum", OwnerType_OwnerTypeEnum] },
            { no: 4, name: "owner_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "owner_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetOwnerRequest
 */
export const SetOwnerRequest = new SetOwnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOwnerResponse$Type extends MessageType<SetOwnerResponse> {
    constructor() {
        super("torch.v1.SetOwnerResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "owner_type", kind: "enum", T: () => ["torch.v1.OwnerType.OwnerTypeEnum", OwnerType_OwnerTypeEnum] },
            { no: 4, name: "owner_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "owner_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetOwnerResponse
 */
export const SetOwnerResponse = new SetOwnerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiApiKey$Type extends MessageType<ApiApiKey> {
    constructor() {
        super("torch.v1.ApiApiKey", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "platform_instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "external_resource_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "api_provider_slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "violations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiViolation },
            { no: 10, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "environment", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "application_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "application_domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "vault", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 16, name: "last_rotated_at", kind: "message", T: () => Timestamp },
            { no: 17, name: "last_used", kind: "message", T: () => Timestamp },
            { no: 18, name: "owner", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "role_consumers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiRoleConsumers },
            { no: 20, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "environment_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiApiKey
 */
export const ApiApiKey = new ApiApiKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiListApiKeysResponse$Type extends MessageType<ApiListApiKeysResponse> {
    constructor() {
        super("torch.v1.ApiListApiKeysResponse", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiApiKey }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ApiListApiKeysResponse
 */
export const ApiListApiKeysResponse = new ApiListApiKeysResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AwsSsoUserData$Type extends MessageType<AwsSsoUserData> {
    constructor() {
        super("torch.v1.AwsSsoUserData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform_instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "external_resource_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.AwsSsoUserData
 */
export const AwsSsoUserData = new AwsSsoUserData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAwsSsoUsersResponse$Type extends MessageType<ListAwsSsoUsersResponse> {
    constructor() {
        super("torch.v1.ListAwsSsoUsersResponse", [
            { no: 1, name: "aws_sso_users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AwsSsoUserData }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ListAwsSsoUsersResponse
 */
export const ListAwsSsoUsersResponse = new ListAwsSsoUsersResponse$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.ApiService
 */
export const ApiService = new ServiceType("torch.v1.ApiService", [
    { name: "SetIsStarred", options: {}, I: SetStarredRequest, O: SetStarredResponse },
    { name: "SetNote", options: {}, I: SetNoteRequest, O: SetNoteResponse },
    { name: "SetOwner", options: {}, I: SetOwnerRequest, O: SetOwnerResponse },
    { name: "CreateToken", options: {}, I: CreateTokenRequest, O: ObjectIdentifier },
    { name: "IsValidSession", options: {}, I: Empty, O: IsValidSessionResponse },
    { name: "ListApiKeysCurrent", options: {}, I: Empty, O: ApiListApiKeysResponse },
    { name: "GetSnapshotStartedAt", options: {}, I: Empty, O: GetSnapshotStartedAtResponse },
    { name: "ListAwsSsoUsers", options: {}, I: Empty, O: ListAwsSsoUsersResponse },
    { name: "NotifyOwner", options: {}, I: ApiNotifyOwnerRequest, O: Empty },
    { name: "AcceptRisk", options: {}, I: ApiAcceptRiskRequest, O: Empty },
    { name: "ReopenRisk", options: {}, I: ApiReopenRiskRequest, O: Empty },
    { name: "UpsertViolationConfig", options: {}, I: ApiUpsertViolationConfigRequest, O: Empty },
    { name: "ListViolationConfigsForIdentity", options: {}, I: ApiListViolationConfigsForIdentityRequest, O: ListViolationConfigsForIdentityResponse }
]);
